import { Injectable } from '@angular/core';
import { BbitSettingsService, GeoCoder } from '@bbit/core';
import * as _ from 'lodash';
import { BbitI18n } from '@bbit/i18n';
import { BbitLog } from '@bbit/log';


export interface IBbitAddress {
  street?: string;
  streetNo?: string;
  postalBox?: string;
  postalCode?: string;
  locality?: string;
  additional1?: string;
  additional2?: string;
  state?: string;
  municipality?: string;
  country?: string;
  lat?: number;
  lon?: number;
  formatted?: string;

  areaCode1Short?: string;
  areaCode1Long?: string;
  areaCode2Short?: string;
  areaCode2Long?: string;
  areaCode3Short?: string;
  areaCode3Long?: string;
  areaCode4Short?: string;
  areaCode4Long?: string;
}

@Injectable()
export class BbitGeocodeService {

  private _log = BbitLog.scope({
    package: 'BbitGeocodeService'
  });

  private _apiKey: string;
  private _defaultRegion: string;
  private _initialized = false;
  private _geocoder: any;

  constructor(
    private _settingsService: BbitSettingsService
  ) {

    this._apiKey = this._settingsService.getSettingValue('google-maps-api-key');
    this._defaultRegion = this._settingsService.getSettingValue('google-maps-api-default-region');


    this._geocoder = new GeoCoder({
      provider: 'google',
      // TODO move api key to settings
      key: this._apiKey ? this._apiKey : 'AIzaSyDKmpp9VNtH8Cl9PM33NSryTyMGcgb60zo',
      lang: BbitI18n.getLanguage(),
      region: this._defaultRegion ? this._defaultRegion : 'ch'
    });
    this._initialized = true;


  }

  public geocode(data: string): Promise<IBbitAddress[]> {
    if (!this._initialized) {
      return Promise.reject('Gecoder is not initialized');
    }
    const self = this;
    return self._geocoder.geocode(data).then(results => {
      const addresses: IBbitAddress[] = [];
      if (!results) {
        return Promise.resolve(null);
      }
      for (let result of results) {
        addresses.push(self._parseResult(result));
      }
      return Promise.resolve(addresses);
    });
  }

  public reverse(lat: number, lon: number): Promise<IBbitAddress> {
    if (!this._initialized) {
      return Promise.reject('Gecoder is not initialized');
    }
    const self = this;
    return self._geocoder.reverse(lat, lon).then(result => {
      return Promise.resolve(self._parseResult(result));
    });
  }

  private _parseResult(result: any): IBbitAddress {
    const address: IBbitAddress = {};

    if (!result) {
      return;
    }

    if (result.source === 'Google') {

      address.formatted = result.formatted;

      const country = _.find(result.raw.address_components, (o: any) => { return _.includes(o.types, 'country'); });
      address.country = country ? `#${country.short_name}` : '';

      const street = _.find(result.raw.address_components, (o: any) => { return _.includes(o.types, 'route'); });
      address.street = street ? street.long_name : '';

      const streetNo = _.find(result.raw.address_components, (o: any) => { return _.includes(o.types, 'street_number'); });
      address.streetNo = streetNo ? streetNo.long_name : '';

      const postalCode = _.find(result.raw.address_components, (o: any) => { return _.includes(o.types, 'postal_code'); });
      address.postalCode = postalCode ? postalCode.long_name : '';

      const locality = _.find(result.raw.address_components, (o: any) => { return _.includes(o.types, 'locality'); });
      address.locality = locality ? locality.long_name : '';

      const areaCode1 = _.find(result.raw.address_components, (o: any) => { return _.includes(o.types, 'administrative_area_level_1'); });
      address.areaCode1Short = areaCode1 ? areaCode1.short_name : '';
      address.areaCode1Long = areaCode1 ? areaCode1.long_name : '';

      const areaCode2 = _.find(result.raw.address_components, (o: any) => { return _.includes(o.types, 'administrative_area_level_2'); });
      address.areaCode2Short = areaCode2 ? areaCode2.short_name : '';
      address.areaCode2Long = areaCode2 ? areaCode2.long_name : '';

      const areaCode3 = _.find(result.raw.address_components, (o: any) => { return _.includes(o.types, 'administrative_area_level_3'); });
      address.areaCode3Short = areaCode3 ? areaCode3.short_name : '';
      address.areaCode3Long = areaCode3 ? areaCode3.long_name : '';

      const areaCode4 = _.find(result.raw.address_components, (o: any) => { return _.includes(o.types, 'administrative_area_level_4'); });
      address.areaCode4Short = areaCode4 ? areaCode4.short_name : '';
      address.areaCode4Long = areaCode4 ? areaCode4.long_name : '';

      address.lat = Number(result.lat);
      address.lon = Number(result.lon);

      return address;

    }
    else {
      this._log.error('result.source ' + result.source + ' is not supported!');
      return null;
    }
  }

}