import { CacheModule } from './modules/cache/cache.module';
import { BbitTooltipModule } from './modules/tooltip/tooltip.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { BbitStyleModule } from './modules/style/style.module';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatNativeDateModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { BbitCoreModule } from '@bbit/core';
import { AgGridModule } from 'ag-grid-angular';
import { App } from './app.component';
import { BbitErrorHandler } from './error-handler';
import { BbitAuthUiModule } from './modules/auth/auth.module';
import { BbitButtonModule } from './modules/button/button.module';
import { BbitCardModule } from './modules/card/card.module';
import { BbitContentModule } from './modules/content/content.module';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { BbitHotkeyModule } from './modules/hotkey/hotkey.module';
import { BbitIconModule } from './modules/icon/icon.module';
import { BbitInputAddressModule } from './modules/input-address/input-address.module';
import { BbitInputFileModule } from './modules/input-file/input-file.module';
import { BbitInputPasswordModule } from './modules/input-password/input-password.module';
import { BbitInputSelectModule } from './modules/input-select/input-select.module';
import { BbitInputTextModule } from './modules/input-text/input-text.module';
import { BbitNotificationModule } from './modules/notification/notification.module';
import { BbitPrintModule } from './modules/print/print.module';
import { BbitScheduleModule } from './modules/schedule/schedule.module';
import { BbitShortcutModule } from './modules/shortcut/shortcut.module';
import { BbitSideNavigationModule } from './modules/side-navigation/side-navigation.module';
import { BbitTabsModule } from './modules/tabs/tab.module';
import { BbitTopNavigationModule } from './modules/top-navigation/top-navigation.module';
import { BbitUiModule } from './modules/ui/ui.module';
import { BbitUpdateModule } from './modules/update/update.module';
import { BbitWorkflowModule } from './modules/workflow/workflow.module';

// const appRoutes: Routes = [
//   { path: '', component: DashboardComponent },
//   { path: 'test', component: DashboardComponent },
//   { path: '**', component: DashboardComponent }
// ];

@NgModule({
  imports: [
    // RouterModule.forRoot(appRoutes),
    BbitCoreModule,
    BbitTabsModule,
    HttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    FormsModule,
    FlexLayoutModule,
    BbitIconModule,
    AgGridModule.withComponents([]),
    BbitUiModule,
    BbitCardModule,
    BbitButtonModule,
    BbitIconModule,
    BbitNotificationModule,
    BbitInputAddressModule,
    BbitInputTextModule,
    BbitInputSelectModule,
    BbitInputPasswordModule,
    BbitInputFileModule,
    BbitHotkeyModule,
    BbitUpdateModule,
    BbitShortcutModule,
    BbitSideNavigationModule,
    BbitContentModule,
    BbitAuthUiModule,
    BbitPrintModule,
    HttpClientModule,
    BbitTopNavigationModule,
    DashboardModule,
    BbitScheduleModule,
    BbitWorkflowModule,
    BbitStyleModule,
    BbitTooltipModule,
    CacheModule
  ],
  declarations: [App],
  bootstrap: [App],
  providers: [
    {provide: ErrorHandler, useClass: BbitErrorHandler},
    { provide: APP_BASE_HREF, useValue: '' }
  ]
})
export class AppModule { }