import { Directive, Output, AfterContentInit, EventEmitter } from '@angular/core';

@Directive({ selector: '[afterIf]' })
export class BbitAfterIfDirective implements AfterContentInit {
  @Output('afterIf')
  public after: EventEmitter<BbitAfterIfDirective> = new EventEmitter();

  public ngAfterContentInit(): void {
    setTimeout(() => {
      this.after.next(this);
    });
  }
}