import { OverlayModule } from '@angular/cdk/overlay';
import { BbitTooltipDirective } from './tooltip.directive';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitTooltipService } from './tooltip.service';
import { BbitTooltipComponent } from './tooltip.component';

@NgModule({
  imports: [
    BrowserModule,
    OverlayModule
  ],
  exports: [
    BbitTooltipComponent,
    BbitTooltipDirective
  ],
  declarations: [
    BbitTooltipComponent,
    BbitTooltipDirective
  ],
  providers: [
    BbitTooltipService
  ],
  entryComponents: [
    BbitTooltipComponent
  ]
})
export class BbitTooltipModule { }
