import { BbitInputSelectModule } from './../input-select/input-select.module';
import { BbitInputTextModule } from './../input-text/input-text.module';
import { BbitInputPasswordModule } from './../input-password/input-password.module';
import { BbitNotificationModule } from './../notification/notification.module';
import { BbitButtonModule } from './../button/button.module';
import { BbitCardModule } from './../card/card.module';
import { BbitLoginComponent } from './login.component';
import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitIconModule } from '../icon/icon.module';
import { BbitCoreModule } from '@bbit/core';
import { MatCardModule, MatButtonModule, MatInputModule, MatStepperModule, MatProgressBarModule } from '@angular/material';

@NgModule({
  imports: [
    BbitCoreModule,
    BbitIconModule,
    BrowserModule,
    FlexLayoutModule,
    BbitHotkeyModule,
    BbitCardModule,
    BbitButtonModule,
    BbitIconModule,
    BbitNotificationModule,
    BbitInputTextModule,
    BbitInputPasswordModule,
    BbitInputSelectModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatStepperModule,
    MatButtonModule,
    MatProgressBarModule
  ],
  exports: [
    BbitLoginComponent
  ],
  declarations: [
    BbitLoginComponent
  ]
})
export class BbitAuthUiModule { }
