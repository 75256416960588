import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BbitController, BbitSchemaUtil, BbitSelectService, IBbitComparisonFilter, IBbitRequestResult, IBbitSelect, IBbitSelectColumn, MergeStrategy, StringHelpers, Utils } from '@bbit/core';
import * as _ from 'lodash';
import { BbitInputSelectComponent } from '../input-select/input-select.component';
import { BbitTabService } from '../tabs/tab.service';
import { IBbitInputSuffix } from './../input-text/interfaces.d';
import { BbitI18n, IBbitI18n, IBbitTranslationType, TranslationType } from '@bbit/i18n';
import { BbitLog } from '@bbit/log';

interface IBbitMultiReferenceRow {
  _combinedId: string;
  _rowid: string;
  _listid: string;
  displayValue: string;
  readOnly: boolean;
  value: boolean;
  tooltip: string;
  menu: any;
}

@Component({
  selector: 'bbit-input-multireference',
  templateUrl: 'input-multireference.component.pug',
  styleUrls: ['input-multireference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitInputMultiReferenceComponent {
  // @Input() referencedController: BbitController;
  // @Input() referencedJsonPath: string;
  @Input() language: IBbitTranslationType;
  @Input() selector: IBbitSelect;
  @Input() ids: string[];
  @Input() displayMode: string;

  @Input() tooltip: string | IBbitI18n;
  @Input() label: string | IBbitI18n;
  @Input() error: string | IBbitI18n;
  @Input() warning: string | IBbitI18n;
  @Input() info: string | IBbitI18n;
  @Input() type: string | IBbitI18n;
  @Input() disabled: boolean;
  @Input() suffixes: IBbitInputSuffix[];

  // @Output() displayValueChange: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() suffixAction: EventEmitter<any> = new EventEmitter();
  @Output() idsChange: EventEmitter<any> = new EventEmitter();

  @ViewChild(BbitInputSelectComponent) bbitSelectInput: BbitInputSelectComponent;

  private _log = BbitLog.scope({
    package: 'BbitInputMultiReferenceComponent'
  });

  _internalError: string;
  _addInputValue: string;
  _displayValues: { [key: string]: string; };
  _isDisplayValueLoading: boolean;
  _isRowsLoading: Promise<IBbitRequestResult>;
  _menu: any;
  _rows: IBbitMultiReferenceRow[];
  _tempCreationController: BbitController;
  _tempCreationSubscribtion: any;
  _hasPreview: boolean;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _selectService: BbitSelectService,
    private _tabService: BbitTabService) { }

  ngOnInit() {
    // this._displayValues = {};
    // this.loadDisplayValue(true);
  }


  ngOnDestroy(): void {
    // this._disposeCacheController();
    this._disposeTempCreation();
  }

  private _disposeTempCreation(): void {
    if (this._tempCreationSubscribtion) this._tempCreationSubscribtion.unsubscribe();
    this._tempCreationController = null;
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['selector'] || changes['ids']) {

      // do a deep Equal check
      if ((!changes['selector'] || _.isEqual(changes['selector'].previousValue, changes['selector'].currentValue)) &&
        (!changes['ids'] || _.isEqual(changes['ids'].previousValue, changes['ids'].currentValue))) {
        return;
      }

      const self = this;

      if (this.displayMode === 'checkboxes') {
        this.loadRows().then(
          res => self._cdr.markForCheck(),
          err => self._cdr.markForCheck(),
        );
      }
      else {
        this.loadDisplayValue(true);
      }
    }
  }

  _onBlur($event) {
    this.blur.emit($event);
  }

  _onFocus($event) {
    this.focus.emit($event);
  }

  _onSuffixAction($event) {
    this.suffixAction.emit($event);
  }

  _onAction($event) {
    switch ($event.action) {
      case 'CREATE-NEW':
        $event.handled = true;
        this.openDetail(null);
        break;
      case 'OPEN-DETAIL':
        if ($event.params && $event.params._id) {
          $event.handled = true;
          this.openDetail($event.params._id);
        }
        break;
    }

    if (!$event.handled) {
      this.action.emit($event);
    }
  }

  openDetail(rowId) {
    const self = this;
    let params: any = {};

    if (self.selector && self.selector.from) {
      if (rowId) {
        params.action = 'OPEN';
        params.entityId = rowId;
        this._disposeTempCreation();

      } else {
        params.action = 'NEW';

        this._disposeTempCreation();

        self._tempCreationController = new BbitController();
        let newId = self._tempCreationController.generateNewId();

        self._tempCreationSubscribtion = self._tempCreationController.getObservableStream('dataChanged').subscribe(() => {
          // ToDo: debouncing to 1s
          const result = self._tempCreationController.getDisplayValue(newId + '.' + self.selector.displayField, { format: 'string', language: self._getTranslationType() });
          if (result) {
            self._displayValues[newId] = result.data;

            if (self._tempCreationController.isNew(newId)) {
              self.warning = 'Bitte neu erfassten Datensatz speichern';
            }
            else {
              self._disposeTempCreation();
              self.warning = null;
            }
          }
          self._cdr.markForCheck();
        });

        self._onSelect({
          displayValue: BbitI18n.t({ key: 'new-record', n: 1 }),
          _id: newId
        });

        params.entityId = newId;
      }

      return this._tabService.create({
        uniqueEntityName: self.selector.from,
        viewName: 'detail', // get default detail view of schema
        params: params
      }, self._tempCreationController);
    }
  }

  openPreview(rowId) {
    const self = this;

    if (!self._hasPreview) {
      return;
    }

    const openAction = {
      action: 'OPEN-PREVIEW',
      tooltip: { key: 'open-entry', n: 1 },
      icon: 'open_in_new',
      params: {
        uniqueEntityName: self.selector.from,
        id: rowId
      },
    };

    this.suffixAction.emit(openAction);
  }

  onRowValueChanged(row: IBbitMultiReferenceRow, $event) {
    row.value = !row.value;

    this.ids = _.compact(_.map(this._rows || [], item => item && item.value ? item._combinedId : null));
    this.idsChange.emit(this.ids);
  }

  _onSelect($event) {

    if ($event._id) {
      let displayValue = $event.displayValue;
      let id = $event._id;

      if (!this.ids) {
        this.ids = [id];
        if (!this._displayValues) this._displayValues = {};
        this._displayValues[id] = displayValue;
      }
      else {
        if (!_.some(this.ids, item => item === id)) {
          this.ids.push(id);
          if (!this._displayValues) this._displayValues = {};
          this._displayValues[id] = displayValue;
        }
      }

      this._addInputValue = '';
      this.bbitSelectInput.forceInputValue('');
      this.idsChange.emit(this.ids);
    }
  }

  unselect(idToRemove: string) {
    this.ids = _.filter(this.ids, id => id !== idToRemove);
    this.idsChange.emit(this.ids);
  }

  _getTranslationType(): IBbitTranslationType {
    if (this.language) {
      return this.language;
    }
    else {
      return {
        language: BbitI18n.getLanguage(),
        type: TranslationType.FULL,
        count: null
      };
    }
  }

  loadRows(): Promise<IBbitRequestResult> {
    const self = this;
    if (this._isRowsLoading) return this._isRowsLoading;

    if (self.selector && self.selector.displayField && self.selector.from) {
      const selectController = new BbitController();
      selectController.setup({
        uniqueEntityName: this.selector.from,
        schemaCompilation: this._selectService.getSchemaService().getSchemaCompilation(this._selectService.getAuthService().getCurrentSession()),
        selectService: this._selectService,
        sessionRef: this._selectService.getAuthService().getCurrentSession(),
        onExternalRowUpdate: 'merge',
        onExternalRowInsert: 'append',
        onExternalRowDelete: 'remove'
      });
      /* {
            const openAction = {
              action: "OPEN-PREVIEW",
              tooltip: { key: "open-entry", n: 1 },
              icon: "open_in_new",
              params: {
                uniqueEntityName: self.selector.from,
                id: self.id
              },
            };
      /* let orderBy = [];
      if (this.selector.orderBy && this.selector.orderBy.length > 0) {
          orderBy = self.selector.orderBy;
      }
      else {
          orderBy = _.map(self.selector.select, (column) => {
              return column.field;
          });
      } */

      let where;
      try {
        where = selectController.evaluateObjectDeep(_.clone(this.selector.where || {}), {}, null, false);
      }
      catch (err) {
        return Promise.reject({ statusCode: 500, errorMessage: 'error evaluating where: ' + err });
      }

      const select: IBbitSelect = {
        select: [],
        where: where,
        from: self.selector.from,
        fromSubList: self.selector.fromSubList,
        // skip: self.selector.skip || 0,
        // limit: self.selector.limit || 50,
        // orderBy: orderBy // orderBy
      };


      this._isRowsLoading = selectController.hasSelectService() ?
        selectController.retrieveList(select, MergeStrategy.OVERWRITE_ORIGINAL) :
        Promise.resolve<IBbitRequestResult>({ statusCode: 200 }); // { $and: [inputFilter, baseFilter ] }

      this._isRowsLoading.then((res): Promise<any> => {

        const rowIDs = selectController.listDocumentId('[all]');

        self._rows = _.compact(_.flatMap(rowIDs, (rowId) => {

          if (select.fromSubList && select.fromSubList.length > 0) {
            return _.map(selectController.getListIds(rowId + '.' + select.fromSubList), listId => {
              const itemPath = rowId + '.' + select.fromSubList + '.' + listId;

              const retrievedRow: IBbitMultiReferenceRow = {
                _combinedId: itemPath,
                _rowid: rowId,
                _listid: listId,
                displayValue: BbitI18n.t(selectController.getDisplayValue(itemPath + '.' + self.selector.displayField, { format: 'string', language: self.language }).data),
                value: self.ids ? self.ids.indexOf(itemPath) >= 0 : false,
                readOnly: false,
                tooltip: null,
                menu: null,
              };

              if (selectController.getSchema().hasReadPermission(self._selectService.getAuthService().getCurrentSession())) {
                retrievedRow.menu = [{
                  action: 'OPEN-DETAIL',
                  text: 'open person',
                  icon: 'open_in_new',
                  params: { _id: rowId },
                }];
              }

              return retrievedRow;
            });
          }
          else {
            const retrievedRow: IBbitMultiReferenceRow = {
              _combinedId: rowId,
              _rowid: rowId,
              _listid: null,
              displayValue: BbitI18n.t(selectController.getDisplayValue(rowId + '.' + self.selector.displayField, { format: 'string', language: self.language }).data),
              value: self.ids ? self.ids.indexOf(rowId) >= 0 : false,
              readOnly: false,
              tooltip: null,
              menu: null,
            };
            return retrievedRow;
          }
        }));

        this._internalError = null;
        this._isRowsLoading = null;
        return Promise.resolve(res);

      }).catch((error) => {
        this._log.error('error retrieving select records:', error);
        if (error && error.statusCode === 404) {
          this._internalError = 'no entry found';
        }
        else {
          this._internalError = 'error: ' + (error.errorMessage || error);
        }
      });

      return this._isRowsLoading;
    }
  }

  loadDisplayValue(force: boolean = false, retryCount: number = 3): Promise<IBbitRequestResult> {
    const self = this;

    if (self.ids && _.isArray(self.ids) && self.selector && self.selector.displayField && self.selector.from) {
      // const fullpath = self.id + "." + self.selector.displayField;

      self._addInputValue = '';

      if (self._isDisplayValueLoading) return Promise.resolve({ statusCode: 200, data: null });

      const cacheController = this._selectService.getCacheControllerWithLoadedId(
        this._selectService.getAuthService().getCurrentSession(),
        self.selector.from,
        self.ids
      );

      if (Utils.isPromise(cacheController)) {
        if (!retryCount || retryCount <= 0) return Promise.resolve({ statusCode: 200, data: null }); // avoid infinite recursion

        self._isDisplayValueLoading = true;
        return cacheController.then((result) => {
          self._isDisplayValueLoading = false;
          // cache has loaded, retry loading
          return self.loadDisplayValue(force, retryCount - 1);
        }, (error) => {
          self._isDisplayValueLoading = false;
          // self._displayValue = "ERR: " + (error && error.errorMessage ? error.errorMessage : error.toString());
          return Promise.resolve(error);
        });
      }
      else {

        this._hasPreview = cacheController.getSchema().hasReadPermission(cacheController.getSession()) &&
          cacheController.getSchema().hasPreviewView('desktop');

        let promisesOrResults = _.map(self.ids, id => {
          let returnVal = {
            _id: id,
            displayValue: id || 'NULL'
          };

          if (id && id.trim().length > 0) {
            returnVal.displayValue = cacheController.getDisplayValue(id + '.' + self.selector.displayField, { format: 'string', language: self._getTranslationType() }).data;
          }
          return returnVal;
        });

        let hasPromise = _.some(promisesOrResults, result => Utils.isPromise(result.displayValue));
        if (hasPromise) {
          if (!retryCount || retryCount <= 0) return Promise.resolve({ statusCode: 200, data: null }); // avoid infinite recursion

          let promiseList: any = _.compact(_.map(promisesOrResults, item => {
            return Utils.isPromise(item.displayValue) ? item.displayValue : null;
          }));

          self._isDisplayValueLoading = true;
          return Promise.all(promiseList).then((result) => {
            self._isDisplayValueLoading = false;
            // cache has loaded, retry loading
            return self.loadDisplayValue(force, retryCount - 1);
          }, (error) => {
            self._isDisplayValueLoading = false;
            // self._displayValue = "ERR: " + (error && error.errorMessage ? error.errorMessage : error.toString());
            return Promise.resolve(error);
          });
        }
        else {

          self._displayValues = {};
          promisesOrResults.forEach(result => {
            self._displayValues[result._id] = result.displayValue;
          });
          self._cdr.markForCheck();
        }
      }
    }
    else {
      this._displayValues = null;
    }
    return Promise.resolve({ statusCode: 200 });
  }

  getSelectValues(currentDisplayValue: string): Promise<any> {

    const self = this;

    const selectController = new BbitController();
    selectController.setup({
      uniqueEntityName: this.selector.from,
      schemaCompilation: this._selectService.getSchemaService().getSchemaCompilation(this._selectService.getAuthService().getCurrentSession()),
      selectService: this._selectService,
      sessionRef: this._selectService.getAuthService().getCurrentSession(),
      onExternalRowUpdate: 'merge',
      onExternalRowInsert: 'append',
      onExternalRowDelete: 'remove'
    });

    const referencedSchema = selectController.getSchema();
    if (!referencedSchema) {
      this._log.warning('unknown-reference-schema', 'unknown schema ' + this.selector.from);
      return Promise.reject(null);
    }
    else {
      if (referencedSchema.hasWritePermission(self._selectService.getAuthService().getCurrentSession())) {
        this._menu = [{
          action: 'CREATE-NEW',
          text: 'Neuer Eintrag',
          icon: 'add'
        }];
      }
      else {
        this._menu = [];
      }
    }

    if (!this.selector.limit) {
      this.selector.limit = 50;
    }

    const translationType = this._getTranslationType();
    const schemaService = this._selectService.getSchemaService();
    const filter: IBbitComparisonFilter = _.clone(this.selector.where || {});

    let columns: IBbitSelectColumn[];
    if (!self.selector.select || _.compact(self.selector.select).length === 0) {
      columns = [{ width: '100%', field: self.selector.displayField }];
    }
    else {
      columns = self.selector.select;
    }

    let terms: string[] = null;
    if (!this.selector.useLocalFilter) {
      if (currentDisplayValue && currentDisplayValue.length > 0) {
        terms = currentDisplayValue.split(' ');
        filter['$and'] = _.compact(_.map(terms, term => {
          if (term.trim().length === 0) return null;

          return {
            '$or': _.compact(_.flatMap(columns, (column) => {
              let res = {};

              // debugger;

              const element = selectController.getSchemaElement('[].' + column.field, false);

              if (!BbitSchemaUtil.isDataField(element)) {
                this._log.warning('field ' + column.field + ' referenced in select is no datafield. Field is ignored');
                return null;
              }

              if (BbitSchemaUtil.isElementComputed(element)) {
                this._log.warning('field ' + column.field + ' referenced in select must not be a computed field. Field is ignored');
                return null;
              }

              const datatype = BbitSchemaUtil.getDataTypeOfElement(element, selectController.getSchemaCompilation());
              if (datatype && datatype.getSearchQuery) {
                const query = datatype.getSearchQuery(term, column.field, element, selectController, { format: 'string', language: translationType });
                if (query.statusCode >= 300) {
                  this._log.warning('for field ' + column.field + ' referenced in select the datatype has returned no search query. Field is ignored');
                  return null;
                }
                else if (Utils.isObjectEmpty(query.data)) {
                  this._log.warning('for field ' + column.field + ' referenced in select the datatype has returned an empty query. Field is ignored');
                  return null;
                }
                else {
                  _.merge(res, query.data);
                }
              }
              else {
                res[column.field] = {
                  $regex: StringHelpers.getSearchTermRegex(term)
                };
                // };
              }

              return res;
            }))
          };

        })).concat(<any>filter['$and'] || []);
      }
    }

    // exclude items that are already mapped
    filter['$and'] = _.map(_.compact(self.ids || []), (id) => {
      return {
        _id: { $ne: id }
      };
    }).concat(<any>filter['$and'] || []);

    /* let orderBy = [];
    if (this.selector.orderBy && this.selector.orderBy.length > 0) {
        orderBy = this.selector.orderBy;
    }
    else {
        orderBy = _.map(self.selector.select, (column) => {
            return column.field;
        });
    } */

    let where = selectController.evaluateObjectDeep(filter, { currentDisplayValue: currentDisplayValue }, null, true);

    const select: IBbitSelect = {
      select: null,
      where: where,
      skip: this.selector.skip || 0,
      limit: this.selector.limit || 50,
      orderBy: null // orderBy
    };

    this._log.log('SELECTOR WHERE', JSON.stringify(select.where, null, 3), terms);

    let reloadPromise: Promise<IBbitRequestResult> = selectController.hasSelectService() ?
      selectController.retrieveList(select, MergeStrategy.OVERWRITE_ORIGINAL) :
      Promise.resolve<IBbitRequestResult>({ statusCode: 200 }); // { $and: [inputFilter, baseFilter ] }

    return reloadPromise.then((result): Promise<any> => {

      const rowIDs = selectController.listDocumentId('[all]');

      const selectMenuRows = _.map(rowIDs, (rowId) => {
        let columnsPaths = self.selector.select;

        const retrievedRow: any = {
          _id: rowId,
          displayValue: BbitI18n.t(selectController.getDisplayValue(rowId + '.' + self.selector.displayField, { format: 'string', language: translationType }).data),
          /* columns: _.map(columnsPaths, column => {
              return selectController.getDisplayValue(rowId + "." + column.field, translationType).data;
          }), */
          menu: [{
            action: 'OPEN-DETAIL',
            text: 'open person',
            icon: 'open_in_new',
            params: { _id: rowId },
          }]
        };
        return retrievedRow;
      });

      if (this.selector.useLocalFilter) {
        if (currentDisplayValue && currentDisplayValue.length > 0) {
          return Promise.resolve(_.filter(selectMenuRows, (a) =>  { return new RegExp(currentDisplayValue, 'i').test(a.displayValue); }));
        }
      }

      return Promise.resolve(selectMenuRows);

    }).catch((error) => {
      this._log.error('error retrieving select records:', error);
      if (error && error.statusCode === 404) {
        // this.validityMessage = "no entry found";
      }
      else {
        // this.validityMessage = "error: " + (error.errorMessage || error);
      }
    });
  }
}