import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule, MatChipsModule, MatMenuModule, MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitInputSelectModule } from './../input-select/input-select.module';
import { BbitInputTextModule } from './../input-text/input-text.module';
import { BbitInputMultiReferenceComponent } from './input-multireference.component';
import { BbitI18nModule } from '@bbit/i18n';


@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    BbitCoreModule,
    BbitInputTextModule,
    BbitI18nModule,
    BbitInputSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatChipsModule,
    MatMenuModule
  ],
  exports: [
    BbitInputMultiReferenceComponent
  ],
  declarations: [
    BbitInputMultiReferenceComponent
  ]
})
export class BbitInputMultiReferenceModule { }
