import { BbitStyleOptions } from './interfaces';
import { BbitColor } from './style.service';

export class BbitStyle<T> {
  protected _options: T;
  constructor(options: T) {
    this._options = options;
  }
  update(options: T) {
    this._options = options;
  }
  getOptions(): T {
    return this._options;
  }

  toString(): string {
    return '';
  }
}

export interface BbitStyleOptions {

}

export interface BbitColorOptions extends BbitStyleOptions {
  rgb?: {
    r: number;
    g: number;
    b: number;
  };
  alpha?: number;
  color?: BbitColor;
}

export type BbitStyleObject = {[key: string]: BbitColor};