import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BbitAuthService, BbitSelectService } from '@bbit/core';
import * as _ from 'lodash';



@Component({
  selector: 'temp-westwind-person-work',
  templateUrl: 'temp-westwind-person-work.component.pug',
  styleUrls: [
    'temp-westwind-person-work.component.scss'
  ]
})
export class TempWestwindPersonWork implements OnInit {

  @Input('ui') ui;
  @Output('action') action: EventEmitter<any> = new EventEmitter();

  characteristics = [];
  personalObjectives = [];
  workObjectives = [];

  constructor(
    private _selectService: BbitSelectService,
    private _authService: BbitAuthService,
    private _cdr: ChangeDetectorRef
  ) {

  }


  ngOnInit() {
    const self = this;
    self._selectService.ensureCache(self._authService.getCurrentSession(), { 'objective-characteristic-work': ['ALL'] }).then(() => {
      let controller = self._selectService.getCacheController(self._authService.getCurrentSession(), 'objective-characteristic-work');
      const data = controller.getData('');
      self.characteristics = _.orderBy(_.filter(data, { person: self.ui.jsonPath, type: '#characteristic'}), (o: any) => o && o.designation ? o.designation.toLowerCase() : null);
      self.personalObjectives = _.orderBy(_.filter(data, { person: self.ui.jsonPath, type: '#personal-objective'}), (o: any) => o && o.designation ? o.designation.toLowerCase() : null);
      self.workObjectives = _.orderBy(_.filter(data, { person: self.ui.jsonPath, type: '#work-objective'}), (o: any) => o && o.designation ? o.designation.toLowerCase() : null);
      self._cdr.markForCheck();
    });
  }

  openPreview(id: string) {
    this.action.emit({ action: 'OPEN-PREVIEW', params: { uniqueEntityName: 'objective-characteristic-work', id: id } });
  }

}
