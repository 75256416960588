import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { IBbitI18n, BbitI18n } from '@bbit/i18n';
import * as moment from 'moment';
import { IBbitInputSuffix } from './../input-text/interfaces.d';


@Component({
  selector: 'bbit-input-text',
  templateUrl: 'input-text.component.pug',
  styleUrls: ['input-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class BbitInputTextComponent {

  @Input() value: string;
  @Input() rawValue: any;
  @Input() tooltip: string | IBbitI18n;
  @Input() label: string | IBbitI18n;
  @Input() error: string | IBbitI18n;
  @Input() warning: string | IBbitI18n;
  @Input() info: string | IBbitI18n;
  @Input() type: string | IBbitI18n;
  @Input() datepicker: boolean;
  @Input() disabled: boolean;
  @Input() multiline: boolean = false;

  @Input() suffixes: IBbitInputSuffix[];

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output('focus') focusEmitter: EventEmitter<any> = new EventEmitter();
  @Output() suffixAction: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatInput) matInput: MatInput;
  @ViewChild('domInput') domInput: ElementRef;
  @ViewChild('inputDatepicker') inputDatepicker: any;

  _suffixClicked: boolean = false;

  constructor(
    private _datepickerAadapter: DateAdapter<any>
  ) {
    this._datepickerAadapter.setLocale(BbitI18n.getMomentLanguage());
  }

  public selectText() {
    this.domInput.nativeElement.setSelectionRange(0, this.domInput.nativeElement.value.length);
  }

  public openDatepicker() {
    this._suffixClicked = true;
    if (this.inputDatepicker) {
      this.inputDatepicker.open();
    }
  }

  public focus() {
    this.matInput.focus();
  }


  private _onValueChange($event): void {
    this.valueChange.emit($event);
  }

  private _onBlur($event): void {
    this.blur.emit($event);
  }

  private _onFocus($event): void {
    this._suffixClicked = false;
    this.selectText();
    this.focusEmitter.emit($event);
  }

  private _onSuffixAction($event): void {
    if ($event && $event.action) {
      this._suffixClicked = true;
      this.suffixAction.emit($event);
    }
    else {
      this._suffixClicked = false;
      this._onFocus({});
    }
  }

  private _onInputClick() {
    if (this._suffixClicked) {
      this._suffixClicked = false;
      this._onFocus({});
    }
  }


  private _getDate() {
    return moment(this.rawValue);
  }

  private _dateChanged(event) {
    this._onValueChange(BbitI18n.m({ date: event.value, format: 'L'}));
    this._onBlur(null);
  }

  public getSubscriptTooltip(element, message) {
    // if (element.scrollWidth > element.offsetWidth) { offsetWidth needed to much time to calculate
      return BbitI18n.t(message);
    // }
    // return null;
  }

}