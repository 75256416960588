import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BbitIconService } from './icon.service';

@Component({
  selector: 'bbit-icon',
  templateUrl: 'icon.component.pug',
  styleUrls: [ 'icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitIcon {

  @Input() icon: string;
  @Input() size: string;

  _type: string;
  _fontSet: string;
  _fontIcon: string;
  _svgIconName: string;

  constructor(private _service: BbitIconService) {
    this._service.init();
  }

  ngOnInit() {
    if (this.icon) {
      if (this.icon.substr(0, 4) === 'mdi:') {
        this._fontSet = 'mdi';
        this._fontIcon = 'mdi-' + this.icon.substr(4, this.icon.length);
        this._type = 'mdi';
      }
      if (this.icon.substr(0, 4) === 'svg:') {
        this._svgIconName = this.icon.substr(4, this.icon.length);
        this._type = 'svg';
      }
    }
    if (!this.size) {
      this.size = '24px';
    }
  }
}