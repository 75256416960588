
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitWorkflowNodeUi } from './workflow-node-ui.component';

@NgModule({
  imports: [
    BbitCoreModule,
    BrowserModule
  ],
  exports: [
    BbitWorkflowNodeUi
  ],
  declarations: [
    BbitWorkflowNodeUi
  ],
  providers: [
  ]
})
export class BbitWorkflowModule { }
