
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { BbitAction, BbitMessagingService, BbitMgmtApiService, BbitSessionState, IBbitEvent, IBbitSession, Utils } from '@bbit/core';
import { init } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { BbitLog } from '@bbit/log';
declare var SessionStack;

@Injectable()
export class BbitErrorHandler implements ErrorHandler {

  private _log = BbitLog.scope({
    package: 'BbitErrorHandler'
  });


  constructor(private injector: Injector) {

    const mgmtApiService: BbitMgmtApiService = this.injector.get(BbitMgmtApiService);

    // TODO find the right place for this
    mgmtApiService.setAppPackageJson(require('../../../package.json'));

    // const zone: NgZone = this.injector.get(NgZone);
    const eventService = this.injector.get(BbitMessagingService);

    const release = (Utils.isElectronApp() ? 'electron' : 'web') + '-' + mgmtApiService.getAppVersion();

    init({
      dsn: 'https://f1bad134cd6c4eb49790246f226d3c5a@sentry.io/1262268',
      release: release,
      beforeSend: function (event) {
        // prepare data for electron source mapping
        if (event.exception && event.exception.values) {
          for (let ex of event.exception.values) {
            if (ex.stacktrace && ex.stacktrace.frames) {
              for (let frame of ex.stacktrace.frames) {
                if (frame.filename && frame.filename.indexOf && frame.filename.indexOf('app.asar') >= 0) {
                  frame.filename = frame.filename.substr(frame.filename.indexOf('app.asar') + 8);
                }
              }
            }
          }
        }
        if (event.transaction && event.transaction.indexOf && event.transaction.indexOf('app.asar') >= 0) {
          event.transaction = 'bbit://' + event.transaction.substr(event.transaction.indexOf('app.asar'));
        }
        return event;
      }

    });


    eventService.observeTopic(null, BbitAction.ON_SESSION_STATE_CHANGES).subscribe(
      (event: IBbitEvent<{ session: IBbitSession, old: BbitSessionState, new: BbitSessionState }>) => {
        switch (event.payload.new) {
          case BbitSessionState.LOGGED_IN:
            // Sentry.setUserContext();
            Sentry.configureScope(scope => {
              scope.setUser({
                id: event.payload.session.getUserId(),
                username: event.payload.session.getUserName(),
                extra: {
                  loginName: event.payload.session.getLoginName(),
                  organization: event.payload.session.getMandatorName()
                }
                // email: event.payload.session.getLoginName() // check if is email
              });
            });

            // SessionStack.identify({
            //   userId: event.payload.session.getUserId(), // Replace the USER-ID with the user id from your app
            //   // email: 'user@example.com', // Not required
            //   displayName: event.payload.session.getUserName(), // Not required
            //   username: event.payload.session.getLoginName(),
            //   organization: event.payload.session.getMandatorName()
            // });
            break;
          case BbitSessionState.LOGGED_OUT:
            Sentry.configureScope(scope => {
              scope.clear();
            });
            break;
        }
      });
  }

  handleError(error: any): void {
    this._log.error(error);
    Sentry.captureException(error);
  }
}
