import { HttpClient } from '@angular/common/http';
import { Component, NgZone } from '@angular/core';
import { BbitAuthService, BbitMgmtApiService, BbitTokenType, IBbitAction, IBbitRequestResult } from '@bbit/core';
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { Database } from './interfaces';
import { BbitLog } from '@bbit/log';

@Component({
  templateUrl: 'database.component.pug'
})
export class DatabaseComponent implements IBbitTabController {

  tab: IBbitTabInterface = null; // warning: still externaly accessed, ToDo: refactor such shit
  isActive: boolean = false;
  toolbar: any;

  databases: Database[];
  gridOptions: GridOptions;
  columnDefs: any[];

  private _log = BbitLog.scope({
    package: 'DatabaseComponent'
  });

  constructor(
    protected _tabService: BbitTabService,
    protected _http: HttpClient,
    protected _auth: BbitAuthService,
    protected _mgmtApi: BbitMgmtApiService
  ) {

    const self = this;

    self.loadDatabases().then(databases => {
      self._log.info(`Retrieved databases`, databases);
      self.databases = databases;
    });

    self.gridOptions = <GridOptions>{
      onGridReady: () => {
        self.gridOptions.api.sizeColumnsToFit();
      },
      floatingFilter: true,
      icons: {
        menu: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">more_vert</i>',
        filter: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">search</i>',
      },
    };


    self.columnDefs = [
      { headerName: 'Database', field: 'database', filter: 'agTextColumnFilter' },
      { headerName: 'Organization', field: 'organization', filter: 'agSetColumnFilter' },
      { headerName: 'Uniquen entity name', field: 'uniqueEntityName', filter: 'agTextColumnFilter' },
      { headerName: 'Bucket', field: 'bucket', filter: 'agSetColumnFilter' },
      { headerName: 'Enpoint', field: 'endpoint', filter: 'agSetColumnFilter' },
      { headerName: 'Enpoint-URL', field: 'endpointUrl', filter: 'agSetColumnFilter' }
    ];

  }

  /**
   * Loads all databases from all endpoints
   */
  public loadDatabases(): Promise<Database[]> {
    const self = this;
    const promises = [];
      for (const endpoint in self._mgmtApi.getDatabaseEndpoints()) {
        promises.push(self.loadDatabasesFromEndpoint(endpoint));
      }
      return Promise.all(promises).then((dbArray: Database[][]) => {
        let databases: Database[] = [];
        for (const o of dbArray) {
          databases = databases.concat(o);
        }
        return Promise.resolve(databases);
      });
  }

  /**
   * Loads all databases from a single endpoints
   * @param endpoint Endpoint key, not the url
   */
  public loadDatabasesFromEndpoint(endpoint: string): Promise<Database[]> {
    const self = this;
    const url = self._mgmtApi.getDatabaseEndpoints()[endpoint];
    self._log.info(`Retrieving databases from ${endpoint} (${url})`);

    return self._auth.getCurrentSession().retrieveToken('databases', BbitTokenType.ACCESS).then((result: IBbitRequestResult) => {
      if (result.statusCode !== 200) {
        return Promise.reject('Error while retrieving token');
      }

      return self._http.get(`${url}/_all_dbs`, { headers: { 'Authorization': `Bearer ${result.data}` } }).toPromise().then((databases: string[]) => {
        const dbs = _.map(databases, (o: string) => {
          const match = /(.*)\+(.*)\+(.*)/.exec(o);
          return <Database>{
            database: o,
            endpoint: endpoint,
            organization: match ? match[1] : null,
            uniqueEntityName: match ? match[2] : null,
            bucket: match ? match[3] : null,
            endpointUrl: url
          };
        });
        return Promise.resolve(dbs);
      });
    });

  }

  rowDoubleClicked(row: any) {
    if (row && row.data) {
      this._tabService.create({
        uniqueEntityName: 'component',
        viewName: 'DocumentListComponent',
        params: {
          database: row.data
        }
      });
    }
  }


  injectParams(tab) {
    this.tab = tab;
    this.tab.text = { value: { key: 'database' } };
    this.tab.icon = 'mdi:database';
    this.toolbar = {
      icon: 'mdi:database',
      title: this.tab.text,
      // buttons: [{
      //   icon: 'add',
      //   action: 'ADD'
      // }]
    };

  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(action: IBbitAction) {
  }


}