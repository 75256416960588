import { AgGridModule } from 'ag-grid-angular';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitIconModule } from '../icon/icon.module';
import { BbitTabsModule } from '../tabs/tab.module';
import { SettingsComponent } from './settings.component';

@NgModule({
  declarations: [SettingsComponent],
  exports: [SettingsComponent],
  imports: [
    BrowserModule,
    BbitCoreModule,
    BbitTabsModule,
    BbitIconModule,
    FlexLayoutModule,
    AgGridModule
  ],
  bootstrap: [SettingsComponent]
})
export class SettingsModule { }
