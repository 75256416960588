import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material';
import { BbitAction, BbitAuthService, BbitMessagingService, BbitMgmtApiService, BbitSessionState, CognitoSession, IBbitAuthOrganisation, IBbitEvent, IBbitSession, IBbitSessionSerialized, Utils } from '@bbit/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BbitInputSelectComponent } from './../input-select/input-select.component';
import { BbitInputTextComponent } from './../input-text/input-text.component';
import { BbitNotificationService } from './../notification/notification.service';
import { BbitLog } from '@bbit/log';

interface IOrgSelect { displayValue: string; value: IBbitAuthOrganisation; }

enum LoginSteps {
  ORGANISATION = 0,
  USER = 1,
  PASSWORD = 2,
  LOGINCHECK = 3,
  MFA = 4,
  SYNC = 5
}



@Component({
  selector: 'bbit-auth-login',
  templateUrl: 'login.component.pug',
})
export class BbitLoginComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('organizationSelect') organizationSelect: BbitInputSelectComponent;
  @ViewChild('userInput') userInput: BbitInputTextComponent;
  @ViewChild('passwordInput') passwordInput: BbitInputTextComponent;

  private _log = BbitLog.scope({
    package: 'BbitLoginComponent'
  });

  _loginSteps = {
    'ORGANISATION': 0,
    'USER': 1,
    'PASSWORD': 2,
    'LOGINCHECK': 3,
    'MFA': 4,
    'SYNC': 5
  };

  sessions: IBbitSessionSerialized[] = [];
  currentSession: IBbitSession = null;
  organizations: IOrgSelect[] = [];
  isStepValid: { [key: number]: boolean; } = { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false };

  displayValueOrganisation: string;
  inputErrorOrganisation: string;
  isStepOrganisationValid: boolean = false;

  inputValueUser: string;
  inputLabelUser: string = 'User';
  inputErrorUser: string;

  inputValuePassword: string;
  inputLabelPassword: string = 'Password';
  inputErrorPassword: string;

  inputValueResetCode: string;
  inputLabelResetCode: string = 'Reset Code';
  inputErrorResetCode: string;

  inputValueMfaCode: string;
  inputLabelMfaCode: string = 'MFA Code';
  inputErrorMfaCode: string;

  isLinear: boolean = true;
  isViewLoaded: boolean = false;
  isCheckingNewOrganisation: Promise<any> = null;
  isSendingResetCode: boolean = false;
  hasForgottenPassword: boolean = false;
  hasToken: boolean = false;
  hasMfaChecked: boolean = false;
  mfaType: string = 'optional';
  mfaDesignation: string = '';
  mfaProperties: { [type: string]: any } = null;

  organizationsMenu = [
    /* {
      action: "CREATE_ORGANIZATION",
      icon: "add",
      text: "Neue Organsation erstellen"
    } */
  ];
  organizationsSuffixes = [
    { action: 'CREATE_ORGANIZATION', icon: 'add', tooltip: 'Organisation hinzufügen' }
  ];

  _subscriptions: Subscription[];
  selectedStepperIndex: number = 0;

  constructor(
    private _authService: BbitAuthService,
    private _apiService: BbitMgmtApiService,
    private _messagingService: BbitMessagingService,
    private _notify: BbitNotificationService,
    private _cdr: ChangeDetectorRef,
    private _zone: NgZone) {
    this._subscriptions = [];
  }

  validateAll() {
    this.isStepValid[LoginSteps.ORGANISATION] = !!this.currentSession;
    this.isStepValid[LoginSteps.USER] = this.isUserValid() || this.hasToken;
    this.isStepValid[LoginSteps.PASSWORD] = this.isPasswordValid() || this.hasToken || this.hasForgottenPassword;
    this.isStepValid[LoginSteps.LOGINCHECK] = this.hasForgottenPassword ? !this.isSendingResetCode : !!this.hasToken;
    this.isStepValid[LoginSteps.MFA] = this.hasMfaChecked;
    this.isStepValid[LoginSteps.SYNC] = this.currentSession && this.currentSession.getState() === BbitSessionState.LOGGED_IN;

    this._log.log('validated stepper: ', {
      '0: ORGANISATION': this.isStepValid[LoginSteps.ORGANISATION],
      '1: USER': this.isStepValid[LoginSteps.USER],
      '2: PASSWORD': this.isStepValid[LoginSteps.PASSWORD],
      '3: LOGINCHECK': this.isStepValid[LoginSteps.LOGINCHECK],
      '4: MFA': this.isStepValid[LoginSteps.MFA],
      '5: SYNC': this.isStepValid[LoginSteps.SYNC]
    });

    this._cdr.markForCheck();
  }

  isUserValid() {
    if (!this.inputValueUser || this.inputValueUser.length === 0 || (this.inputErrorUser && this.inputErrorUser.length > 0)) {
      return false;
    }
    return true;
  }

  isPasswordValid() {
    if (!this.inputValuePassword || this.inputValuePassword.length === 0 || (this.inputErrorPassword && this.inputErrorPassword.length > 0)) {
      return false;
    }
    return true;
  }

  onAction($event) {
    if (!$event) return;

    this._zone.run(() => {
      switch ($event.action) {
        case 'CREATE_ORGANIZATION':
          alert('Bitte im bbit Administrationsportal eine neue Organisation erstellen');
          break;
        case 'FORGOT_USER':
          alert('Bitte wenden Sie sich an Ihren Administrator');
          break;
        case 'FORGOT_PW':
          this.initForgotPassword();
          break;
        case 'CANCEL_PW_RESET':
          this.hasForgottenPassword = false;
          this._setMfa('optional');
          break;
        case 'RESEND_PW_RESET_CODE':
          this.hasForgottenPassword = false;
          this.initForgotPassword();
          break;
        case 'CONFIRM_MFA_CHALLENGE':
          this.verifyMfaCode();
          break;
        case 'CANCEL_MFA_CHALLENGE':
          if (this.currentSession) this.currentSession.logout().catch(err => {
            this._log.error('error logging out', err);
          });
          break;
      }
    });
  }

  _setMfa(type: string, properties?: any) {
    this.inputLabelMfaCode = 'MFA Code';
    switch (type) {
      case 'optional':
        this.mfaDesignation = 'MFA';
        break;
      case 'new-password':
        this.mfaDesignation = 'New Password';
        break;
      case 'reset-password':
        this.mfaDesignation = 'Reset Passwort';
        break;
      case 'code':
        this.mfaDesignation = 'MFA Code';
        if (properties) {
          switch (properties.medium) {
            case 'SMS':
              this.inputLabelMfaCode = properties.destination ? `SMS Code (was sent to ${properties.destination})` : 'SMS Code';
          }
        }
        break;
      default:
        this._log.error('unknown mfa type', type);
        return;
    }
    this.mfaType = type;
    this.mfaProperties = properties;
    this._log.log('_setMfa', this.mfaType, this.mfaDesignation, this.mfaProperties);
  }

  _mapOrganisationToSelect(organisation: IBbitAuthOrganisation) {
    return { value: organisation, displayValue: organisation.key + ' - ' + organisation.name };
  }

  _lookupOrganisation(org: IBbitAuthOrganisation) {
    this.isCheckingNewOrganisation = this._apiService.lookupPublicOrganisation(org.key).then(
      result => {
        this._log.log('org lookup result', result);

        // patch for change of naming
        if (result.data && result.data['auth-provider'] === '#aws-cognito') {
          result.data['auth-provider'] = 'cognito';
        }

        const newOrgSelect = this._mapOrganisationToSelect(result.data);
        this.organizations.push(newOrgSelect);
        this.isCheckingNewOrganisation = null;

        this.onOrganizationSelect(newOrgSelect);
      },
      err => {
        this._log.error('org lookup error', err);
        this.isCheckingNewOrganisation = null;
        this.onOrganizationSelect(null);

        this._notify.showNotification({
          type: 'error',
          text: 'Organisation unbekannt',
          detail: Utils.stringifyIBbitRequestResult(err)
        });

        setTimeout(() => {
          this.organizationSelect.focus();
          this.organizationSelect.selectText();
        }, 50);
      }
    );

    return this.isCheckingNewOrganisation;
  }

  onOrganizationSelect(organization: IOrgSelect) {
    this.currentSession = null; // ToDo: check if that makes sense

    if (organization && organization.value) {
      if (organization.value && organization.value._id) {
        this._authService.createNewSession(organization.value).then(
          session => {
            this._authService.changeCurrentSessionTo(session, false);
            setTimeout(() => {
              this.stepper.next();
            }, 20);
          }
        );
      }
      else {
        this._lookupOrganisation(organization.value);
      }
    }
  }

  filterOrganizations(filter) {
    const res = this._filter(filter);
    return res;
  }

  _filter(term) {
    if (!term || term.length === 0) return this.organizations;
    term = term.toLowerCase();
    const filteredList = _.filter(this.organizations, (org: IOrgSelect) => org.displayValue.toLowerCase().startsWith(term));

    filteredList.push({
      value: { _id: null, key: term, name: 'Organisation verlinken', 'auth-provider': null },
      displayValue: term + ' Organisation verlinken'
    });
    return filteredList;
  }

  _onKeydown(input, event) {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
    }
    this._zone.run(() => {
      // the keydown event is fired before the input actually has changed
      setTimeout(() => {
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            switch (input) {
              case 'password':
                this.login();
                break;
              case 'mfacode':
                this.verifyMfaCode();
                break;
              default:
                if (this.isStepValid[this.stepper.selectedIndex]) {
                  this.stepper.next();
                }
            }
            break;

          default:
            switch (input) {
              case 'user':
                this.inputErrorUser = this.inputValueUser && this.inputValueUser.length > 0 ? '' : 'Bitte ausfüllen';
                break;
              case 'password':
                this.inputErrorPassword = this.inputValuePassword && this.inputValuePassword.length > 0 ? '' : 'Bitte ausfüllen';
                break;
              case 'organisation':
                this.inputErrorOrganisation = this.currentSession ? '' : 'Bitte ausfüllen';
                break;
              case 'resetcode':
                this.inputErrorResetCode = this.inputValueResetCode && this.inputValueResetCode.length > 5 ? '' : 'Bitte 6 stelligen Code eingeben';
            }
            this.validateAll();
        }
      }, 50);
    });
  }

  ngOnInit(): void {
    const self = this;

    this._subscriptions.push(this._authService.onSessionChanged((change: { old: IBbitSession, new: IBbitSession }) => {
      this._log.log('LoginComponent: ', 'changed current session');
      self.onCurrentSessionChanged(change.new);
    }));

    this._subscriptions.push(this._messagingService.observeTopic(null, BbitAction.ON_SESSION_STATE_CHANGES).subscribe(
      (event: IBbitEvent<{ session: IBbitSession, old: BbitSessionState, new: BbitSessionState }>) => {
        this._log.log('LoginComponent: ', event.payload.session === self.currentSession ? 'the currently displayed' : 'a hidden',
          ' session changed state to ', BbitSessionState[event.payload.new]);
        if (event.payload.session === self.currentSession) {
          self.onCurrentSessionStateChanged(event.payload.session, event.payload.new);
        }
      }
    ));

    this.reloadSessionListFromStore();

    if (this._authService.getCurrentSession()) {
      this.onCurrentSessionChanged(this._authService.getCurrentSession());
    } else {
      this._authService.loadLastSession().then((result) => {
        // result is handled with events
      }).catch((err) => {
        this._log.warning('was not able to loadLastSession', err);
        self.inputValuePassword = '';
        self.autoSelectStepper();
      });
    }
  }

  ngAfterViewInit() {
    this.isViewLoaded = true;
    this.autoSelectStepper();
  }


  ngOnDestroy(): void {
    this._subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });
  }

  reloadSessionListFromStore() {
    const self = this;

    return this._authService.getSessionStore().listSessions().then(res => {
      const orgs = _.uniqBy(_.map(res.data || [], (serialSession: IBbitSessionSerialized) => serialSession.organisation), org => org._id);

      this._log.log('available organisations', orgs);

      self.organizations = _.map(orgs, org => self._mapOrganisationToSelect(org));
    });
  }

  onCurrentSessionChanged(session: IBbitSession) {
    this.currentSession = session;

    const org = session ? session.getOrganisation() : null;

    // set display values
    this.displayValueOrganisation = org ? this._mapOrganisationToSelect(org).displayValue : null;

    this.inputLabelUser = org && org.cognito ? _.compact([
      org.cognito['use-email'] ? 'E-Mail' : null,
      org.cognito['use-username'] ? 'Username' : null,
      org.cognito['use-phone'] ? 'Phone number' : null,
    ]).join(' or ') : null;

    if (!this.inputLabelUser || this.inputLabelUser.length === 0) this.inputValueUser = 'User';

    this.onCurrentSessionStateChanged(session, session ? session.getState() : null);
  }

  onCurrentSessionStateChanged(session: IBbitSession, newState: BbitSessionState) {
    this.hasToken = false;
    this.hasMfaChecked = false;
    this.inputValueUser = session ? session.getLoginName() : '';

    switch (newState) {
      case BbitSessionState.LOGGING_IN:
      case BbitSessionState.LOGGED_IN:
      case BbitSessionState.NEED_NEW_PASSWORD:
      case BbitSessionState.NEED_MFA:
      case BbitSessionState.NEED_BRANCH:
        this.inputValuePassword = 'token';
        this.hasToken = true;
        break;
      case BbitSessionState.REFRESHABLE:
      case BbitSessionState.AUTHENTICATING:
        if (!this.inputValuePassword || this.inputValuePassword.length === 0) this.inputValuePassword = 'token';
        break;
      default:
        this.inputValuePassword = '';
    }

    let newIndex = undefined;

    switch (newState) {
      case BbitSessionState.NEED_NEW_PASSWORD:
        this._setMfa('new-password', session ? session.getChallengeProperties() : null);
        break;
      case BbitSessionState.NEED_MFA:
        this._setMfa('code', session ? session.getChallengeProperties() : null);
        break;
      case BbitSessionState.NEED_BRANCH:
        this._setMfa('branch', session ? session.getChallengeProperties() : null);
        break;
      default:
        this._setMfa('optional');
    }

    switch (newState) {
      case BbitSessionState.LOGGING_IN:
      case BbitSessionState.LOGGED_IN:
        this.hasMfaChecked = true;
        break;
    }

    switch (newState) {
      case BbitSessionState.REFRESHABLE:
        setTimeout(() => {
          this._authService.getCurrentSession().proceedLogin().catch(
            (err) => {
              this._log.error('autorefresh of session failed', err);
            }
          );
        }, 0);
        break;
    }
    this.autoSelectStepper();
  }

  autoSelectStepper() {
    this.validateAll();

    if (!this.isViewLoaded) return;

    const self = this;
    const currentSelection = this.stepper.selectedIndex;
    const MAX_STEPS = 6;

    let max = -1;
    for (let i = 0; i < MAX_STEPS; i++) {
      if (this.isStepValid[i]) max = i;
    }
    max = max + 1;
    if (max >= MAX_STEPS) max = MAX_STEPS - 1;

    let count = max - currentSelection;
    let changeIsNeeded = count !== 0;

    this._log.log('autoselect stepper index from ', currentSelection, ' to ', max, ' needsToChange: ', changeIsNeeded);

    if (changeIsNeeded) {
      if (count > 0) {
        count++;
        for (let i = 0; i < count; i++) {
          self.stepper.next();
          self._cdr.detectChanges();
        }
      } else {
        count = count * -1;
        for (let i = 0; i < count; i++) {
          self.stepper.previous();
          self._cdr.detectChanges();
        }
      }

      this.onSelectionChange(this.stepper);
    }
  }

  onSelectionChange(event) {
    const timeout = 100;
    const self = this;
    setTimeout(() => {
      switch (self.stepper.selectedIndex) {
        case LoginSteps.ORGANISATION:
          self.organizationSelect.focus();
          break;
        case LoginSteps.USER:
          self.userInput.focus();
          break;
        case LoginSteps.PASSWORD:
          self.passwordInput.focus();
          break;
      }
    }, timeout);
  }

  login() {
    const self = this;

    this.validateAll();
    if (!this.isStepValid[LoginSteps.ORGANISATION] || !this.isStepValid[LoginSteps.USER] || !this.isStepValid[LoginSteps.PASSWORD]) {
      return;
    }
    self.stepper.next();

    return this.currentSession.login(
      { logintype: 'emailpw', email: this.inputValueUser, password: this.inputValuePassword }).then((result) => {
        // this executes after the entire login process has finished
        // listen to the session events for details on the login process

        // self._notify.showNotification({
        //   type: "success",
        //   text: "Login erfolgreich",
        //   // detail: "Yipiäiye :)"
        // });
        return Promise.resolve(result);
      }).catch((err) => {
        this._log.error('error on login', err);
        self._notify.showNotification({
          type: 'error',
          text: 'Fehler beim Einloggen',
          detail: err.errorMessage && err.errorMessage.length > 0 ? err.errorMessage.toString() : JSON.stringify(err)
        });
        self.inputValuePassword = '';

        if (err && err.message === 'Incorrect username or password.') {
          self.inputErrorPassword = 'Incorrect username or password';
        }
        else {
          self.inputErrorPassword = err.errorMessage ? err.errorMessage.toString ? err.errorMessage.toString() : err.errorMessage : 'Unknown error';
        }

        self.autoSelectStepper();
      });
  }

  initForgotPassword() {
    const self = this;
    const session: CognitoSession = <any>this._authService.getCurrentSession();

    if (this.hasForgottenPassword) return;

    self.hasForgottenPassword = true;
    self.isSendingResetCode = true;

    this.validateAll();
    if (!this.isStepValid[LoginSteps.ORGANISATION] || !this.isStepValid[LoginSteps.USER]) {
      self.hasForgottenPassword = false;
      self.isSendingResetCode = false;
      return;
    }

    self._setMfa('reset-password');
    self.autoSelectStepper();

    // const temp = new Promise((resolve, reject) => { setTimeout(resolve, 2000); });
    const temp = session.initiateForgotPasswordRequest(this.inputValueUser);
    temp.then((result) => {
      self.isSendingResetCode = false;
      this._log.log('initiateForgotPasswordRequest', result);

      self.autoSelectStepper();
      // TODO translate
      self._notify.showNotification({
        type: 'success',
        text: 'Prüfcode gesendet!',
        // detail: "Yipiäiye :)"
      });
    }).catch((err) => {
      this._log.error('error on login', err);
      self._notify.showNotification({
        type: 'error',
        text: 'Fehler beim E-Mail senden',
        detail: err.errorMessage && err.errorMessage.length > 0 ? err.errorMessage.toString() : JSON.stringify(err)
      });
      self.isSendingResetCode = false;

      if (err && err.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number') {
        self.inputValuePassword = '';
        self.inputErrorUser = 'This is no registered/verified user.';
      }
      else if (err && err.message === 'Attempt limit exceeded, please try after some time.') {
        self.inputValuePassword = '';
        self.inputErrorUser = 'Attempt limit exceeded, please try after some time.';
      }
      self.autoSelectStepper();
    });
  }

  forgotPasswordSetNew(password: string) {
    const self = this;
    const session: CognitoSession = <any>this._authService.getCurrentSession();

    session.confirmPassword(this.inputValueResetCode, password).then((result) => {
      self.hasForgottenPassword = false;

      // TODO translate
      self._notify.showNotification({
        type: 'success',
        text: 'Passwort setzen erfolgreich',
        detail: 'Yipiäiye :)'
      });

      this.inputValuePassword = password;
      this.login();
    }, (err) => {
      this._log.error(err);
      self._notify.showNotification({
        type: 'error',
        text: 'Fehler beim Passwort setzen',
        detail: err.errorMessage && err.errorMessage.length > 0 ? err.errorMessage.toString() : JSON.stringify(err)
      });
    });
  }

  setNewPassword(password: string) {
    const self = this;
    const session: CognitoSession = <any>this._authService.getCurrentSession();

    session.completePasswordChallenge(password).then((result) => {
      // TODO translate
      self._notify.showNotification({
        type: 'success',
        text: 'Passwort setzen erfolgreich',
        detail: 'Yipiäiye :)'
      });

      this.inputValuePassword = password;
      this.login();
    }).catch((err) => {
      this._log.error(err);
      self._notify.showNotification({
        type: 'error',
        text: 'Fehler beim Passwort setzen',
        detail: err.errorMessage && err.errorMessage.length > 0 ? err.errorMessage.toString() : JSON.stringify(err)
      });
    });
  }

  verifyMfaCode() {
    const self = this;
    const session: CognitoSession = <any>this._authService.getCurrentSession();

    session.completeMfaChallenge(this.inputValueMfaCode).then((result) => {
      // TODO translate
      self._notify.showNotification({
        type: 'success',
        text: 'MFA erfolgreich'
      });
    }).catch((err) => {
      this._log.error(err);
      self._notify.showNotification({
        type: 'error',
        text: 'Fehler bei der MFA',
        detail: err.message
      });
    });
  }
}