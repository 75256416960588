import { IBbitI18n, IBbitTranslationType, BbitI18n } from '@bbit/i18n';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BbitController, BbitUiController, IBbitDisplayValueMeta, IBbitDisplayValueResult, IBbitExpression, IBbitSchemaDataField, IBbitSelect } from '@bbit/core';
import { BbitLog } from '@bbit/log';

@Component({
  selector: 'bbit-ui-field',
  templateUrl: 'field.component.pug',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitUiFieldComponent {

  @Input() controller: BbitController;
  @Input() translationType: IBbitTranslationType;
  @Input() uiController: BbitUiController;
  @Input() ui: any;

  @Input() type: string;
  @Input() class: string;

  @Input() label: IBbitExpression;
  @Input() tooltip: IBbitExpression;
  @Output() action: EventEmitter<any> = new EventEmitter();

  private _log = BbitLog.scope({
    package: 'BbitUiFieldComponent'
  });

  _type: string;
  _hasFocus: boolean;
  _isReadOnly: boolean;
  _isVisible: boolean;
  _isLoading: boolean;
  _translationValues: { [key: string]: string } = {};

  _label: string | IBbitI18n;
  _tooltip: string | IBbitI18n;
  _displayValue: string;
  _jsonValue: any;
  _required: boolean;

  _denyReadPermission: string;
  _denyWritePermission: string;

  _error: string | IBbitI18n;
  _warning: string | IBbitI18n;
  _info: string | IBbitI18n;
  _disabled: boolean;

  _subscription: any;
  _schemaElement: IBbitSchemaDataField;
  _selector: IBbitSelect;
  _hiddenFields: string[];
  _visibleFields: string[];
  _requiredFields: string[];
  _coordinatesOnly: boolean;
  _defaultCountry: string[];
  _allowedMimeTypes: string;
  _fileBucketName: string;
  _fileKeyPrefix: string;
  _datepicker: boolean;

  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this._type = this.ui.inputtype;
    this._isVisible = true;

    this._subscription = this.uiController.getUiChangedObservable().subscribe(() => {

      if (!this._hasFocus) {
        this.loadValueFromController();
        this.loadInfoWarnOrErrorMessage(null, null, null);
      }
    });

    this.loadValueFromController();
    this.loadInfoWarnOrErrorMessage(null, null, null);
  }

  /* ngDoCheck(): void {
      if (this._type === "delta") {
          this._jsonValue = _.cloneDeep(this.ui.jsonValue);
      }

      throw new Error("Method not implemented.");
  } */

  ngOnDestroy(): void {
    this._dispose();
  }

  private _dispose(): void {
    if (this._subscription) this._subscription.unsubscribe();
    this._subscription = null;
    this._schemaElement = null;
  }

  _onBlur($event) {
    this._hasFocus = false;
    this.loadValueFromController();
    this.loadInfoWarnOrErrorMessage(null, null, null);
  }

  _onFocus($event) {
    this._hasFocus = true;
  }

  _onSuffixAction($event) {

    this.action.emit($event);
  }

  _onValueChange($event) {
    if ($event && $event.displayValue) {
      this.setDisplayValueToController($event.displayValue, $event);
    }
    else {
      this.setDisplayValueToController($event, null);
    }
  }

  _onTranslationChange($event, translation) {
    this.setDisplayValueToController($event, { language: translation });
  }


  _onFileChange($event: any) {
    this.setDisplayValueToController($event, null);
  }

  _onIdChange($event) {
    this.setDisplayValueToController(undefined, { _id: $event, _ids: $event });
  }

  /**
   * Load
   *
   * @param {boolean} [force=false]
   * @param {boolean} [awaitCache=true] helper flag to avoid inifinite recursions
   * @returns
   *
   * @memberOf BbitInput
   */
  loadValueFromController() {
    const self = this;

    if (this.ui) {

      this._translationValues = this.ui.translationValues;

      if (this._type === 'delta') {
        // the jsonValue object itself does not change it's reference, so it is not updated through change detection
        // fix this through enforcing a new object
        this._jsonValue = { ops: this.ui.jsonValue ? this.ui.jsonValue.ops : [] };
      }
      else {
        this._jsonValue = this.ui.jsonValue;
      }
      this._required = this.ui.required;
      this._isVisible = this.ui.isVisible;
      this._disabled = this.ui.disabled;
      this._label = this.ui.title;
      this._tooltip = this.ui.tooltip;
      this._selector = this.ui.selector;
      this._displayValue = BbitI18n.t(this.ui.displayValue);

      this._hiddenFields = this.ui.addressHiddenFields;
      this._visibleFields = this.ui.addressVisibleFields;
      this._requiredFields = this.ui.addressRequiredFields;
      this._defaultCountry = this.ui.addressDefaultCountry;
      this._coordinatesOnly = this.ui.addressCoordinatesOnly;

      this._datepicker = this.ui.datepicker;
    }

    this._cdr.markForCheck();
  }

  loadInfoWarnOrErrorMessage(error: string | IBbitI18n, warning: string | IBbitI18n, info: string | IBbitI18n): void {
    this._error = error;
    this._warning = warning;
    this._info = info;

    if (this.ui) {

      if (!this._error) {
        this._error = this.ui.error;
      }

      if (!this._warning) {
        this._warning = this.ui.warning;
      }
    }

    this._cdr.markForCheck();
  }

  setDisplayValueToController(value: any, meta: IBbitDisplayValueMeta): void {

    if (!meta) meta = {};
    // if (!meta.language) meta.language = this.translationType;

    let result = this.controller.setFromDisplayValue(this.ui.jsonPath, value, meta);

    if (!result) {
      result = { statusCode: 400, errorMessage: 'controller.setFromDisplayValue returned no result' };
    }

    if (result.statusCode < 400) {
      const newState: IBbitDisplayValueResult = result.data[0]; // first element, because the data in setvalue get all changed set fields

      if (newState.newDisplayValue !== undefined) {
        this._displayValue = newState.newDisplayValue;
      }

      this.loadInfoWarnOrErrorMessage(this._hasFocus ? newState.parsingErrorMessage : null, null, this._hasFocus ? newState.infoMessage : null);
    }
    else {
      this._log.warning('Error on controller.setFromDisplayValue ', value, ': ', JSON.stringify(result.errorMessage));
      this.loadInfoWarnOrErrorMessage(BbitI18n.t(result.errorMessage[0]) + (result.data && result.data[0] ? ': ' + BbitI18n.t(result.data[0]) : ''), null, null);
    }
  }

  handleAction(event: any) {
    this.action.emit(event);
  }

}
