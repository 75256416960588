import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BbitShortcutService } from './../shortcut/shortcut.service';
import { BbitI18n } from '@bbit/i18n';


@Component({
  selector: 'bbit-toolbar',
  templateUrl: 'toolbar.component.pug',
  styleUrls: ['toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitToolbarComponent implements OnChanges {

  @Input() icon: string;
  @Input() title: string;
  @Input() titleI18n: string;
  @Input() menu: any;
  @Input() buttons: any;
  @Input() color: string;
  @Input() search: boolean = false;
  @Input() searchValue: string;

  @Output('action') actionEventEmitter = new EventEmitter();
  @Output('searchValueChange') searchValueEventEmitter = new EventEmitter();

  style: any = {};

  constructor(
    private _shortcutService: BbitShortcutService) {
  }

  ngOnInit() {
    if (this.color) {
      this.style.background = this.color;
    }
  }

  ngOnChanges(changes) {
    if (changes['buttons']) {
      if (this.buttons) {
        for (let button of this.buttons) {
          if (button.action) {
            button.tooltipWithShortcut = BbitI18n.t(button.tooltip) + ' ' + this._shortcutService.getShortcutText(button.action);
          }
        }
      }
    }
  }

  onSearchValueChange(event) {
    this.searchValue = event;
    this.searchValueEventEmitter.emit(event);
  }

  handleAction(event) {
    this.actionEventEmitter.emit(event);
  }

}