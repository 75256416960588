import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule, MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitIconModule } from './../icon/icon.module';
import { BbitInputTextModule } from './../input-text/input-text.module';
import { BbitInputSelectComponent } from './input-select.component';
import { BbitI18nModule } from '@bbit/i18n';


@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    BbitCoreModule,
    BbitInputTextModule,
    BbitI18nModule,
    BbitIconModule,
    MatSelectModule,
    MatTooltipModule,
    OverlayModule
  ],
  exports: [
    BbitInputSelectComponent
  ],
  declarations: [
    BbitInputSelectComponent
  ]
})
export class BbitInputSelectModule { }
