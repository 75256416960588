import { BbitGeocodeService } from './geocode.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    BrowserModule
  ],
  exports: [
  ],
  declarations: [
  ],
  providers: [
    BbitGeocodeService
  ]
})
export class BbitGeocodeModule { }
