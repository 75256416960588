import { BbitUtils, BbitString } from '@bbit/utils';
import { BbitI18n } from '@bbit/i18n';
import { BbitStyleService, BbitColor } from './../style/style.service';
import { ChangeDetectorRef, Component, NgZone, ElementRef, ViewChild } from '@angular/core';
import { BbitAuthService, BbitController, BbitSchemaUtil, BbitSelectService, BbitSettingsService, IBbitSelect, IBbitSelectColumn, IBbitToolbar, IBbitUiListView, INewSettingObject, isBbitUiListView, SettingScope, StringHelpers, Utils, BbitFileService, IBbitFileState } from '@bbit/core';
import { GridOptions, RowNode } from 'ag-grid-community';
import * as interact from 'interactjs';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BbitNotificationService } from '../notification/notification.service';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { BbitLog } from '@bbit/log';
import { Camt54Parser } from '@bbit/iso20022';


interface IBbitListView {
  _id: string;
  def: IBbitSelect;
  scope: SettingScope[];
  global: boolean;
  modified: boolean;
  delete: boolean;
  isEditing: boolean;
}

@Component({
  templateUrl: 'list-controller.component.pug',
  styleUrls: [
    'list-controller.component.scss'
  ]
})
export class BbitUiListControllerComponent implements IBbitTabController {

  private _log = BbitLog.scope({
    package: 'BbitUiListControllerComponent'
  });

  tab: IBbitTabInterface = null; // warning: still externaly accessed, ToDo: refactor such shit
  isActive: boolean = false;

  uiSchema: IBbitUiListView;
  toolbar: IBbitToolbar;

  private _cacheController: BbitController;
  private _cacheControllerSubscribtion: Subscription;
  private _selector: IBbitSelect;
  private _agGridApi;

  previewWidth: number = 400;
  debouncedReload: any;
  loaded: boolean = false;
  quickfilterValue: string;
  gridOptions: GridOptions;
  showViewPanel: boolean = false;
  showColumnPanel: boolean = false;
  rowData: any[];
  columnDefs: any[];
  views: IBbitListView[];
  previews: {
    title: string;
    docId: string;
    controller: BbitController;
  }[] = [];
  activePreview: {
    title: string;
    docId: string;
    controller: BbitController;
  };
  activeRow: RowNode;
  columnToolbar: any;
  viewToolbar: any;
  paramsFilter: {
    id: string,
    field: string,
    filterValue: any
  };

  viewMenu = [{
    text: { key: 'delete' },
    action: 'DELETE-VIEW'
  }, {
    text: { key: 'edit' },
    action: 'EDIT-VIEW'
  }, /* {
        text: { key: "set-as-default" },
        action: "SET-AS-DEFAULT"
    } */
  ];

  /* column panel */
  allColumns = [];
  // selectedColumns: IBbitDisplayColumn[] = [];


  protected listView: BbitController;
  search: string;

  gridReady: boolean = false;

  constructor(
    private _zone: NgZone,
    protected _tabService: BbitTabService,
    protected _selectService: BbitSelectService,
    protected _cdr: ChangeDetectorRef,
    protected _settingsService: BbitSettingsService,
    protected _authService: BbitAuthService,
    private _notify: BbitNotificationService,
    private _style: BbitStyleService,
    private _elementRef: ElementRef,
    private _fileService: BbitFileService
  ) {

  }


  rowDoubleClicked(event) {
    if (event && event.data && event.data._id) {
      this.openDetail(event.data._id);
    }
  }


  rowClicked(event) {

    if (event && event.data && event.data._id) {
      const resetRow = this.activeRow;
      this.openPreview(this.tab.uniqueEntityName, event.data._id, true);

      if (resetRow) {
        resetRow.setDataValue('isInitialPreviewRow', false);
      }
      this.activeRow = event.node;
      this.activeRow.setDataValue('$isInitialPreviewRow', true);
    }


  }

  closePreview() {
    this.previews = [];
    this.activePreview = null;
    if (this.activeRow) {
      this.activeRow.setDataValue('$isInitialPreviewRow', false);
      this.activeRow = null;
    }
  }

  selectPreview(index: number) {
    this.activePreview = this.previews[index];
    this.previews.length = index + 1;
  }

  openPreview(uniqueEntityName: string, id: string, reset: boolean) {

    if (this.activePreview && this.activePreview.docId === id) {
      return;
    }

    const self = this;
    let controller = self._selectService.getCacheControllerWithLoadedId(this._authService.getCurrentSession(), uniqueEntityName, id);
    if (Utils.isPromise(controller)) {
      controller.then(() => {
        controller = self._selectService.getCacheControllerWithLoadedId(this._authService.getCurrentSession(), uniqueEntityName, id);
        this._doOpenPreview(<BbitController>controller, id, reset);
      });

    } else {
      this._doOpenPreview(controller, id, reset);
    }

  }

  _doOpenPreview(controller: BbitController, id: string, reset: boolean) {
    const self = this;
    if (controller.getSchema().hasReadPermission(this._authService.getCurrentSession()) && controller.getSchema().hasPreviewView('desktop')) {

      if (reset) {
        self.previews = [];
      }

      if (this.activePreview && this.activePreview.docId === id) {
        return;
      } else {
        const preview = {
          title: BbitI18n.t(controller.evaluate(controller.getSchema().getUiSchema('desktop', 'preview').title, {}, id).data),
          controller: controller,
          docId: id
        };
        self.previews.push(preview);
        self.activePreview = preview;
      }
    }
  }

  previewAttached(element) {
    interact(element)
      .resizable({
        // resize from all edges and corners
        edges: { left: true, right: false, bottom: false, top: false },

        onmove: (event: any) => {

          let width = event.rect.width;
          // element.offsetWidth = width;
          this.previewWidth = width;
          // this.sideNavWidth = width;
          // event.target.style.flex = `0 0 ${width}px`;

        },

        onend: (event) => {
          // TODO implement save
          // self.saveEvent(event.target.getAttribute("data-id"));
        }

      }
      );
  }

  openDetail(rowId: string = null, uniqueEntityName: string = null) {

    let params: any = {};

    if (rowId) {
      params.action = 'OPEN';
      params.entityId = rowId;
    } else {
      params.action = 'NEW';
    }

    this._tabService.create({
      uniqueEntityName: uniqueEntityName || this.tab.uniqueEntityName,
      viewName: this.uiSchema.detailViewName || 'detail',
      params: params
    });
  }


  quickfilter(value: string) {
    this.gridOptions.api.setQuickFilter(value);
    this.quickfilterValue = value;
    this._cdr.markForCheck();
  }

  hasWritePermissions() {
    const schema = this.listView ? this.listView.getSchema() : null;

    if (schema) return schema.hasWritePermission(this._selectService.getAuthService().getCurrentSession());

    return false;
  }

  injectParams(tab) {
    // store Reference
    const self = this;
    self.tab = tab;

    self.listView = self.tab.controller || new BbitController();
    let error = self.listView.setup({
      uniqueEntityName: self.tab.uniqueEntityName,
      schemaCompilation: self._selectService.getSchemaService().getSchemaCompilation(self._selectService.getAuthService().getCurrentSession()),
      selectService: this._selectService,
      sessionRef: self._selectService.getAuthService().getCurrentSession(),
      onExternalRowUpdate: 'merge',
      onExternalRowInsert: 'append',
      onExternalRowDelete: 'remove'
    });


    const dataSchema = self.listView.getSchema();
    const newUiSchema = dataSchema.getUiSchema('desktop', self.tab.viewName);
    if (!newUiSchema) return this._log.error('error getting uiSchema for view ' + self.tab.viewName);

    if (!isBbitUiListView(newUiSchema)) {
      return this._log.error('error getting uiSchema for view ' + self.tab.viewName);
    }
    else {
      self.uiSchema = newUiSchema;
    }

    let addButton = {
      icon: 'add',
      action: 'NEW',
      tooltip: { key: 'new', n: 1 }
    };

    if (dataSchema.getUniqueEntityName() === 'camt') {
      addButton = {
        icon: 'note_add',
        action: 'NEW_CAMT',
        tooltip: { key: 'new', n: 1 }
      };
    }

    self.tab.text = self.uiSchema.title;
    self.tab.icon = self.uiSchema.icon;
    self.toolbar = {
      icon: self.uiSchema.toolbar.icon || self.uiSchema.icon || '',
      title: self.uiSchema.toolbar.title || self.uiSchema.title,
      buttons: _.compact([
        {
          icon: 'more_vert',
          tooltip: { key: 'more', n: 1 },
          menu: [
            {
              icon: 'sync',
              action: 'SYNC',
              text: { key: 'sync-list', n: 1 }
            },
            {
              icon: 'assessment',
              action: 'EXPORT-AS-EXCEL',
              text: { key: 'excel-export', n: 1 }
            },
            {
              icon: 'view_column',
              action: 'TOGGLE-COLUMN-PANEL',
              text: { key: 'toggle-columneditor-panel', n: 1 }
            },
            {
              icon: 'view_compact',
              action: 'TOGGLE-VIEW-PANEL',
              text: { key: 'toggle-view-panel', n: 1 }
            },
            {
              icon: 'mdi:filter-remove',
              action: 'RESET-FILTER',
              text: { key: 'reset-filter', n: 1 }
            }
          ]
        },
        self.hasWritePermissions() ? addButton : null,
        {
          icon: 'close',
          tooltip: { key: 'close', n: 1 },
          action: 'CLOSE-TAB'
        }
      ])
    };

    self.columnToolbar = {
      icon: 'view_column',
      title: { key: 'column' },
      buttons: _.compact([
        {
          icon: 'done',
          action: 'APPLY-COLUMNS',
          tooltip: { key: 'apply-column' }
        },
        self.hasWritePermissions() ? {
          icon: 'add',
          action: 'ADD-COLUMN',
          tooltip: { key: 'add-column', n: 1 }
        } : null
      ])
    };

    self.viewToolbar = {
      icon: 'view_compact',
      title: { key: 'view' },
      buttons: _.compact([
        self.hasWritePermissions() ? {
          icon: 'svg:global-add',
          action: 'ADD-GLOBAL-VIEW',
          tooltip: { key: 'add-global-view', n: 1 }
        } : null,
        {
          icon: 'add',
          action: 'ADD-VIEW',
          tooltip: { key: 'add-view', n: 1 }
        },
      ])
    };

    // { field: "_id", width: "350px" }, { field: "_rev", width: "350px" }, { field: "$changedBy", width: "350px" }, { field: "$changedAt", width: "350px" }
    self._selector = self.uiSchema.selector || { select: [], where: null };
    self._selector.from = self.tab.uniqueEntityName;
    // self._selector.select = []; // <-- uncomment this to enforce loading all columns
    // self.updateSelectorSelect();

    self.debouncedReload = _.throttle(this.reloadData, 2000);
    self.gridOptions = <GridOptions>{
      onGridReady: self.onGridReady.bind(self),
      deltaRowDataMode: true,
      animateRows: true,
      getRowNodeId: (row) => { return (row._subid ? row._id + '.' + row._subid : row._id); },
      floatingFilter: true,
      rowGroupPanelShow: 'always',
      showToolPanel: false,
      getContextMenuItems: () => {
        return [
          'copy',
          'copyWithHeaders'
        ];
      },
      icons: {
        menu: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">more_vert</i>',
        filter: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">search</i>',
      },
      rowClassRules: {
        'bbit-grid-row-active-preview': (params) => {
          if (self.previews && self.previews.length > 0) {
            if (params.node.id === self.previews[0].docId) {
              return true;
            }
          }
          return false;
        }
      }
    };


    self.paramsFilter = null;
    if (tab.params.referenceField) {
      self.paramsFilter = {
        id: tab.params.id,
        field: tab.params.referenceField,
        filterValue: tab.params.filterValue
      };
    }

    if (this.listView.getSchema().getDesktopColor()) {
      this._style.applyStyles({
        secondaryColor: new BbitColor(this.listView.getSchema().getDesktopColor())
      }, this._elementRef.nativeElement);
    }

    // self.gridOptions.defaultColDef = {
    //     headerComponentFramework: <{ new(): BbitGridHeaderComponent }>BbitGridHeaderComponent
    // };

  }

  getJoinTypes(currentDisplayValue: string): Promise<any> {
    const joinTypes = [{
      _id: 'first',
      displayValue: 'first',
    }, {
      _id: 'last',
      displayValue: 'last',
    }, {
      _id: 'all',
      displayValue: 'all',
    }, {
      _id: 'index',
      displayValue: 'at index',
    }, {
      _id: 'filter',
      displayValue: 'filter',
    }];

    const searchRegex = StringHelpers.getSearchTermRegex(currentDisplayValue);

    const resultList = _.filter(joinTypes, col => searchRegex.test(col.displayValue));

    return Promise.resolve(resultList);
  }

  getSelectColumns(currentDisplayValue: string): Promise<any> {
    const self = this;
    const schema = self.listView.getSchema();
    const dataSchema = self.listView.getSchema();

    if (!this.allColumns || this.allColumns.length === 0) {
      const entireColumnList = dataSchema.getVirtualFieldList(self.listView.getSchemaCompilation(), [], 0);

      this.allColumns = _.sortBy(_.map(entireColumnList, column => {
        const pathparts: string[] = BbitSchemaUtil.getNumberedIndexPathFromSchemaPath(column);
        const pathes = [];

        for (let i = 1; i <= pathparts.length; i++) {
          pathes.push(pathparts.slice(0, i));
        }

        const names = _.map(pathes, patharray => {

          if (patharray[patharray.length - 1].substr(0, 1) === '[') {
            return patharray[patharray.length - 1];
          }
          else {
            try {
              const element = self.listView.getSchemaElementIn(patharray, true);

              if (element && element.label) {
                return BbitI18n.t(Utils.stringifyIBbitRequestResult(self.listView.evaluate(element.label, {}, patharray.join('.'))));
              }
              else {
                return patharray[patharray.length - 1];
              }
            }
            catch (err) {
              return patharray[patharray.length - 1]; // Todo: + "(err: " + err + ")";
            }
          }
        });

        const retrievedRow: any = {
          _id: pathparts.join('.'),
          columnId: BbitSchemaUtil.getColumnIdFromJsonPath(pathparts),
          displayValue: names.slice(1).join('.') // + " (json: " + path + ")",
        };

        return retrievedRow;
      }), 'displayValue');
    }

    const searchRegex = StringHelpers.getSearchTermRegex(currentDisplayValue);

    const resultList = _.filter(this.allColumns, col => searchRegex.test(col.displayValue));

    return Promise.resolve(resultList);
  }

  onSelectColumn(column: IBbitSelectColumn, $event) {
    if (!column) return;

    if ($event && $event._id) {
      const path: string = $event._id;

      column.field = $event._id;
      column.columnId = $event.columnId;
      column.joins = <any>_.map(_.compact(path.match(/\.\[#(\d+)\]/g)) || [], match => {
        return { index: parseInt(match.substring(3, match.length - 1)), expression: '', type: 'first' };
      });
    }
    else {
      column.columnId = null;
      column.field = null;
      column.joins = [];
    }
  }

  deleteColumn(column) {
    this._selector.select = _.filter(this._selector.select, col => col !== column);
  }

  onGridReady(params) {
    this._agGridApi = params.api;
    const self = this;
    self._zone.run(() => {

      self._cacheController = self._selectService.getCacheController(self._selectService.getAuthService().getCurrentSession(), self._selector.from);
      self._cacheControllerSubscribtion = self._cacheController.getObservableStream('dataChanged').subscribe((change) => {
        if (self.loaded) {
          self.debouncedReload('debounced on dataChanged');
        }
      });

      self.loadViews();
      self.applyView(null, true);
      self._cdr.markForCheck();
    });
  }

  getSettingPrefix() { return 'grid-view-' + this.tab.uniqueEntityName; }
  isGlobalView(view) { return view && view.scope && view.scope.indexOf(SettingScope.User) < 0 && view.scope.indexOf(SettingScope.Device) < 0; }

  loadViews() {

    this.views = _.map(this._settingsService.listSettingsByPrefix(this.getSettingPrefix()), setting => {

      const value = setting.get('value');
      const view: IBbitListView = {
        _id: setting.get('key'),
        def: {
          name: value ? value.name : null,
          select: value ? value.select : null,
          where: null,
          guiFilter: value ? value.guiFilter : null,
          guiSort: value ? value.guiSort : null,
          guiQuickFilter: value ? value.quiQuickFilter : null,
        },
        scope: setting.get('scope') || [SettingScope.Mandator, SettingScope.User],
        global: false,
        modified: false,
        delete: false,
        isEditing: false
      };

      view.global = this.isGlobalView(view);

      return view;
    });

    const defaultViewId = this.getSettingPrefix() + '-global-default';

    if (!_.some(this.views, view => view._id === defaultViewId)) {
      this.views.push({
        _id: defaultViewId,
        def: this.uiSchema.selector || { name: 'Standard', select: [], where: null },
        scope: [SettingScope.Mandator],
        global: true,
        modified: false,
        delete: false,
        isEditing: false
      });
    }

  }

  applyView(view: IBbitListView, global?: boolean) {

    this.quickfilter('');

    if (!view) {
      view = _.find(this.views, { _id: this.getSettingPrefix() + '-global-default' });
    }

    if (!view) {
      this._log.error('view is empty and no default view is defined!');
      return;
    }

    this._selector = _.clone(view.def);
    this._selector.from = this.tab.uniqueEntityName;
    if (!this._selector.select) this._selector.select = [];


    if (this.paramsFilter) {
      const field = `[#0].${this.paramsFilter.field}`;
      if (!_.find(this._selector.select, { field: field })) {
        this._selector.select.push({
          field: field
        });
      }
      if (!this._selector.guiFilter) {
        this._selector.guiFilter = {};
      }
      this._selector.guiFilter[this.paramsFilter.field] = this.paramsFilter.filterValue;
      this.paramsFilter = null;

    }
    else {
      this._selector.guiFilter = {};
    }

    const test = this._selectService.getGridHeaders(this._selector, this.listView.getSession());
    this.gridOptions.api.setColumnDefs(<any>test);

    const self = this;
    this.reloadData('applyView').then(() => {

      self.applyFilterAndSort();
      self.loaded = true;
      self._cdr.markForCheck();
    });

  }

  applyFilterAndSort() {
    if (this._selector.guiFilter) {
      this.gridOptions.api.setFilterModel(this._selector.guiFilter);
    }

    if (this._selector.guiSort) {
      this.gridOptions.api.setSortModel(this._selector.guiSort);
    }

    if (this._selector.guiQuickFilter) {
      this.quickfilter(this._selector.guiQuickFilter);
    }
  }

  canEditGlobalViews() {
    return this._authService.getCurrentSession().hasWritePermission('grid-global-views');
  }

  addView(scope: SettingScope[]) {
    const view = {
      _id: null,
      def: {
        name: name,
        select: [],
        where: null,
        guiFilter: null,
        guiSort: null,
        guiQuickFilter: null
      },
      scope: scope,
      global: false,
      modified: true,
      delete: false,
      isEditing: true
    };

    view.global = this.isGlobalView(view);

    this.views.push(view);
  }

  saveView(view: IBbitListView) {
    if (!view.def.name || view.def.name.length === 0) {
      this._notify.showNotification({
        type: 'error',
        text: 'Fehler beim Speichern',
        detail: 'Name darf nicht leer sein'
      });
      return;
    }

    if (!view._id || view._id.length === 0) {
      view._id = this.getSettingPrefix() + '-' + StringHelpers.slugify(view.def.name);
    }

    const self = this;
    view.def.select = _.compact(_.map(this.gridOptions.columnApi.getAllGridColumns(), col => {
      const id = col.getColId();
      const theCol = _.find(self._selector.select, selected => selected.columnId === id);

      this._log.info('setting saveView col', self._selector.select, theCol, col);
      if (!theCol) return null;

      const val: IBbitSelectColumn = {
        columnId: id,
        field: theCol.field,
        displayValue: theCol.displayValue,
        joins: theCol.joins,
        visible: col.isVisible(),
        width: <any>col.getActualWidth()
      };
      return val;
    }));

    view.def.guiFilter = this.gridOptions.api.getFilterModel();
    view.def.guiSort = this.gridOptions.api.getSortModel();
    view.def.guiQuickFilter = this.quickfilterValue;

    view.modified = true;
    view.isEditing = false;

    this.saveViews();
  }

  saveViews() {

    const newSettings = _.compact(_.map(this.views, view => {
      if (!view.isEditing && (view.modified || view.delete)) {
        // let scope = view.global ? [SettingScope.Mandator] : [SettingScope.User, SettingScope.Mandator];
        const newSetting: INewSettingObject = { key: view._id, value: view.delete ? undefined : view.def, scope: view.scope };
        return newSetting;
      }
      return null;
    }));

    return this._settingsService.setSettings(newSettings, this._selectService.getAuthService().getCurrentSession()).then(
      (results) => {
        this.loadViews();
        this._notify.showNotification({
          type: 'success',
          text: 'Speichern erfolgreich',
          // detail: "Revision: " + this.controller.getDocumentRevision("[0]")
        });
      },
      (error) => {
        this._log.error(error);
        this._notify.showNotification({
          type: 'error',
          text: 'Fehler beim Speichern',
          detail: error.toString()
        });
      }
    );
  }

  resetFilterAndSort() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.setQuickFilter(null);
    this.gridOptions.api.setSortModel(null);
    this.quickfilter('');
  }

  reloadData(triggerSource: string) {
    const self = this;

    this._log.info('gridData start triggered by ', triggerSource);
    return self._selectService.select(self._selectService.getAuthService().getCurrentSession(), self._selector).then(
      result => {
        if (self.gridOptions && self.gridOptions.api) {
          this._log.info('gridData', triggerSource, result.data);
          self.gridOptions.api.setRowData(result.data);
          self._cdr.markForCheck();
        }
        else {
          this._log.error('ERROR SETTING VALUE, FUCKING GRID NOT READY', self.gridOptions);
        }

        if (result.promises && _.compact(result.promises).length > 0) {
          this._log.warning('select returned with ', result.promises.length, ' promises, schedule additional reload');
          Promise.all(result.promises).then(() => this.reloadData('recursive reload data')).catch((err) => {
            this._log.error('error reloading async getDisplayData Values: ', err);
          });
        }

        return Promise.resolve(null);
      },
      error => {
        this._log.error('ERROR ON SELECT', error);
      }
    );
  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(event, param?: any) {
    // if (_.isPlainObject(event.action)) {
    //   this._tabService.handleMessage(null, event.action);
    // }
    // else {
    switch (event.action) {
      case 'EXPORT-AS-EXCEL':
      this._agGridApi.exportDataAsExcel({
        processCellCallback: (params) => {
          const moment = BbitI18n.getMoment();
          if (params.value) {
            console.log('arsch 1', params.value);
            if (params.value instanceof Object) {

              // date
              if (params.value.date) {
                return params.value.displayValue;
              }

              // amount
              if (params.value.raw) {
                return params.value.raw.amount;
              }

            }
          }
          return params.value;
        }
      });
        break;
      case 'NEW':
        this.openDetail(null);
        break;
      case 'NEW_CAMT':
        this.newWithCamt();

        break;
      case 'EDIT':
        if (this.activePreview) {
          this.openDetail(this.activePreview.docId, this.activePreview.controller.getSchema().getUniqueEntityName());
        }
        break;
      case 'TOGGLE-VIEW-PANEL':
        this.showViewPanel = !this.showViewPanel;
        if (this.showViewPanel) {
          this.showColumnPanel = !this.showViewPanel;
        }
        break;
      case 'TOGGLE-COLUMN-PANEL':
        this.showColumnPanel = !this.showColumnPanel;
        if (this.showColumnPanel) {
          this.showViewPanel = !this.showColumnPanel;
        }
        break;
      case 'RESET-FILTER':
        this.resetFilterAndSort();
        break;
      case 'ADD-COLUMN':
        this._selector.select.push({
          columnId: null,
          field: null,
          displayValue: null,
          joins: []
        });
        break;
      case 'APPLY-COLUMNS':
        this.gridOptions.api.setColumnDefs(<any>this._selectService.getGridHeaders(this._selector, this.listView.getSession()));
        this.reloadData('apply-columns').then(() => {
          this._cdr.markForCheck();
        });
        break;

      case 'ADD-GLOBAL-VIEW':
        this.addView([SettingScope.Mandator]);
        break;

      case 'ADD-VIEW':
        this.addView([SettingScope.Mandator, SettingScope.User]);
        break;

      case 'DELETE-VIEW':
        param.delete = true;
        this.saveViews();

        break;
      case 'EDIT-VIEW':
        param.isEditing = true;
        break;
      case 'SET-AS-DEFAULT':
        this._log.error('ToDo');
        break;

      case 'CLOSE-PREVIEW':
        this.closePreview();
        break;

      case 'CLOSE-TAB':
        this._tabService.close(this.tab);
        break;

      case 'OPEN-PREVIEW':
        this.openPreview(event.params.uniqueEntityName, event.params.id, false);
        break;

      case 'OPEN-REFERENCED-LIST':

        let params = event.params;
        params.action = 'OPEN';
        params.id = this.activePreview.docId;

        this._tabService.create({
          uniqueEntityName: event.params.uniqueEntityName,
          viewName: 'list',
          params: params
        });
        break;

      case 'SYNC':
        this._cacheController.getSelectService().request('SYNC', this._cacheController.getSchema().getUniqueEntityName(), {}, this._cacheController.getSession()).then(() => {
          // TODO translate
          this._notify.showNotification({
            type: 'success',
            text: 'Liste erfolgreich synchronisiert',
          });
        }).catch((err) => {
          // TODO translate
          this._notify.showNotification({
            type: 'error',
            text: 'Fehler beim Synchronisieren',
            detail: Utils.stringifyIBbitRequestResult(err)
          });
        });
        break;

      default:
        this._log.error('unknown action ' + event.action);
        return;
    }
  }
  // }


  updateTitle() {

  }

  async newWithCamt() {
    const dataSchema = this.listView.getSchema();
    const mandator = StringHelpers.stripBeginningHashtag(this._authService.getCurrentSession().getMandatorId());

    if (mandator === 'peakevent') {

      const controller = new BbitController();

      // create temp controller
      let error = controller.setup({
        uniqueEntityName: dataSchema.getUniqueEntityName(),
        schemaCompilation: this._selectService.getSchemaService().getSchemaCompilation(this._selectService.getAuthService().getCurrentSession()),
        selectService: this._selectService,
        sessionRef: this._authService.getCurrentSession(),
        onExternalRowUpdate: 'merge',
        onExternalRowInsert: 'append',
        onExternalRowDelete: 'remove'
      });

      // create new document
      await controller.createNew(this.tab.params.entityId);

      const docId = controller.getDocumentId('[0]');
      const bucketName = 'bbit-' + mandator;
      const keyPrefix = dataSchema.getUniqueEntityName() + '/' + docId + '.file';

      const result: any = await this._fileService.addNewFilesByDialog(
        this._authService.getCurrentSession(),
        {
          bucketName: bucketName,
          bucketRegion: 'eu-central-1',
          keyPrefix: keyPrefix,
          allowDirectory: false,
          maxFiles: 1,
          // maxFileSize: this.maxFilesize,
          includeDirectory: false,
          allowMultiple: false,
          // replacesFile: this._currentFileState,
          allowMimeTypes: 'text/xml, application/xml'
        }
      );
      const fileState = result[0];


      const subscription = fileState.onStateChange.subscribe(async () => {
        if (fileState.state === 'online') {

          if (subscription && subscription.unsubscribe) {
            subscription.unsubscribe();
          }

          const reader = new FileReader();
          reader.onload = (e: any) => {
            const xml = e.target.result;
            Camt54Parser.parse(xml).then((parsed) => {

              // set _id property to prevent additional save
              _.map(parsed, (o: any) => {
                o._id = BbitString.ensureBeginningHashtag(BbitUtils.makeId());
                return o;
              });
              // add file and entries to document and save it
              controller.set(docId + '.state', '#open');
              controller.set(docId + '.entries', parsed);
              controller.set(docId + '.file', fileState.file);
              controller.save(docId).then(() => {
                this.openDetail(docId);
              });
            });
          };
          reader.readAsText(fileState.fileSource);

        }
      });

    }
  }

}