
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { IBbitDefaultDialogInterface } from './interfaces';

@Component({
  templateUrl: 'default-dialog.component.pug',
  styleUrls: ['default-dialog.component.scss']
})
export class BbitDefaultDialogComponent {

  _options: IBbitDefaultDialogInterface;

  constructor(private _dialogRef: MatDialogRef<BbitDefaultDialogComponent>) {

  }

  injectParams(params: IBbitDefaultDialogInterface) {
    this._options = params;
  }

  close(returnValue: any) {
    this._dialogRef.close(returnValue);
  }

}