import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitIcon } from './icon.component';
import { BbitIconService } from './icon.service';

@NgModule({
    imports: [
        BrowserModule,
        MatIconModule,
    ],
    exports: [
        BbitIcon
    ],
    providers: [BbitIconService],
    declarations: [
        BbitIcon
    ]
})
export class BbitIconModule {}
