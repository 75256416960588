import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { BbitAuthService, BbitSettingsService, CognitoAdminUtil, CognitoSession } from '@bbit/core';
import { BbitNotificationService } from '../notification/notification.service';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { BbitLog } from '@bbit/log';

@Component({
  templateUrl: 'user.component.pug',
  styleUrls: [
    'user.component.scss'
  ]
})
export class BbitUserComponent implements IBbitTabController {

  private _log = BbitLog.scope({
    package: 'BbitUserComponent'
  });

  tab: IBbitTabInterface = null; // warning: still externaly accessed, ToDo: refactor such shit
  isActive: boolean = false;
  toolbar: any;
  attributes: any = {};
  session: CognitoSession;
  phoneConfirmationCode: string;
  emailConfirmationCode: string;
  loaded: boolean = false;
  mfa: boolean = false;
  mfaChanged: boolean = false;
  adminEdit: boolean = false;
  username: string;
  isNew: boolean = false;
  validation: any = [];

  constructor(
    private _zone: NgZone,
    protected _tabService: BbitTabService,
    protected _settingsService: BbitSettingsService,
    protected _cdr: ChangeDetectorRef,
    protected _authService: BbitAuthService,
    protected _notify: BbitNotificationService) {
    this.session = <CognitoSession>this._authService.getCurrentSession();
  }


  private _loadAttributes(attributes) {
    for (let attribute of attributes) {
      const a: any = attribute;
      this.attributes[a.Name] = a.Value;
    }
  }

  private _loadMFAOptions(options) {
    this.mfa = false;
    if (options && options.length > 0) {
      for (let mfa of options) {
        if (mfa.AttributeName === 'phone_number') {
          this.mfa = true;
        }
      }
    }
  }

  refresh() {
    const self = this;
    self._zone.run(() => {
      if (self.adminEdit) {
        if (self.isNew) {
          self.loaded = true;
          return Promise.resolve();
        }
        return CognitoAdminUtil.adminGetUser(self.session.getUserPoolId(), self.username).then((user) => {
          self._loadAttributes(user.UserAttributes);
          self._loadMFAOptions(user.MFAOptions);
          self.loaded = true;
        });
      } else {
        self.username = self.session.getCognitoUser().getUsername();
        return self.session.getAttributes().then((attributeResult) => {
          self._loadAttributes(attributeResult);
          return self.session.getMFAOptions().then((mfaResult) => {
            self._loadMFAOptions(mfaResult);
            self.loaded = true;
          });
        });
      }
    }).then(() => {
      self._validate().catch((error) => { });
    });
  }

  private _prepareAttributes(): Array<any> {
    let newAttributes = [];
    for (let property in this.attributes) {
      if (this.attributes.hasOwnProperty(property)) {
        switch (property) {
          case 'sub':
          case 'email_verified':
          case 'phone_number_verified':
            if (this.adminEdit) {
              if (this.attributes.email) {
                newAttributes.push({
                  Name: 'email_verified',
                  Value: 'true'
                });
              }
              if (this.attributes.phone_number) {
                newAttributes.push({
                  Name: 'phone_number_verified',
                  Value: 'true'
                });
              }
            }
            // skip those attributes
            break;
          default:
            newAttributes.push({
              Name: property,
              Value: this.attributes[property]
            });
        }
      }
    }
    return newAttributes;
  }

  save(): Promise<any> {
    const self = this;
    return Promise.resolve().then(
      () => { return self._validate(); }).then(
        () => { return self._createUser(); }).then(
          () => { return self._updateAttributes(); }).then(
            () => { return self._updateMfa(); }).then(
              () => {
                self._notify.showNotification({
                  type: 'success',
                  text: 'Erfolgreich gespeichert'
                });
                self.refresh();
              }).catch((error) => {
                self._notify.showNotification({
                  type: 'error',
                  text: 'Fehler beim Speichern',
                  detail: error.message
                });
                this._log.error(error);
                self.refresh();
              });
  }

  private _validate(): Promise<any> {

    this.validation = [];

    if (!this.username) {
      this.validation.push({ field: 'username', key: 'field-required-message', n: 1 });
    }

    if (!this.attributes.email) {
      this.validation.push({ field: 'email', key: 'field-required-message', n: 1 });
    }

    if (!this.attributes.given_name) {
      this.validation.push({ field: 'given_name', key: 'field-required-message', n: 1 });
    }

    if (!this.attributes.family_name) {
      this.validation.push({ field: 'family_name', key: 'field-required-message', n: 1 });
    }

    if (this.validation.length === 0) {
      return Promise.resolve();
    }
    return Promise.reject({ message: 'Error while validating data!' });
  }


  getError(field: string): any {
    if (this.validation.length > 0) {
      for (let v of this.validation) {
        if (v.field === field) {
          return v;
        }
      }
    }
  }

  private _createUser(): Promise<any> {
    if (this.isNew) {
      return CognitoAdminUtil.adminCreateUser(
        this.session.getUserPoolId(),
        this.username,
        null,
        null,
        this.attributes.email,
        this.attributes.phone_number
      );
    }
    return Promise.resolve();
  }

  private _updateAttributes(): Promise<any> {
    const newAttributes = this._prepareAttributes();
    if (this.adminEdit) {
      return CognitoAdminUtil.adminUpdateUserAttributes(this.session.getUserPoolId(), this.username, newAttributes);
    } else {
      return this.session.updateAttributes(newAttributes);
    }
  }

  private _updateMfa(): Promise<any> {
    if (this.mfaChanged) {
      if (this.adminEdit) {
        if (this.mfa) {
          return CognitoAdminUtil.adminSetMFA(this.session.getUserPoolId(), this.username, 'SMS');
        } else {
          return CognitoAdminUtil.adminSetMFA(this.session.getUserPoolId(), this.username, 'NONE');
        }
      } else {
        if (this.mfa) {
          return this.session.enableMFA();
        } else {
          return this.session.disableMFA();
        }
      }
    } else {
      return Promise.resolve();
    }
  }

  getUsername() {
    return this.username;
  }

  setUsername(value: string) {
    this.username = value;
    this._validate().catch((error) => { });
  }

  getAttribute(attribute: string) {
    return this.attributes[attribute];
  }

  setAttribute(attribute: string, value: string) {

    if (attribute === 'phone_number' && !value) {
      this.mfaChanged = true;
      this.mfa = false;
    }

    this.attributes[attribute] = value;
    this._validate().catch((error) => { });
  }

  verifyPhoneNumber() {
    if (this.adminEdit) {
      this._notify.showNotification({
        type: 'error',
        text: 'Cannot be used as admin!'
      });
      return;
    }
    const self = this;
    self.session.verifyAttribute('phone_number', self.phoneConfirmationCode)
      .then(() => {
        self.refresh();
      })
      .catch((error) => {
        self._notify.showNotification({
          type: 'error',
          text: 'Fehler beim verifizieren',
          detail: error.message
        });
        this._log.error(error);
      });
  }

  resendPhoneConfirmationCode() {
    if (this.adminEdit) {
      this._notify.showNotification({
        type: 'error',
        text: 'TODO'
      });
      return;
    }
    const self = this;
    self.session.getAttributeVerificationCode('phone_number')
      .then(() => {
        self.refresh();
      })
      .catch((error) => {
        self._notify.showNotification({
          type: 'error',
          text: 'Fehler beim resend',
          detail: error.message
        });
        this._log.error(error);
      });
  }

  verifyEmail() {
    if (this.adminEdit) {
      this._notify.showNotification({
        type: 'error',
        text: 'Cannot be used as admin!'
      });
      return;
    }
    const self = this;
    self.session.verifyAttribute('email', self.emailConfirmationCode)
      .then(() => {
        self.refresh();
      })
      .catch((error) => {
        self._notify.showNotification({
          type: 'error',
          text: 'Fehler beim verifizieren',
          detail: error.message
        });
        this._log.error(error);
      });
  }

  resendEmailConfirmationCode() {
    if (this.adminEdit) {
      this._notify.showNotification({
        type: 'error',
        text: 'Cannot be used as admin!'
      });
      return;
    }
    const self = this;
    self.session.getAttributeVerificationCode('email')
      .then(() => {
        self.refresh();
      })
      .catch((error) => {
        self._notify.showNotification({
          type: 'error',
          text: 'Fehler beim resend',
          detail: error.message
        });
        this._log.error(error);
      });
  }

  onMfaChange(event) {
    this.mfaChanged = !this.mfaChanged;
    this.mfa = event;
  }

  delete(): Promise<any> {
    const self = this;
    return CognitoAdminUtil.adminDeleteUser(this.session.getUserPoolId(), this.username)
      .then(() => {
        self._notify.showNotification({
          type: 'success',
          text: 'User wurde erfolfreich gelöscht'
        });
        self._tabService.close(self._tabService.currentTab);
      })
      .catch((error) => {
        self._notify.showNotification({
          type: 'error',
          text: 'Fehler beim Löschen',
          detail: error.message
        });
      });
  }


  injectParams(tab) {

    let buttons: Array<any> = [];
    this.tab = tab;
    this.tab.text = { value: { key: 'user', n: 1 } };
    this.tab.icon = 'person';

    if (this.tab.params.username) {
      this.adminEdit = true;
      this.username = this.tab.params.username;
      if (this.username === 'new') {
        this.isNew = true;
        this.username = '';
      }
      if (this.username !== this.session.getCognitoUser().getUsername()) {
        buttons.push({
          icon: 'delete',
          action: 'DELETE'
        });
      } else {
        buttons.push({
          icon: 'mdi:logout',
          action: 'LOGOUT'
        });
      }
    } else {
      buttons.push({
        icon: 'mdi:logout',
        action: 'LOGOUT'
      });
    }

    buttons.push({
      icon: 'save',
      action: 'SAVE'
    });

    this.toolbar = {
      icon: 'person',
      title: this.tab.text,
      buttons: buttons
    };

    this.refresh();

  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(event) {
  //   if (_.isPlainObject(event.action)) {
  //     this._tabService.handleMessage(null, event.action);
  // }
  // else {
    switch (event.action) {
      case 'SAVE':
        this.save();
        break;
      case 'LOGOUT':
        this.session.logout();
        break;
      case 'DELETE':
        this.delete();
        break;
      default:
        this._log.error('unknown action ' + event.action);
        return;
    }
  }
// }


  updateTitle() {

  }
}