import { BbitNotificationService } from './notification.service';
import { Component } from '@angular/core';


@Component({
  selector: 'bbit-notification-area',
  templateUrl: 'notification-area.component.pug',
  styleUrls: ['notification-area.component.scss']
})
export class BbitNotificationAreaComponent {

  constructor(public _notify: BbitNotificationService) {

  }

}