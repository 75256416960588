import { BbitInputDeltaComponent } from './input-delta.component';
import { NgModule } from '@angular/core';


@NgModule({
  imports: [],
  exports: [BbitInputDeltaComponent],
  declarations: [BbitInputDeltaComponent]
})
export class BbitInputDeltaModule { }
