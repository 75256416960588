import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BbitController, BbitUiController } from '@bbit/core';

interface FlexUi {
  type?: 'flex' | 'card' | 'input' | 'list';
  jsonPath?: string;
  layout?: 'row' | 'column';
  size?: string;
  itemMenu?: any;
  toolbar: any;
  isVisible?: boolean;
}


@Component({
  selector: 'bbit-ui-flex',
  templateUrl: 'flex.component.pug',
  styleUrls: [
    'flex.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BbitUiFlexComponent {

  @Input() ui: FlexUi;
  @Input() controller: BbitController;
  @Input() uiController: BbitUiController;
  @Output() action: EventEmitter<any> = new EventEmitter();

  subscription = null;

  constructor(
    private _cdr: ChangeDetectorRef
  ) { }

  trackById(index, item) {
    return item.id;
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.uiController.getUiChangedObservable().subscribe(() => {
      this._cdr.markForCheck();
    });
  }

  private _onSizeChange(event: any) {
    // this.ui.size = event;
  }

  /* public getJsonPath() {
      if (this.parentJsonPath) {
          return this.parentJsonPath + "." + this.ui.jsonPath;
      }
      return this.currentDocId + "." + this.ui.jsonPath;
  } */

  handleAction(event) {
    this.action.emit(event);
    // TODO move to doc controller

  }

  // handleImmutableAction(event) {
  //   // let action = $event.toJS();
  //   if (event.params) {
  //     event.params.jsonPath = event.jsonPah;
  //   } else {
  //     event.params = {
  //       jsonPath: event.jsonPath
  //     };
  //   }
  //   this.handleAction(event);
  // }

}