import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BbitController, BbitSchemaUtil, BbitSelectService, IBbitComparisonFilter, IBbitRequestResult, IBbitSelect, IBbitSelectColumn, MergeStrategy, StringHelpers, Utils } from '@bbit/core';
import * as _ from 'lodash';
import { BbitTabService } from '../tabs/tab.service';
import { IBbitInputSuffix } from './../input-text/interfaces.d';
import { IBbitTranslationType, IBbitI18n, BbitI18n, TranslationType } from '@bbit/i18n';
import { BbitLog } from '@bbit/log';

@Component({
  selector: 'bbit-input-reference',
  templateUrl: 'input-reference.component.pug',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitInputReferenceComponent {
  @Input() language: IBbitTranslationType;
  @Input() selector: IBbitSelect;
  @Input() id: any;

  @Input() tooltip: string | IBbitI18n;
  @Input() label: string | IBbitI18n;
  @Input() error: string | IBbitI18n;
  @Input() warning: string | IBbitI18n;
  @Input() info: string | IBbitI18n;
  @Input() type: string | IBbitI18n;
  @Input() disabled: boolean;
  @Input() suffixes: IBbitInputSuffix[];

  @Output() displayValueChange: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();
  @Output() suffixAction: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() idChange: EventEmitter<string> = new EventEmitter();

  private _log = BbitLog.scope({
    package: 'BbitInputReferenceComponent'
  });

  _displayValue: string;
  _isLoading: boolean;
  _menu: any;
  _tempCreationController: BbitController;
  _tempCreationSubscribtion: any;
  _cacheController: BbitController;
  _cacheControllerSubscribtion: any;
  _suffixes: IBbitInputSuffix[];

  debouncedLoad: () => any;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _selectService: BbitSelectService,
    private _tabService: BbitTabService) {
    const self = this;
    self.debouncedLoad = _.debounce(() => self.loadDisplayValue(), 1000);
  }

  openDetail(rowId) {
    const self = this;
    let params: any = {};

    if (self.selector && self.selector.from) {
      if (rowId) {
        params.action = 'OPEN';
        params.entityId = rowId;
      } else {
        params.action = 'NEW';

        this._disposeTempCreation();

        self._tempCreationController = new BbitController();
        let newId = self._tempCreationController.generateNewId();

        self._tempCreationSubscribtion = self._tempCreationController.getObservableStream('dataChanged').subscribe(() => {

          // ToDo: debouncing to 1s
          if (!self._tempCreationController.hasDocumentId(newId)) {
            self._disposeTempCreation();
            self._onSelect({ _id: null });
            self.warning = null;
          }
          else {
            const getResult = self._tempCreationController.getDisplayValue(newId + '.' + self.selector.displayField, { format: 'string', language: self._getTranslationType() });
            self._displayValue = getResult ? getResult.data : newId;

            if (self._tempCreationController.isNew(newId)) {
              self.warning = 'Bitte neu erfassten Datensatz speichern';
            }
            else {
              self._disposeTempCreation();
              self._onSelect({ _id: newId });
              self.warning = null;
            }
          }
          self._cdr.markForCheck();
        });

        self._displayValue = BbitI18n.t({ key: 'new-record', n: 1 });

        params.entityId = newId;
      }

      return this._tabService.create({
        uniqueEntityName: self.selector.from,
        viewName: 'detail', // get default detail view of schema
        params: params
      }, self._tempCreationController);
    }
  }

  ngOnInit() {
    // this.loadDisplayValue(true);
  }

  ngOnDestroy(): void {
    this._disposeCacheController();
    this._disposeTempCreation();
  }

  private _disposeTempCreation(): void {
    if (this._tempCreationSubscribtion) this._tempCreationSubscribtion.unsubscribe();
    this._tempCreationController = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selector'] || changes['id']) {
      this.loadDisplayValue(true);
    }
  }

  _onBlur($event) {
    this.blur.emit($event);
  }

  _onFocus($event) {
    this.focus.emit($event);
  }

  _onSuffixAction($event) {

    if (!$event.handled) {
      this.suffixAction.emit($event);
    }
  }

  _onAction($event) {
    switch ($event.action) {
      case 'CREATE-NEW':
        $event.handled = true;
        this.openDetail(null);
        break;
    }

    if (!$event.handled) {
      this.action.emit($event);
    }
  }

  _onDisplayValueChange($event) {
    this.displayValueChange.emit($event);
  }

  _onSelect($event) {
    if ($event && $event._id !== undefined) { // temporary fix because of input-select
      this._displayValue = $event.displayValue;
      this.id = $event._id;
      this.idChange.emit($event._id);
    }
  }

  _getTranslationType(): IBbitTranslationType {
    if (this.language) {
      return this.language;
    }
    else {
      return {
        language: BbitI18n.getLanguage(),
        type: TranslationType.FULL,
        count: null
      };
    }
  }

  _disposeCacheController() {
    if (this._cacheControllerSubscribtion) this._cacheControllerSubscribtion.unsubscribe();
    this._cacheController = null;
  }

  loadDisplayValue(force: boolean = false, retryCount: number = 3): Promise<IBbitRequestResult> {
    const self = this;

    if (self.id && self.selector && self.selector.displayField && self.selector.from) {
      const fullpath = self.id + '.' + self.selector.displayField;

      if (self._isLoading) return Promise.resolve({ statusCode: 200, data: null });

      const cacheController = this._selectService.getCacheControllerWithLoadedId(this._selectService.getAuthService().getCurrentSession(), self.selector.from, self.id);

      if (Utils.isPromise(cacheController)) {
        if (!retryCount || retryCount <= 0) return Promise.resolve({ statusCode: 200, data: null }); // avoid infinite recursion

        self._isLoading = true;
        return cacheController.then((result) => {
          self._isLoading = false;
          // cache has loaded, retry loading
          return self.loadDisplayValue(force, retryCount - 1);
        }, (error) => {
          self._isLoading = false;
          self._displayValue = 'ERR: ' + (error && error.errorMessage ? error.errorMessage : error.toString());
          return Promise.resolve(error);
        });
      }
      else {
        if (!self._cacheController || self._cacheController !== cacheController) {
          self._disposeCacheController();
          self._cacheController = cacheController;
          self._cacheControllerSubscribtion = self._cacheController.getObservableStream('dataChanged').subscribe(() => {
            self.debouncedLoad();
          });
        }

        let displayResult: IBbitRequestResult = cacheController.getDisplayValue(fullpath, { format: 'string', language: self._getTranslationType() });

        if (!displayResult) {
          this._log.error('datatype getDisplayValue returned null instead of a IBbitRequestResult, please fix that');
          displayResult = { statusCode: 400, errorMessage: 'no result' };
        }

        if (displayResult.promises && displayResult.promises.length > 0) {
          if (!retryCount || retryCount <= 0) return Promise.resolve({ statusCode: 200, data: null }); // avoid infinite recursion

          self._isLoading = true;
          return Promise.all(displayResult.promises).then((result) => {
            self._isLoading = false;
            // cache has loaded, retry loading
            return self.loadDisplayValue(force, retryCount - 1);
          }, (error) => {
            self._isLoading = false;
            this._log.error('error loading data: ', JSON.stringify(error));
            self._displayValue = 'ERR: ' + (error && error.errorMessage ? error.errorMessage : error.toString());
            return Promise.resolve(error);
          });
        }
        else {

          // TODO replace with read permission
          self._suffixes = _.cloneDeep(self.suffixes) || [];

          if (cacheController.getSchema().hasReadPermission(cacheController.getSession()) && cacheController.getSchema().hasPreviewView('desktop')) {
            const openAction = {
              action: 'OPEN-PREVIEW',
              tooltip: { key: 'open-entry', n: 1 },
              icon: 'open_in_new',
              params: {
                uniqueEntityName: self.selector.from,
                id: self.id
              },
            };

            const actionIndex = _.findIndex(self._suffixes, { action: 'OPEN-PREVIEW' });
            if (actionIndex > 0) {
              self._suffixes[actionIndex] = openAction;
            }
            else {
              self._suffixes.push(openAction);
            }
          }

          self._displayValue = BbitI18n.t(displayResult.data);
          self._cdr.markForCheck();
        }
      }
    }

    return Promise.resolve({ statusCode: 200 });
  }

  getSelectValues(currentDisplayValue: string): Promise<any> {
    const self = this;

    this._log.log('getSelectValues', currentDisplayValue);
    const currentSchemaCompilation = this._selectService.getSchemaService().getSchemaCompilation(this._selectService.getAuthService().getCurrentSession());


    const selectController = new BbitController();
    selectController.setup({
      uniqueEntityName: this.selector.from,
      schemaCompilation: currentSchemaCompilation,
      selectService: this._selectService,
      sessionRef: this._selectService.getAuthService().getCurrentSession(),
      onExternalRowUpdate: 'merge',
      onExternalRowInsert: 'append',
      onExternalRowDelete: 'remove'
    });

    const referencedSchema = selectController.getSchema();
    if (!referencedSchema) {
      this._log.warning('unknown-reference-schema', 'unknown schema ' + this.selector.from);
      return Promise.reject(null);
    }
    else {
      if (referencedSchema.hasWritePermission(self._selectService.getAuthService().getCurrentSession())) {
        this._menu = [{
          action: 'CREATE-NEW',
          text: 'Neuer Eintrag',
          icon: 'add'
        }];
      }
      else {
        this._menu = [];
      }
    }

    if (!this.selector.limit) {
      this.selector.limit = 50;
    }

    const translationType = this._getTranslationType();
    const schemaService = this._selectService.getSchemaService();
    const filter: IBbitComparisonFilter = _.clone(this.selector.where || {});

    let columns: IBbitSelectColumn[];
    if (!self.selector.select || _.compact(self.selector.select).length === 0) {
      columns = [{ width: '100%', field: self.selector.displayField }];
    }
    else {
      columns = self.selector.select;
    }

    if (currentDisplayValue && currentDisplayValue.length > 0) {

      let terms = currentDisplayValue.split(' ');
      filter['$and'] = _.compact(_.map(terms, term => {
        if (term.trim().length === 0) return null;

        return {
          '$or': _.compact(_.flatMap(columns, (column) => {
            let res = {};

            const element = selectController.getSchemaElement('[].' + column.field, false);

            if (!element) {
              this._log.warning('field [' + column.field + '] referenced in select is unknown. Field is ignored');
              return null;
            }

            if (!BbitSchemaUtil.isDataField(element)) {
              this._log.warning('field ' + column.field + ' referenced in select is no datafield (' + element.type + ') Field is ignored');
              return null;
            }

            if (BbitSchemaUtil.isElementComputed(element)) {
              this._log.warning('field ' + column.field + ' referenced in select must not be a computed field. Field is ignored');
              return null;
            }

            const datatype = BbitSchemaUtil.getDataTypeOfElement(element, currentSchemaCompilation);
            if (datatype && datatype.getSearchQuery) {
              const query = datatype.getSearchQuery(term, column.field, element, selectController, { format: 'string', language: translationType });
              if (query.statusCode >= 300) {
                this._log.warning('for field ' + column.field + ' referenced in select the datatype has returned no search query. Field is ignored');
                return null;
              }
              else {
                _.merge(res, query.data);
              }
            }
            else {
              res[column.field] = {
                $regex: StringHelpers.getSearchTermRegex(term)
              };
              // };
            }

            return res;
          }))
        };

      })).concat(<any>filter['$and'] || []);
    }

    /*
    let orderBy = [];
    if (this.selector.orderBy) {
        orderBy = this.selector.orderBy;
    }
    else {
        orderBy = _.map(self.selector.select, (column) => {
            return column.field;
        });
    } */

    let where = selectController.evaluateObjectDeep(filter, { currentDisplayValue: currentDisplayValue }, null, true);

    // fix for "list all" with limit
    /* where["_id"] = {
        $exists: true
    }; */

    const select: IBbitSelect = {
      select: null,
      where: where,
      skip: this.selector.skip || 0,
      limit: this.selector.limit || 50,
      orderBy: null // orderBy
    };

    this._log.log('SELECTOR WHERE', select.where);

    let reloadPromise: Promise<IBbitRequestResult> = selectController.hasSelectService() ?
      selectController.retrieveList(select, MergeStrategy.OVERWRITE_ORIGINAL) :
      Promise.resolve<IBbitRequestResult>({ statusCode: 200 }); // { $and: [inputFilter, baseFilter ] }

    return reloadPromise.then((result): Promise<any> => {

      const rowIDs = selectController.listDocumentId('[all]');

      const selectMenuRows = _.map(rowIDs, (rowId) => {
        let columnsPaths = self.selector.select;

        const retrievedRow: any = {
          _id: rowId,
          displayValue: BbitI18n.t(selectController.getDisplayValue(rowId + '.' + self.selector.displayField, { format: 'string', language: translationType }).data),
          /* columns: _.map(columnsPaths, column => {
              return selectController.getDisplayValue(rowId + "." + column.field, translationType).data;
          }), */
        };

        if (selectController.getSchema().hasReadPermission(self._selectService.getAuthService().getCurrentSession()) && selectController.getSchema().hasDetailView('desktop')) {
          retrievedRow.menu = [{
            action: 'OPEN-PREVIEW',
            text: BbitI18n.t({ key: 'open', n: 1 }),
            icon: 'open_in_new',
            params: {
              uniqueEntityName: this.selector.from,
              id: rowId
            },
          }];
        }
        return retrievedRow;
      });

      return Promise.resolve(selectMenuRows);

    }).catch((error) => {
      this._log.error('error retrieving select records:', error);
      if (error && error.statusCode === 404) {
        // this.validityMessage = "no entry found";
      }
      else {
        // this.validityMessage = "error: " + (error.errorMessage || error);
      }
    });
  }
}