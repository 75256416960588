
import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { BbitAuthService, BbitSettingsService, CognitoAdminUtil, CognitoSession } from '@bbit/core';
import { GridOptions } from 'ag-grid-community';
import { BbitNotificationService } from '../notification/notification.service';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { BbitLog } from '@bbit/log';


@Component({
  templateUrl: 'user-list.component.pug'
})
export class BbitUserListComponent implements IBbitTabController {

  private _log = BbitLog.scope({
    package: 'BbitUserListComponent'
  });

  tab: IBbitTabInterface = null; // warning: still externaly accessed, ToDo: refactor such shit
  isActive: boolean = false;
  toolbar: any;

  session: CognitoSession;
  users: any = [];
  loaded: boolean = false;
  gridOptions: GridOptions;
  rowData: any[];
  columnDefs: any[];
  quickfilterValue: string = '';

  constructor(
    protected _tabService: BbitTabService,
    protected _settingsService: BbitSettingsService,
    protected _cdr: ChangeDetectorRef,
    protected _authService: BbitAuthService,
    protected _notify: BbitNotificationService) {
    this.session = <CognitoSession>this._authService.getCurrentSession();
    // this.refresh();

    const self = this;

    self.session = <CognitoSession>self._authService.getCurrentSession();
    CognitoAdminUtil.listUser(self.session.getUserPoolId()).then((result) => {
      for (let user of result.Users) {
        let attributes: any = {};

        attributes.username = user.Username;
        attributes.enabled = user.Enabled;
        attributes.createDate = user.UserCreateDate;
        attributes.lastModifiedDate = user.UserLastModifiedDate;
        attributes.status = user.UserStatus;

        for (let attribute of user.Attributes) {
          const a: any = attribute;
          attributes[a.Name] = a.Value;
        }

        self.users.push(attributes);
      }
      self.rowData = self.users;

    });

    this.gridOptions = <GridOptions>{
      onGridReady: () => {
        this.gridOptions.api.sizeColumnsToFit();
      },
      // rowHeight: 48,
      // headerHeight: 40,
      floatingFilter: true,
      // rowGroupPanelShow: "always",
      icons: {
        rowGroupPanel: '<i class=\'mdi mdi-vector-combine\'></i>'
      }
    };

    // this.gridOptions.defaultColDef = {
    //     headerComponentFramework : <{new(): BbitGridHeaderComponent}>BbitGridHeaderComponent,
    //     // headerComponentParams : {
    //     //     menuIcon: "fa-bars"
    //     // }
    // };

    this.columnDefs = [
      { headerName: 'Username', field: 'username', filter: 'agTextColumnFilter' },
      { headerName: 'E-Mail', field: 'email', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Name', field: 'family_name', filter: 'agTextColumnFilter' },
      { headerName: 'Firstname', field: 'given_name', filter: 'agTextColumnFilter' },
      { headerName: 'Phone', field: 'phone_number', filter: 'agTextColumnFilter' },
      { headerName: 'Locale', field: 'locale', filter: 'agSetColumnFilter' }
    ];

  }

  getAllTheFuckingFiltersDuuuude() {
    let filters = this.gridOptions.api.getFilterModel();

  }

  quickfilter(value: string) {
    this.gridOptions.api.setQuickFilter(value);
  }

  selectAllRows() {
    this.gridOptions.api.selectAll();
  }



  ngOnInit() {

  }


  injectParams(tab) {
    this.tab = tab;
    this.tab.text = { value: { key: 'user' } };
    this.tab.icon = 'people';
    this.toolbar = {
      icon: 'people',
      title: this.tab.text,
      buttons: [{
        icon: 'add',
        action: 'ADD'
      }]
    };

  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(event) {
  //   if (_.isPlainObject(event.action)) {
  //     this._tabService.handleMessage(null, event.action);
  // }
  // else {
    switch (event.action) {
      case 'ADD':
        this._tabService.create({
          uniqueEntityName: 'component',
          viewName: 'BbitUserComponent',
          params: {
            username: 'new'
          }
        });
        break;
      default:
        this._log.error('unknown action ' + event.action);
        return;
    }
  }
// }


  updateTitle() {

  }

  rowDoubleClicked(event) {
    this._tabService.create({
      uniqueEntityName: 'component',
      viewName: 'BbitUserComponent',
      params: {
        username: event.data.username
      }
    });
  }
}