import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { IBbitRequestResult } from '@bbit/core';
import * as _ from 'lodash';

export interface IBbitHotkeyElement {
  element: ElementRef;
  prefix: string;
  callback: any;
  topOffset: string;
  leftOffset: string;
  keyCallback: any;
}

@Injectable()
export class BbitHotkeyService {

  enabledEmitter: EventEmitter<boolean> = new EventEmitter();
  enabled: boolean = false;
  private _registeredElements: any = [];
  private _attachedElements: {} = {};
  private _adjacentHotkeys: {} = {};
  private _keys: {} = {};
  private _keyCount: {} = {};
  private _lastInput: string;
  private _inputResetTimeout: any;
  private _stayOn: boolean = false;

  constructor() {
    const self = this;
    $(document).keydown((e) => {
      if (e.keyCode === 27 && self.enabled) {
        self.disableHotkeys();
      }
      if (self.enabled) {
        if (e.metaKey) {
          self._lastInput = '';
        }
        else {
          self._lastInput += e.key.toUpperCase();
        }
        if (self._inputResetTimeout) {
          clearTimeout(self._inputResetTimeout);
        }
        self._inputResetTimeout = setTimeout(() => {
          self._lastInput = '';
        }, 1000);
        const link = self._attachedElements[self._lastInput];
        if (link) {
          if (link.callback) {
            setTimeout(() => {
              link.callback();
            }, 0);
          }
          else {
            setTimeout(() => {
              link.element.nativeElement.click();
            }, 0);
          }
          if (self._stayOn) {
            self._recalculateKeys();
          }
          else {
            self.disableHotkeys();
          }
          if (self._inputResetTimeout) {
            clearTimeout(self._inputResetTimeout);
          }
          self._lastInput = '';
          return;
        }
      }
    });
    $(document).click(() => {
      if (self.enabled && !self._stayOn) {
        self.disableHotkeys();
      }
    });
  }

  enableHotkeys(stayOn: boolean = false) {
    this._stayOn = stayOn;
    if (this.enabled && !this._stayOn) {
      this.disableHotkeys();
      return;
    }
    this._recalculateKeys();
  }

  registerAdjacentHotkey(key: string, callback: () => void): IBbitRequestResult {
    if (!key || key.length === 0) return { statusCode: 500, errorMessage: 'invalid parm key' };
    key = key.toUpperCase();

    if (!callback) {
      delete this._adjacentHotkeys[key];
    }
    else {
      this._adjacentHotkeys[key] = { callback: callback };
    }

    return { statusCode: 200 };
  }

  disableHotkeys() {
    this._attachedElements = {};
    this._setEnabled(false);
  }

  registerElement(element: IBbitHotkeyElement) {
    if (!element.prefix) {
      element.prefix = '-';
    }
    this._registeredElements.push(element);
    if (this.enabled) {
      this._recalculateKeys();
    }
  }

  _setEnabled(enabled: boolean) {
    this.enabled = enabled;
    this.enabledEmitter.emit(enabled);
  }

  _recalculateKeys() {
    this._attachedElements = _.clone(this._adjacentHotkeys || {});
    this._keys = {};
    this._lastInput = '';
    this._updateKeyCount();
    for (let e of this._registeredElements) {
      if (e.element.nativeElement.offsetParent === null) {
        // continue if element is not visible in DOM
        continue;
      }
      let key = this._getKey(e.prefix);
      e.keyCallback(key);
      this._attachedElements[key] = e;
    }
    this._setEnabled(true);
  }

  unregisterElement(element: ElementRef) {
    const index = this._registeredElements.indexOf(element);
    if (index !== -1) {
      this._registeredElements.splice(index, 1);
    }
    if (this.enabled) {
      this._recalculateKeys();
    }
  }

  private _getKey(prefix) {
    let index = 0;
    if (!prefix) {
      prefix = '-';
    }
    if (this._keys[prefix] >= 0) {
      index = this._keys[prefix] + 1;
    }
    this._keys[prefix] = index;
    let first = Math.floor(index / 26);
    let char = String.fromCharCode(65 + first);
    let second = index - (first * 26);
    char += String.fromCharCode(65 + second);
    if (prefix !== '-') {
      char = prefix + char;
    }
    return char;
  }

  private _updateKeyCount() {
    for (let element of this._registeredElements) {
      if (!this._keyCount[element.prefix]) {
        this._keyCount[element.prefix] = 0;
      }
      this._keyCount[element.prefix]++;
    }
  }


}