
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { BbitAuthService, BbitSchema, BbitSchemaService, StringHelpers } from '@bbit/core';
import * as _ from 'lodash';
import { BbitNotificationService } from '../notification/notification.service';
import { IBbitTabController } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { GridOptions } from 'ag-grid-community';
import { BbitExpression } from '@bbit/expression';
import { BbitI18n } from '@bbit/i18n';
import { BbitLog } from '@bbit/log';

@Component({
  templateUrl: 'settings.component.pug',
  styleUrls: ['settings.component.scss']
})
export class SettingsComponent implements IBbitTabController {

  private _log = BbitLog.scope({
    package: 'SettingsComponent'
  });

  @ViewChild('content') contentRef;
  static componentTitle: any = { key: 'settings' };
  static componentIcon: string = 'settings';

  tab: any = {};
  toolbar: any = {};
  params: any = {};
  isActive: boolean = false;
  gridOptions: GridOptions;
  columnDefs: any[];
  rowData: any[];

  settingsSchemas: BbitSchema[] = [];

  constructor(
    private _tabService: BbitTabService,
    private _schemaService: BbitSchemaService,
    private _authService: BbitAuthService) {

      this.gridOptions = <GridOptions>{
        onGridReady: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        floatingFilter: true,
        icons: {
          menu: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">more_vert</i>',
        filter: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">search</i>',
        }
      };

      this.columnDefs = [
        { headerName: BbitI18n.t({ key: 'designation', n: 1}), field: 'designation', filter: 'agTextColumnFilter' },
        // { headerName: 'E-Mail', field: 'email', filter: 'agTextColumnFilter', enableRowGroup: true },
        // { headerName: 'Name', field: 'family_name', filter: 'agTextColumnFilter' },
        // { headerName: 'Firstname', field: 'given_name', filter: 'agTextColumnFilter' },
        // { headerName: 'Phone', field: 'phone_number', filter: 'agTextColumnFilter' },
        // { headerName: 'Locale', field: 'locale', filter: 'agSetColumnFilter' }
      ];

  }

  isAdmin() {
    return this._authService.getCurrentSession().hasWritePermission('admin');
  }

  isSuperUser() {
    return this._authService.getCurrentSession().hasWritePermission('superuser');
  }

  injectParams(tab) {
    this.tab = tab;
    this.tab.text = SettingsComponent.componentTitle;
    this.tab.icon = SettingsComponent.componentIcon;

    this.toolbar = {
      title: BbitI18n.t({ key: 'settings' }) + (this.isSuperUser() ? ' - SuperUser Mode' : (this.isAdmin() ? ' - Admin' : '')),
      icon: 'settings'
    };
    // this._cdr.markForCheck();

    this.loadGrid();


  }

  loadGrid() {
    const expr = new BbitExpression();
    this.rowData = [];
    const schemas = _.filter(this._schemaService.listSchemas(this._authService.getCurrentSession()), schema => schema.hasSettingsPage('desktop'));
    for (let schema of schemas) {
      const hasWritePermission = schema.hasWritePermission(this._authService.getCurrentSession());

      if (hasWritePermission) {
        this.settingsSchemas.push(schema);
        if (!schema.getUniqueEntityName().startsWith('base-')) {
          this.rowData.push({
            designation: BbitI18n.t(expr.execute(<any>schema.getDisplayTitle('desktop'), {}).data),
            open: () => this.handleAction({ action: 'OPEN', uniqueEntityName: schema.getUniqueEntityName(), viewName: schema.getSettingsView('desktop') }),
          });
        }
      }
    }

    // add additional views
    this.rowData.push({
      designation: BbitI18n.t({ key: 'current-organization', n: 1}),
      open: () => this.openCurrentOrganization()
    });

    this.rowData.push({
      designation: BbitI18n.t({ key: 'current-user', n: 1}),
      open: () => this.openCurrentUser()
    });

    this.rowData.push({
      designation: BbitI18n.t({ key: 'current-session', n: 1}),
      open: () => this.openCurrentSession()
    });

    if (this.isAdmin()) {
      this.rowData.push({
        designation: BbitI18n.t({ key: 'permission'}),
        open: () => this.openPermissionGrid()
      });
      this.rowData.push({
        designation: BbitI18n.t({ key: 'user'}),
        open: () => this.openUserList()
      });
    }

    if (this.isSuperUser()) {
      this.rowData.push({
        designation: BbitI18n.t({ key: 'database'}),
        open: () => this.openDatabases(),
      });
    }
  }

  rowDoubleClicked(row) {
    row.node.data.open();
    // row.open();
  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.loadGrid();
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(event) {
    try {
      switch (event.action) {
        case 'OPEN':
          this.openComponent(event);
          break;
        case 'CLOSE-TAB':
          this.closeTab();
          break;
        default:
          this._log.error('unknown action ', event.action);
      }
    } catch (err) {
      this._log.error('ERROR on handleAction of Component', err);
    }
  }

  openComponent(message) {
    let error = this._tabService.create(message);
    if (error) this._log.error(error);
  }

  openUserList() {
    this._tabService.create({
      uniqueEntityName: 'component',
      viewName: 'BbitUserListComponent'
    });
  }

  openPermissionGrid() {
    this._tabService.create({
      uniqueEntityName: 'component',
      viewName: 'BbitPermissionGridComponent'
    });
  }

  openDatabases() {
    this._tabService.create({
      uniqueEntityName: 'component',
      viewName: 'DatabaseComponent'
    });
  }

  openCurrentUser() {
    let params: any = {
      action: 'OPEN',
      entityId: StringHelpers.ensureBeginningHashtag(this._authService.getCurrentSession().getUserId())
    };

    this._tabService.create({
      uniqueEntityName: 'user',
      viewName: 'detail',
      params: params
    });
  }

  openCurrentOrganization() {
    let params: any = {
      action: 'OPEN',
      entityId: '#org'
    };

    this._tabService.create({
      uniqueEntityName: 'branch',
      viewName: 'detail',
      params: params
    });
  }

  openCurrentBranch() {
    let params: any = {
      action: 'OPEN',
      entityId: StringHelpers.ensureBeginningHashtag(this._authService.getCurrentSession().getBranchId())
    };

    this._tabService.create({
      uniqueEntityName: 'branch',
      viewName: 'detail',
      params: params
    });
  }

  openCurrentDevice() {
    let params: any = {
      action: 'OPEN',
      entityId: StringHelpers.ensureBeginningHashtag(this._authService.getCurrentSession().getDeviceId())
    };

    this._tabService.create({
      uniqueEntityName: 'device',
      viewName: 'detail',
      params: params
    });
  }

  openCurrentSession() {
    this._tabService.create({
      uniqueEntityName: 'component',
      viewName: 'BbitSessionComponent'
    });
  }
}
