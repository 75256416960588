import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitAuthModule } from '@bbit/core';
import { BbitButtonModule } from './../button/button.module';
import { BbitDownloadModule } from './../download/download.module';
import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { BbitIconModule } from './../icon/icon.module';
import { BbitBitratePipe } from './bitrate.pipe';
import { BbitInputFileComponent } from './input-file.component';
import { RelativeTimePipe } from './relativeTime.pipe';
import { BbitI18nModule } from '@bbit/i18n';


@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    BbitI18nModule,
    BbitIconModule,
    BbitButtonModule,
    BbitAuthModule,
    BbitDownloadModule,
    FlexLayoutModule,
    BbitHotkeyModule,
    MatProgressBarModule
  ],
  exports: [
    BbitBitratePipe,
    RelativeTimePipe,
    BbitInputFileComponent,
  ],
  declarations: [
    BbitBitratePipe,
    RelativeTimePipe,
    BbitInputFileComponent,
  ]
})
export class BbitInputFileModule { }
