
import { ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { BbitAuthService, BbitController, BbitSelectService, BbitSettingsService } from '@bbit/core';
import * as _ from 'lodash';
import { BbitNotificationService } from '../notification/notification.service';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { BbitLog } from '@bbit/log';
import { BbitI18n } from '@bbit/i18n';



@Component({
  templateUrl: 'permission-grid.component.pug',
  styleUrls: [
    './permission-grid.component.scss'
  ]
})
export class BbitPermissionGridComponent implements IBbitTabController {

  i18n = BbitI18n;

  private _log = BbitLog.scope({
    package: 'BbitPermissionGridComponent'
  });

  tab: IBbitTabInterface = null; // warning: still externaly accessed, ToDo: refactor such shit
  isActive: boolean = false;
  toolbar: any;

  @ViewChild('permissionUser') permissionUser: ElementRef;
  @ViewChild('permissionContent') permissionContent: ElementRef;
  @ViewChild('permissionHeader') permissionHeader: ElementRef;
  @ViewChild('permissionSubheader') permissionSubheader: ElementRef;


  userController: BbitController;
  users: any;

  securityObjectController: BbitController;
  securityObjects: any;

  original: any;

  userPermissions: any = {
    '#eu-central-1:01d80e3f-d207-48ff-90cf-42eecfe35f82': {
      '#person': {
        read: true,
        write: false
      }
    }
  };

  constructor(
    protected _tabService: BbitTabService,
    protected _selectService: BbitSelectService,
    protected _settingsService: BbitSettingsService,
    protected _cdr: ChangeDetectorRef,
    protected _authService: BbitAuthService,
    protected _notify: BbitNotificationService) {

    const self = this;


    this.reload();
  }

  onContentScroll(event) {
    this.permissionUser.nativeElement.scrollTop = event.srcElement.scrollTop;
    this.permissionSubheader.nativeElement.scrollLeft = event.srcElement.scrollLeft;
    this.permissionHeader.nativeElement.scrollLeft = event.srcElement.scrollLeft;

  }


  reload() {
    const self = this;
    const test = this._settingsService.generatePermissionGrid(this._authService.getCurrentSession()).then(res => {
      self.users = _.orderBy(res.users, (user) => user.firstname ? user.firstname.toLowerCase() : '', ['asc']);
      self.securityObjects = res.securityObjects;
      self.userPermissions = res.userPermissions;

      self.original = _.cloneDeep(res);
    });
  }

  hasReadPermission(userId: string, key: string): boolean {
    return this.hasPermission(userId, key, 'read');
  }

  hasWritePermission(userId: string, key: string): boolean {
    return this.hasPermission(userId, key, 'write');
  }

  hasPermission(userId: string, key: string, permission: string): boolean {
    let user = this.userPermissions[userId];
    if (user) {
      let obj = user[key];
      if (obj) {
        return obj[permission];
      }
    }
    return false;
  }

  setReadPermission(userId: string, key: string, value: boolean) {
    this.setPermission(userId, key, 'read', value);
  }

  setWritePermission(userId: string, key: string, value: boolean) {
    this.setPermission(userId, key, 'write', value);
  }

  setPermission(userId: string, key: string, permission: string, value: boolean) {
    let user = this.userPermissions[userId];

    if (!user) {
      this.userPermissions[userId] = {};
      user = this.userPermissions[userId];
    }

    let obj = user[key];
    if (!obj) {
      user[key] = {};
      obj = user[key];
    }
    obj[permission] = value;
    switch (permission) {
      case 'read':
        if (!value) {
          obj['write'] = false;
        }
        break;
      case 'write':
        if (value) {
          obj['read'] = true;
        }
        break;
    }
  }

  logit(event) {
    this._log.log(event);
  }


  injectParams(tab) {
    this.tab = tab;
    this.tab.text = { value: { key: 'permission' } };
    this.tab.icon = 'people';
    this.toolbar = {
      icon: 'people',
      title: this.tab.text,
      buttons: [{
        icon: 'save',
        action: 'SAVE'
      }]
    };

  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(event) {
    const self = this;

    // if (_.isPlainObject(event.action)) {
    //   this._tabService.handleMessage(null, event.action);
    // }
    // else {
    switch (event.action) {
      case 'ADD':
        this._tabService.create({
          uniqueEntityName: 'component',
          viewName: 'BbitUserComponent',
          params: {
            username: 'new'
          }
        });
        break;
      case 'SAVE':
        this._settingsService.updatePermissions(this._authService.getCurrentSession(), this.original, {
          users: this.users,
          securityObjects: this.securityObjects,
          userPermissions: this.userPermissions
        }).then(() => {
          this._notify.showNotification({
            type: 'success',
            text: 'Speichern erfolgreich'
          });
          self.reload();
        });

        break;
      default:
        this._log.error('unknown action ' + event.action);
        return;
    }
  }
  // }

  updateTitle() {

  }

}
