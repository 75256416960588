import { Component, ViewContainerRef } from '@angular/core';
import { BbitTabService } from './tab.service';

/*
 *   Anchor dummy element for dynamic component loading
 *
 */
@Component({
  selector: 'bbit-tab-content-anchor',
  template: '<div></div>'
})
export class BbitTabContentAnchor {
  constructor(private elementRef: ViewContainerRef, private tabService: BbitTabService) {
    this.tabService.tabContentElementRef = this.elementRef;
  }
}