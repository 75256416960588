import { HttpClient } from '@angular/common/http';
import { Component, NgZone } from '@angular/core';
import { BbitAuthService, BbitMgmtApiService, BbitTokenType, IBbitRequestResult } from '@bbit/core';
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import PouchDB from 'pouchdb-browser';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { Database, DatabaseDocument } from './interfaces';
import { BbitLog } from '@bbit/log';

@Component({
  templateUrl: 'document-list.component.pug'
})
export class DocumentListComponent implements IBbitTabController {

  tab: IBbitTabInterface = null; // warning: still externaly accessed, ToDo: refactor such shit
  isActive: boolean = false;
  toolbar: any;

  database: Database;
  documents: DatabaseDocument[];
  gridOptions: GridOptions;
  columnDefs: any[];

  private _log = BbitLog.scope({
    package: 'DocumentListComponent'
  });

  constructor(
    protected _tabService: BbitTabService,
    protected _http: HttpClient,
    protected _auth: BbitAuthService,
    protected _mgmtApi: BbitMgmtApiService
  ) {

    const self = this;

    self.gridOptions = <GridOptions>{
      onGridReady: () => {
        self.gridOptions.api.sizeColumnsToFit();
      },
      floatingFilter: true,
      icons: {
        menu: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">more_vert</i>',
        filter: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">search</i>',
      },
    };


    self.columnDefs = [
      { headerName: 'ID', field: 'id', filter: 'agTextColumnFilter' },
      { headerName: 'Revision', field: 'rev', filter: 'agTextColumnFilter' }
    ];

  }

  /**
   * Loads all databases from all endpoints
   */
  public loadDocuments(database: Database): Promise<DatabaseDocument[]> {
    const self = this;
    return self._auth.getCurrentSession().retrieveToken('databases', BbitTokenType.ACCESS).then((result: IBbitRequestResult) => {
      if (result.statusCode !== 200) {
        return Promise.reject('Error while retrieving token');
      }
      const _pouchDB = new PouchDB(`${database.endpointUrl}/${database.database}`, {
        fetch: function (url, opts) {
          if (result.data) {
            opts.headers.set('Authorization', 'Bearer ' + result.data);
          }
          return PouchDB.fetch(url, opts);
        }
      });
      return _pouchDB.allDocs().then(result => {
        const rows = _.map(result.rows, (row) => {
          return {
            id: row.id,
            rev: row.value.rev
          };
        });
        return Promise.resolve(rows);
      });
    });

  }

  updateTitle() {
    if (this.database) {
      this.tab.text = { value: this.database };
    }
    else {
      this.tab.text = { value: { key: 'document' } };
    }
  }

  rowDoubleClicked(row: any) {
    if (row && row.data) {
      this._tabService.create({
        uniqueEntityName: 'component',
        viewName: 'DocumentDetailComponent',
        params: {
          database: this.database,
          document: row.data
        }
      });
    }
  }


  injectParams(tab) {
    this.tab = tab;
    this.updateTitle();
    this.tab.icon = 'mdi:file-multiple';
    this.toolbar = {
      icon: 'mdi:file-multiple',
      title: this.tab.text,
      buttons: [{
        icon: 'loop',
        action: 'RELOAD'
      }, {
        icon: 'add',
        action: 'ADD'
      }]
    };
    this.database = this.tab.params.database;
    this.reload();
  }

  reload() {
    return this.loadDocuments(this.database).then(documents => {
      this._log.info(`Retrieved documents`, documents);
      this.documents = documents;
    });
  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
    // this.reload(); <-- debounce this or use mqtt as notifier of changes
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(event: any) {
    switch (event.action) {
      case 'ADD':
        this._tabService.create({
          uniqueEntityName: 'component',
          viewName: 'DocumentDetailComponent',
          params: {
            database: this.database,
            document: null
          }
        });
        break;
      case 'RELOAD':
        this.reload();
    }
  }


}