import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'bbit-button',
  templateUrl: 'button.component.pug',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitButtonComponent {

  @Input() color;

  constructor() {
  }
}