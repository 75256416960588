import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'relativetime'
})

export class RelativeTimePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (Math.abs(value) < 0.1) {
      return '';
    }
    let time = moment.duration(value, 'seconds');
    return time.humanize(true);
  }
}