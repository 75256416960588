import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BbitController, BbitUiController } from '@bbit/core';
import * as _ from 'lodash';
import { IBbitTranslationType } from '@bbit/i18n';
import { BbitLog } from '@bbit/log';

@Component({
  selector: 'bbit-ui-doc-view',
  templateUrl: 'doc-view.component.pug',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitUiDocViewComponent implements OnChanges, OnDestroy {

  private _log = BbitLog.scope({
    package: 'BbitUiDocViewComponent'
  });

  @Input() uniqueUiName: string;
  @Input() controller: BbitController;
  @Input() translationType: IBbitTranslationType;
  @Input() docId: string;
  // @Input() view: IBbitUiView = null;
  @Output() action: EventEmitter<any> = new EventEmitter();

  @ViewChild('sectionWrapper') sectionWrapper;

  renderedUi: any = {};
  uiController: BbitUiController;
  // validationState: any;

  // debouncedRender;
  subscription = null;

  constructor(private _cdr: ChangeDetectorRef) {
    // this.debouncedRender = _.debounce(this.renderUi.bind(this), 100);
  }

  ngOnInit() {
    if (this.controller && this.docId) {
      const schema = this.controller.getSchema();

      let view = schema.getUiSchema('desktop', this.uniqueUiName);
      if (!view) {
        this._log.error('unknown view uniqueUiName ', this.uniqueUiName);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  renderUi() {
    if (!this.uiController) {
      this.uiController = new BbitUiController(this.controller);
    }

    try {
      this.uiController.renderFlexUi('desktop', this.uniqueUiName, this.docId, this.renderedUi);
    } catch (err) {
      this.renderedUi = null;
      this._log.error('renderUI', err);
    }
    this._cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const self = this;
    if (changes['controller']) {
      if (this.subscription) this.subscription.unsubscribe();

      if (changes['controller'].currentValue) {


        const cacheInit = _.reduce(
          _.uniq(
            _.compact(
              _.map(this.controller.getSchema().getRealFieldList(), field => field.element.referencesDocumentSchema)
            )
          ),
          (res, schemaName) => {
            res[schemaName] = ['ALL'];
            return res;
          }, {});

        // temporary fix for preview: load all references schemas into cache so that expressions already return valid values
        this.controller.getSelectService().ensureCache(this.controller.getSelectService().getAuthService().getCurrentSession(), cacheInit).then(
          () => {
            this._log.info('cache loaded', cacheInit);
            self.renderUi();
          },
          (err) => this._log.error(err)
        );

        this.subscription = this.controller.getObservableStream('dataChanged').subscribe((result) => {
          // ToDo: throttling?
          // this.debouncedRender();
          self.renderUi();
        });
      }
    }

    if (changes['docId'].currentValue) {

      this.renderUi();
    }
  }

  handleAction(event: any) {
    this.action.emit(event);
  }
}