import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'bbit-icon-button',
  templateUrl: 'icon-button.component.pug',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitIconButtonComponent {

  @Input() icon: string;
  @Input() tooltip: string;

  constructor() {
  }
}