
import { BbitAfterIfDirective } from './after-if.directive';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitMiddleclickDirective } from './middleclick.directive';

@NgModule({
  imports: [
    BrowserModule
  ],
  exports: [
    BbitAfterIfDirective,
    BbitMiddleclickDirective
  ],
  declarations: [
    BbitAfterIfDirective,
    BbitMiddleclickDirective
  ],
  providers: [
  ]
})
export class BbitHelperModule { }
