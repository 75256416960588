import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BbitAuthService, BbitSelectService } from '@bbit/core';
import * as _ from 'lodash';


@Component({
  selector: 'temp-westwind-person-living-type',
  templateUrl: 'temp-westwind-person-living-type.component.pug',
  styleUrls: [
    'temp-westwind-person-living-type.component.scss'
  ]
})
export class TempWestwindPersonLivingType implements OnInit {

  @Input('ui') ui;

  data: any;

  constructor(
    private _selectService: BbitSelectService,
    private _authService: BbitAuthService,
    private _cdr: ChangeDetectorRef
  ) {

  }


  ngOnInit() {
    const self = this;
    self._selectService.ensureCache(self._authService.getCurrentSession(), { 'person': ['ALL'], 'apartment': ['ALL'], 'apartment-type': ['ALL'] }).then(() => {
      let personController = self._selectService.getCacheController(self._authService.getCurrentSession(), 'person');
      const person = personController.getData(self.ui.jsonPath);

      if (person.offers && person.offers.living && person.offers.living.used) {
        const contracts = _.orderBy(person.living, c => new Date(c.validFrom), ['desc']);
        if (contracts.length > 0) {
          const apartment = contracts[0].apartment;
          if (apartment) {
            let apartmentController = self._selectService.getCacheController(self._authService.getCurrentSession(), 'apartment');
            self.data = apartmentController.getDisplayValue(apartment + '.type', {}).data;
          }
        }
      }
      self._cdr.markForCheck();
    });
  }


}
