import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule, MatInputModule, MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitI18nModule } from '@bbit/i18n';
import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { BbitInputTextComponent } from './input-text.component';


@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    BbitI18nModule,
    BbitHotkeyModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule
  ],
  exports: [
    BbitInputTextComponent
  ],
  declarations: [
    BbitInputTextComponent
  ]
})
export class BbitInputTextModule { }
