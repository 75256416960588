import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitIconModule } from '../icon/icon.module';
import { BbitTabsModule } from '../tabs/tab.module';
import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { BbitNotificationModule } from './../notification/notification.module';
import { BbitSideNavigation } from './side-navigation.component';

@NgModule({
  imports: [
    BbitCoreModule,
    BbitIconModule,
    BrowserModule,
    FlexLayoutModule,
    BbitHotkeyModule,
    BbitIconModule,
    BbitNotificationModule,
    BbitTabsModule,
    MatTooltipModule
  ],
  exports: [
    BbitSideNavigation,
  ],
  declarations: [
    BbitSideNavigation
  ]
})
export class BbitSideNavigationModule { }
