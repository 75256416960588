import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bitrate'
})
export class BbitBitratePipe implements PipeTransform {
  transform(bits: any, ...args: any[]): any {
    if (typeof bits !== 'number') {
      return '';
    }
    if (bits >= 1000000000) {
      return (bits / 1000000000).toFixed(2) + ' Gbit/s';
    }
    if (bits >= 1000000) {
      return (bits / 1000000).toFixed(2) + ' Mbit/s';
    }
    if (bits >= 1000) {
      return (bits / 1000).toFixed(2) + ' kbit/s';
    }
    return bits.toFixed(2) + ' bit/s';
  }
}