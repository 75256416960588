import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as Quill from 'quill';

@Component({
  selector: 'bbit-input-delta',
  templateUrl: 'input-delta.component.pug',
  styleUrls: ['input-delta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitInputDeltaComponent implements AfterViewInit, OnChanges {

  private _quill: any = Quill;

  _isValueSettingFromAngular: boolean = false;

  @Input() value: any;
  @Input() disabled: boolean = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('editor') editorAnchor: any;

  editor: any;

  constructor() {
  }

  onValueChange(event: any) {
    this.valueChange.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['value']) {
      if (this.editor && !this.editor.hasFocus()) {
        this._isValueSettingFromAngular = true;
        this.editor.setContents(changes['value'].currentValue);
        this._isValueSettingFromAngular = false;
      }
    }
  }

  ngAfterViewInit() {

    const self = this;

    self._registerCustomBlots();

    self._setIcons();

    self.editor = new this._quill(self.editorAnchor.nativeElement, {
      modules: {
        toolbar: this.disabled ? false : [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'color': [] }, { 'background': [] }],
          ['blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['link'],
        ]
      },
      theme: 'snow',
      readOnly: this.disabled
    });

    self.editor.setContents(self.value, 'api');

    self.editor.on('text-change', (delta, oldDelta, source) => {
      if (!self._isValueSettingFromAngular) { // do not emit deltas set from extern
        self.valueChange.emit(self.editor.getContents());
      }
    });
  }

  private _setIcons() {
    let icons = this._quill.import('ui/icons');
    icons['bold'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>format_bold</i>';
    icons['italic'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>format_italic</i>';
    icons['underline'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>format_underlined</i>';
    icons['strike'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>format_strikethrough</i>';
    icons['color'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>format_color_text</i>';
    icons['background'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>format_color_fill</i>';
    icons['blockquote'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>format_quote</i>';
    icons['list']['bullet'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>format_list_bulleted</i>';
    icons['list']['ordered'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>format_list_numbered</i>';
    icons['link'] = '<i class=\'material-icons bbit-quill-icon\' aria-hidden=\'true\'>link</i>';
  }

  private _registerCustomBlots() {

    const Embed = this._quill.import('blots/embed');
    class ProtectedH3Blot extends Embed {

      static create(value) {
        let node = super.create();
        node.setAttribute('spellcheck', false);
        node.setAttribute('contenteditable', false);
        node.setAttribute('data-id', value.id);
        node.setAttribute('data-value', value.displayValue);
        node.setAttribute('style', 'font-weight: 700;');
        node.innerHTML = value.displayValue;
        return node;
      }

      static value(node) {
        return { displayValue: node.getAttribute('data-value'), id: node.getAttribute('data-id') };
      }

      deleteAt() {
        return false;
      }
    }

    ProtectedH3Blot.blotName = 'protectedH3';
    ProtectedH3Blot.tagName = 'h3';

    this._quill.register(ProtectedH3Blot);

    class ProtectedH2Blot extends Embed {

      static create(value) {
        let node = super.create();
        node.setAttribute('spellcheck', false);
        node.setAttribute('contenteditable', false);
        node.setAttribute('data-id', value.id);
        node.setAttribute('data-value', value.displayValue);
        node.setAttribute('style', 'font-weight: 700;');
        node.innerHTML = value.displayValue;
        return node;
      }

      static value(node) {
        return { displayValue: node.getAttribute('data-value'), id: node.getAttribute('data-id') };
      }

      deleteAt() {
        return false;
      }
    }

    ProtectedH2Blot.blotName = 'protectedH2';
    ProtectedH2Blot.tagName = 'h2';

    this._quill.register(ProtectedH2Blot);
  }

}