import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BbitIconButtonComponent } from './icon-button.component';
import { BbitMenuButtonComponent } from './menu-button.component';
import { BbitMiniFabComponent } from './mini-fab.component';
import { BbitFabComponent } from './fab.component';
import { BbitRaisedButtonComponent } from './raised-button.component';
import { BbitButtonComponent } from './button.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitIconModule } from '../icon/icon.module';
import { BbitCoreModule } from '@bbit/core';
import { MatButtonModule, MatMenuModule, MatTooltipModule } from '@angular/material';

@NgModule({
  imports: [
    BbitCoreModule,
    MatButtonModule,
    BbitIconModule,
    BrowserModule,
    FlexLayoutModule,
    BbitHotkeyModule,
    MatMenuModule,
    MatTooltipModule
  ],
  exports: [
    BbitButtonComponent,
    BbitRaisedButtonComponent,
    BbitFabComponent,
    BbitMiniFabComponent,
    BbitMenuButtonComponent,
    BbitIconButtonComponent
  ],
  declarations: [
    BbitButtonComponent,
    BbitRaisedButtonComponent,
    BbitFabComponent,
    BbitMiniFabComponent,
    BbitMenuButtonComponent,
    BbitIconButtonComponent
  ]
})
export class BbitButtonModule { }
