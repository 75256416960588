import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BbitAuthService, BbitController, BbitSelectService, Utils } from '@bbit/core';


@Component({
  selector: 'bbit-display-text',
  styleUrls: ['display-text.component.scss'],
  templateUrl: 'display-text.component.pug',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitDisplayTextComponent implements OnChanges {

  @Input('ui') ui;
  @Input('displayValue') displayValue;
  @Input('label') label;
  @Output() action: EventEmitter<any> = new EventEmitter();

  referenceDisplayValue: string;
  multiReferenceValues: {
    id: string;
    displayValue: string;
  }[] = [];
  hasPreview: boolean = false;

  constructor(
    private _selectService: BbitSelectService,
    private _authService: BbitAuthService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ui']) {
      this.load();
    }
  }

  load() {
    if (!this.ui.selector) {
      return;
    }
    const self = this;
    self.hasPreview = self._selectService.getSchemaService().getSchema(self._authService.getCurrentSession(), self.ui.selector.from).hasPreviewView('desktop');
    switch (this.ui.datatype) {
      case 'reference':
        if (self.ui.jsonValue) {
          let controller = self._selectService.getCacheControllerWithLoadedId(self._authService.getCurrentSession(), self.ui.selector.from, self.ui.jsonValue);
          if (Utils.isPromise(controller)) {
            controller.then(() => {
              controller = self._selectService.getCacheControllerWithLoadedId(self._authService.getCurrentSession(), self.ui.selector.from, self.ui.jsonValue);
              self.referenceDisplayValue = (<BbitController>controller).getDisplayValue(`${self.ui.jsonValue}.${self.ui.selector.displayField}`, {}).data;
            });
          } else {
            self.referenceDisplayValue = controller.getDisplayValue(`${self.ui.jsonValue}.${self.ui.selector.displayField}`, {}).data;
          }
        }
        break;
      case 'multiReference':
        if (self.ui.jsonValue) {
          let controller = self._selectService.getCacheController(self._authService.getCurrentSession(), self.ui.selector.from);
          if (Utils.isPromise(controller)) {
            controller.then(() => {
              controller = self._selectService.getCacheController(self._authService.getCurrentSession(), self.ui.selector.from);
              for (let field of self.ui.jsonValue) {
                self.multiReferenceValues.push({
                  id: field,
                  displayValue: controller.getDisplayValue(`${field}.${self.ui.selector.displayField}`, {}).data
                });
              }
            });
          } else {
            for (let field of self.ui.jsonValue) {
              self.multiReferenceValues.push({
                id: field,
                displayValue: controller.getDisplayValue(`${field}.${self.ui.selector.displayField}`, {}).data
              });
            }
          }
        }
        break;
    }
  }


  openReferencePreview() {
    this.action.emit({ action: 'OPEN-PREVIEW', params: { uniqueEntityName: this.ui.selector.from, id: this.ui.jsonValue } });
  }

  openMultiReferencePreview(id: string) {
    this.action.emit({ action: 'OPEN-PREVIEW', params: { uniqueEntityName: this.ui.selector.from, id: id } });
  }

}