
import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { IBbitNotificationInterface, IBbitDefaultDialogInterface } from './interfaces';
import { IBbitRequestResult } from '@bbit/core';
import { NotificationTimer } from './timer';
import { BbitDefaultDialogComponent } from './default-dialog.component';
import * as Sentry from '@sentry/browser';

@Injectable()
export class BbitNotificationService {

  private _timeout: number = 3000;
  private _defaultDialogRef: MatDialogRef<BbitDefaultDialogComponent>;
  public notifications: Array<IBbitNotificationInterface> = [];

  private _appViewContainterRef: ViewContainerRef;

  constructor(private _dialog: MatDialog) {

  }


  setAppViewContainerRef(ref: ViewContainerRef) {
    this._appViewContainterRef = ref;
  }

  showDefaultDialog(options: IBbitDefaultDialogInterface): Promise<IBbitRequestResult> {
    return new Promise<IBbitRequestResult>((resolve, reject) => {
      // let config = new MdDialogConfig();
      // config.viewContainerRef = this._appViewContainterRef;

      this._defaultDialogRef = this._dialog.open(BbitDefaultDialogComponent);
      this._defaultDialogRef.componentInstance.injectParams(options);

      this._defaultDialogRef.afterClosed().subscribe(result => {
        resolve({ statusCode: 200, data: result });
        this._defaultDialogRef = null;
      });
    });
  }

  showNotification(notification: IBbitNotificationInterface) {

    if (!notification.icon) {
      switch (notification.type) {
        case 'info':
          notification.icon = 'info';
          break;
        case 'success':
          notification.icon = 'done';
          break;
        case 'warning':
          notification.icon = 'warning';
          break;
        case 'error':
          notification.icon = 'error';
          break;
      }
    }

    const self = this;
    notification.timer = new NotificationTimer();
    notification.timer.setDelay(this._timeout);
    notification.timer.setCallback(() => {
      self.removeNotification(notification);
    });
    notification.timer.resume();

    this.notifications.push(notification);

    // show additional dialog on error
    if (notification.type === 'error') {
      Sentry.captureException(notification);
      this.showDefaultDialog({
        title: 'Fehler: ' + notification.text,
        message: notification.detail,
        buttons: [{
          color: 'warn',
          text: 'OK',
          returnValue: true
        }]
      });
    }

  }

  removeNotification(notification: IBbitNotificationInterface) {
    const index = this.notifications.indexOf(notification);
    if (index > -1) this.notifications.splice(index, 1);
  }

  setNotificationTimeout(timeout: number) {
    this._timeout = timeout;
  }

  pauseNotificationTimer() {
    for (let i = 0; i < this.notifications.length; i++) {
      this.notifications[i].timer.pause();
    }
  }

  resumeNotificationTimer() {
    for (let i = 0; i < this.notifications.length; i++) {
      this.notifications[i].timer.resume();
    }
  }

}