import { BbitUtils } from '@bbit/utils';
import { BbitInputCheckboxComponent } from './../input-checkbox/input-checkbox.component';
import { BbitColorOptions, BbitStyleObject, BbitStyle } from './interfaces';


import { Injectable } from '@angular/core';
import * as _ from 'lodash';

export class BbitColor extends BbitStyle<BbitColorOptions> {

  constructor(options: BbitColorOptions) {
    super(options);
  }

  toString() {
    if (this._options.rgb) {
      if (this._options.alpha) {
        return `rgba(${this._options.rgb.r}, ${this._options.rgb.g}, ${this._options.rgb.b}, ${this._options.alpha})`;
      }
      return `rgb(${this._options.rgb.r}, ${this._options.rgb.g}, ${this._options.rgb.b})`;
    }
    if ( this._options.color) {
      if (this._options.alpha) {
        return `rgba(${this._options.color.getOptions().rgb.r}, ${this._options.color.getOptions().rgb.g}, ${this._options.color.getOptions().rgb.b}, ${this._options.alpha})`;
      }
      return `rgb(${this._options.color.getOptions().rgb.r}, ${this._options.color.getOptions().rgb.g}, ${this._options.color.getOptions().rgb.b})`;
    }
    return '';
  }
}

import {
  Plugins,
  StatusBarStyle,
} from '@capacitor/core';

@Injectable()
export class BbitStyleService {

  constructor() {

    if (BbitUtils.isCordova()) {
      const { StatusBar } = Plugins;
      StatusBar.setStyle({
        style: StatusBarStyle.Dark
      });
    }

  }


  private _generateStyles(overwrite?: BbitStyleObject): {[key: string]: string} {
    const defaults = this.getDefaults();
    const generated: {[key: string]: string} = {};
    _.forOwn(overwrite, (value, key) => {
      defaults[key].update(value.getOptions());
    });
    _.forOwn(defaults, (value, key) => {
      if (value instanceof BbitColor) {
        generated[this._getKey(key)] = value.toString();
      } else {
        generated[this._getKey(key)] = value;
      }
    });
    return generated;
  }

  getDefaults(): BbitStyleObject {
    const defaults: any = {};

    // fonts
    defaults.fontColor = new BbitColor( { rgb: { r: 0, g: 0, b: 0 }, alpha: 0.87 });

    // shadows
    defaults.shadowColor = new BbitColor( { rgb: { r: 0, g: 0, b: 0 }, alpha: 0.87 });

    // content
    defaults.contentBackgroundColor = new BbitColor( { rgb: { r: 255, g: 255, b: 255 } });

    defaults.primaryColor = new BbitColor( { rgb: { r: 44, g: 45, b: 49 } });
    defaults.primaryLightColor = new BbitColor( { rgb: { r: 191, g: 192, b: 193 } });
    defaults.primaryContrastColor = new BbitColor( { rgb: { r: 255, g: 255, b: 255 } });

    defaults.secondaryColor = new BbitColor( { rgb: { r: 51, g: 93, b: 127 } });
    defaults.secondaryContrastColor = new BbitColor( { rgb: { r: 255, g: 255, b: 255 } });
    defaults.appDefaultBackgroundColor = new BbitColor( { rgb: {r: 247, g: 247, b: 247}});

    defaults.infoColor = new BbitColor({ rgb: { r: 47, g: 27, b: 207} });
    defaults.errorColor = new BbitColor({ rgb: { r: 175, g: 0, b: 0} });
    defaults.warningColor = new BbitColor({ rgb: { r: 255, g: 126, b: 0} });
    defaults.successColor = new BbitColor({ rgb: {r: 0, g: 131, b: 21} });

    // toolbar
    defaults.toolbarColor = defaults.secondaryColor;
    defaults.toolbarContrastColor = defaults.secondaryContrastColor;
    defaults.toolbarContrastPlaceholderColor = new BbitColor({ color: defaults.toolbarContrastColor, alpha: 0.3 });
    defaults.toolbarMenuItemColor = new BbitColor({ color: defaults.toolbarContrastColor, alpha: 0.7 });
    defaults.toolbarMenuItemHoverColor = defaults.toolbarContrastColor;

    // hotkey
    defaults.hotkeyColor = new BbitColor({ rgb: {r: 255, g: 126, b: 0} });
    defaults.hotkeyContrastColor = new BbitColor({ rgb: {r: 0, g: 0, b: 0} });

    // notifications
    defaults.notificationColor = defaults.primaryColor;
    defaults.notificationContrastColor = defaults.primaryContrastColor;
    defaults.notificationCloseButtonColor = new BbitColor({ color: defaults.notificationContrastColor, alpha: 0.3 });
    defaults.notificationCloseButtonHoverColor = defaults.notificationContrastColor;

    // quill
    defaults.quillHeaderColor = defaults.secondaryColor;

    // grid
    defaults.gridCellFocusColor = defaults.secondaryColor;
    defaults.gridRowActivePreviewColor = new BbitColor({ color: defaults.secondaryColor, alpha: 0.1 });

    // top-navigation
    defaults.topNavigationColor = defaults.primaryColor;
    defaults.topNavigationContrastColor = defaults.primaryContrastColor;
    defaults.topNavigationCloseHoverColor = defaults.errorColor;
    if (BbitUtils.isCordova()) {
      defaults.topNavigationHeight = '57px';
      defaults.topNavigationPaddingTop = '20px';
    } else {
      defaults.topNavigationHeight = '37px';
      defaults.topNavigationPaddingTop = '0';
    }

    // side-navigation
    defaults.sideNavigationColor = defaults.primaryColor;
    defaults.sideNavigationContrastColor = defaults.primaryContrastColor;
    defaults.sideNavigationShadowColor = defaults.shadowColor;

    // buttons
    defaults.buttonColor = defaults.secondaryColor;

    // checkbox
    defaults.checkboxColor = defaults.secondaryColor;
    defaults.checkboxRippleColor = new BbitColor( { color: defaults.checkboxColor, alpha: 0.3 });

    // stepper
    defaults.stepperColor = defaults.secondaryColor;

    // progress
    defaults.progressBarColor = defaults.secondaryColor;
    defaults.progressBarBackgroundColor = new BbitColor({ rgb: { r: 225, g: 225, b: 225 }});

    // inputs
    defaults.inputFocusLabelColor = defaults.secondaryColor;
    defaults.inputFocusLineColor = defaults.secondaryColor;
    defaults.inputSelectIconColor = new BbitColor({ color: defaults.fontColor , alpha: 0.2 });
    defaults.inputSelectIconHoverColor = defaults.fontColor;

    // tooltip
    defaults.tooltipColor = defaults.primaryColor;
    defaults.tooltipContrastColor = defaults.primaryContrastColor;


    return defaults;
  }

  applyStyles(overwrite?: BbitStyleObject, element?: HTMLElement) {
    let el: HTMLElement = element;
    if (!el) {
      el = document.documentElement;
    }
    const generated = this._generateStyles(overwrite);
    _.forOwn(generated, (value, key) => {
      el.style.setProperty(key, value);
    });
  }

  private _getKey(key: string): string {
    const upperChars = key.match(/([A-Z])/g);
    if (!upperChars) {
        return key;
    }

    let str = key.toString();
    for (let i = 0, n = upperChars.length; i < n; i++) {
        str = str.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase());
    }

    if (str.slice(0, 1) === '-') {
        str = str.slice(1);
    }

    return '--' + str;
  }

}