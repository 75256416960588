import { BbitI18nModule } from '@bbit/i18n';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitAuthModule } from '@bbit/core';
import { BbitButtonModule } from './../button/button.module';
import { BbitTabsModule } from './../tabs/tab.module';
import { BbitDisplayButtonComponent } from './display-button.component';

@NgModule({
  imports: [
    BrowserModule,
    BbitI18nModule,
    BbitAuthModule,
    BbitTabsModule,
    BbitButtonModule
  ],
  exports: [
    BbitDisplayButtonComponent,
  ],
  declarations: [
    BbitDisplayButtonComponent
  ]
})
export class BbitDisplayButtonModule { }
