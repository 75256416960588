
import { Component } from '@angular/core';

@Component({
  template: '',
})
export class DashboardComponent {

  constructor() {}

}