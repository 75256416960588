import { BbitUtils } from '@bbit/utils';
import { Injectable } from '@angular/core';
import { BbitAuthService, BbitTokenType, IBbitRequestResult } from '@bbit/core';
import PouchDB from 'pouchdb-browser';
import * as _ from 'lodash';
const path = require('path');
const fs = require('fs');
const os = require('os');

export interface CacheDocument {
  _id: string;
  _rev: string;
  uniqueEntityName: string;
  doc: any;
}


@Injectable({
  providedIn: 'root',
})
export class CacheService {

  filePath: string;
  rootPath: string;
  data: CacheDocument[] = [];

  private _pouchDBs: {[key: string]: any} = {};


  constructor(
    private _auth: BbitAuthService
  ) {
    if (BbitUtils.isElectron()) {
      const electron = require('electron');
      const userDataPath = (electron.app || electron.remote.app).getPath('userData');
      this.rootPath = path.join(os.homedir(), '.bbit');
      this.filePath = path.join(this.rootPath, 'local-cache.json');
      this.data = this.loadFile();
    }

  }

  public save(uniqueEntityName: string, doc: any): Promise<void> {
    if (!BbitUtils.isElectron()) {
      return Promise.resolve();
    }
    _.remove(this.data, (o) => o._id === doc._id );
    this.data.push({
      _id: doc._id,
      _rev: doc._rev,
      uniqueEntityName: uniqueEntityName,
      doc: doc,
    });
    return this.saveFile();
  }

  public loadFile() {
    if (!BbitUtils.isElectron()) {
      return [];
    }
    try {
      return JSON.parse(fs.readFileSync(this.filePath));
    } catch (error) {
      return [];
    }
  }

  public saveFile(): Promise<void> {
    if (!BbitUtils.isElectron()) {
      return Promise.resolve();
    }
    try {
      if (!fs.existsSync(this.rootPath)) {
        fs.mkdirSync(this.rootPath);
      }
      fs.writeFileSync(this.filePath, JSON.stringify(this.data));
      return Promise.resolve();
    } catch (error) {
      console.error('Error while saving cache file', error, this.filePath, this.data);
      return Promise.reject();
    }
  }

  public async checkSyncedData(): Promise<any> {
    if (!BbitUtils.isElectron()) {
      return Promise.resolve();
    }
    const notSynced: CacheDocument[] = [];

    console.log('arsch check data', this.data);

    for (const o of this.data) {

      // get database
      const db = await this._getDatabase(o.uniqueEntityName);

      // load revisions
      const d = await db.get(o._id, {
        revs: true,
        open_revs: 'all'
      });

      const foundDoc = d[0].ok;
      let lastRevision = foundDoc._revisions.start;

      const revisions = _.map(foundDoc._revisions.ids, r => {
        const revision = {
          id: o._id,
          rev: lastRevision + '-' + r
        };
        lastRevision--;
        return revision;
      });
      delete foundDoc._revisions;

      const promises = [];
      for (const revision of revisions) {
        promises.push(db.get(o._id, {
          revs: revision,
        }));
      }

      // get documents by revision
      const result = await db.bulkGet({
        docs: revisions,
        include_docs: true
      });

      // parse docs
      let docs = [];
      for (let res of result.results) {
        // filter missing elements
        if (res.docs[0].ok) {
          docs.push(res.docs[0].ok);
        }
      }
      docs = docs.reverse();

      if (!_.find(docs, (t) => { return t._rev === o._rev; })) {
        notSynced.push(o);
      }

    }
    this.data = notSynced;
    this.saveFile();

    console.log('arsch checked data', this.data);

    return Promise.resolve(this.data);
  }

  private _getDatabase(uniqueEntityName: string): Promise<any> {
    if (this._pouchDBs[uniqueEntityName]) {
      return Promise.resolve(this._pouchDBs[uniqueEntityName]);
    }
    const bucket = 'main';
    const endpoint = 'bbit-cloud';
    const organization = this._auth.getCurrentSession().getOrganisation().key;
    const endpointUrl = this._auth.getCurrentSession().getMgmtApiService().getDatabaseEndpoints()[endpoint];
    const database = organization + '+' + uniqueEntityName + '+' + bucket;

    return this._auth.getCurrentSession().retrieveToken('databases', BbitTokenType.ACCESS).then((result: IBbitRequestResult) => {
      const _pouchDB = new PouchDB(`${endpointUrl}/${database}`, {
        fetch: function (url, opts) {
          if (result.data) {
            opts.headers.set('Authorization', 'Bearer ' + result.data);
          }
          return PouchDB.fetch(url, opts);
        }
      });

      this._pouchDBs[uniqueEntityName] = _pouchDB;
      return Promise.resolve(_pouchDB);
    });
  }

}