import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitI18nModule } from '@bbit/i18n';
import { BbitCardModule } from '../card/card.module';
import { BbitScheduleComponent } from './schedule.component';


@NgModule({
  imports: [
    BbitCardModule,
    BrowserModule,
    BbitI18nModule
  ],
  exports: [
    BbitScheduleComponent,
  ]
  ,
  declarations: [
    BbitScheduleComponent,
  ]
})
export class BbitScheduleModule { }