import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { BbitShortcutService } from './shortcut.service';

@NgModule({
  imports: [
    BrowserModule,
    BbitHotkeyModule
  ],
  exports: [
  ],
  declarations: [
  ],
  providers: [
    BbitShortcutService
  ]
})
export class BbitShortcutModule { }
