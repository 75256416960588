import { IBbitI18n } from '@bbit/i18n';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Utils } from '@bbit/core';
import * as _ from 'lodash';
import { BbitInputTextComponent } from './../input-text/input-text.component';
import { IBbitInputSuffix } from './../input-text/interfaces.d';
import { BbitLog } from '@bbit/log';



@Component({
  selector: 'bbit-input-select',
  templateUrl: 'input-select.component.pug',
  styleUrls: ['input-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitInputSelectComponent implements OnInit, OnChanges {

  private _log = BbitLog.scope({
    package: 'BbitInputSelectComponent'
  });

  public options;

  @ViewChild(BbitInputTextComponent) _input: BbitInputTextComponent;
  @ViewChild(CdkConnectedOverlay) _connectedOverlay: CdkConnectedOverlay;

  _panelOpen: boolean = false;
  _mouseOverOverlay: boolean = false;
  _selectedOption: number = null;
  _hoveredOption: number = null;
  _filterFunction: (filter: string) => Promise<any>;
  _throttledFilterFunctionTimeout: any;
  _templateListId: string;
  _lastMousePosition: any;
  _arrowScrolled: boolean = false;
  _marginTop: string = 'top';
  _menuPosition: 'top' | 'bottom';
  _suffixClicked: boolean = false;
  _menuId: string;
  _noFilterResultTimeout = null;
  _lastSelectedValue;
  _currentPanelWith: string;

  @Input() value: any;
  @Input() tooltip: string | IBbitI18n;
  @Input() label: string | IBbitI18n;
  @Input() error: string | IBbitI18n;
  @Input() warning: string | IBbitI18n;
  @Input() info: string | IBbitI18n;
  @Input() type: string | IBbitI18n;
  @Input() disabled: boolean;
  @Input() suffixes: IBbitInputSuffix[];

  @Input('options') optionsInput: any;
  @Input() filterThrottle: number = 0;
  @Input() menu: any[] = [];


  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() focusEmitter: EventEmitter<any> = new EventEmitter();
  @Output() suffixAction: EventEmitter<any> = new EventEmitter();

  @Output() filter: EventEmitter<string> = new EventEmitter();
  @Output() select: EventEmitter<{ _id: string }> = new EventEmitter();
  @Output() action: EventEmitter<string> = new EventEmitter();

  @ViewChild('panel') templateRef: TemplateRef<any>;
  @ViewChild('filterList') filterList: ElementRef;

  @ViewChild(BbitInputTextComponent) bbitTextInput: BbitInputTextComponent;

  _suffixes: any;

  constructor(
    private _zone: NgZone,
    private _cdr: ChangeDetectorRef,
    private _element: ElementRef,
  ) {

    this._templateListId = 'selectlist_' + Utils.makeId();
    this._menuId = 'menu_' + Utils.makeId();

  }

  ngOnInit() {
    const self = this;

    if (!self.suffixes || self.suffixes.length === 0) {
      self.suffixes = [];
    }

    if (!self.disabled && !_.find(self.suffixes, { icon: 'keyboard_arrow_down'})) {
      self.suffixes.push({ icon: 'keyboard_arrow_down' });
    }

    if (typeof self.optionsInput === 'function') {
      self._filterFunction = self.optionsInput;
      self.options = null;
    }
    else {
      self.options = self.optionsInput;
    }

    // force rendering on text-input, better solution?
    self._suffixes = _.cloneDeep(self.suffixes);


  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this._lastSelectedValue = changes['value'].currentValue;
    }
  }

  public selectText() {
    this._input.selectText();
  }

  public focus() {
    this._input.focus();
  }

  _onKeydown(event) {
    switch (event.key) {
      case 'Tab':
        this._enter();
        break;
      case 'Escape':
        this._esc();
        event.preventDefault();
        break;
      case 'Enter':
        this._enter();
        event.preventDefault();
        break;
      case 'ArrowDown':
        this._arrowDown();
        event.preventDefault();
        break;
      case 'ArrowUp':
        this._arrowUp();
        event.preventDefault();
        break;
    }
  }

  _enter() {
    if (this.options && this.options.length > 0) {
      this._select(this._selectedOption);
    }
  }

  _esc() {
    this._selectedOption = null;

    if (this._panelOpen) {
      this.closePanel();
    }
    else {
      this._select(-1);
    }
  }

  _arrowDown() {
    if (this.options && this._selectedOption < this.options.length - 1) {
      this._selectedOption++;
      this._hoveredOption = null;
      this._arrowScrolled = true;
      const templateList = this._getTemplateList();
      if (templateList) {
        const listItem = this._getTemplateListItem(templateList, this._selectedOption);
        if (listItem) {
          if (listItem.offsetTop + listItem.offsetHeight > templateList.scrollTop + templateList.offsetHeight) {
            templateList.scrollTop = (listItem.offsetTop + listItem.offsetHeight) - templateList.offsetHeight - 6;
          }
        }
      }
    }

  }
  _arrowUp() {
    if (this.options && this._selectedOption > 0) {
      this._selectedOption--;
      this._hoveredOption = null;
      this._arrowScrolled = true;
      const templateList = this._getTemplateList();
      if (templateList) {
        const listItem = this._getTemplateListItem(templateList, this._selectedOption);
        if (listItem) {
          if (listItem.offsetTop < templateList.scrollTop) {
            templateList.scrollTop = listItem.offsetTop - 6;
          }
        }
      }
    }

  }

  _getMenu() {
    return document.getElementById(this._menuId);
  }

  _getTemplateList() {
    return document.getElementById(this._templateListId);
  }

  _getTemplateListItem(templateList, index) {
    return $(templateList).find('.bbit-filter-item')[index];
  }

  _onValueChange($event): void {
    this.valueChange.emit($event);
  }

  _onBlur($event): void {
    if (!this._mouseOverOverlay) { // blur-event is executed before click-event
      this._select(this._selectedOption);
    }
    // else {
    //   // click event will be executed afterwards
    //   // ToDo: backup with setTimeout to prevent not closed popups
    // }

    this.blur.emit($event);
  }

  _onFocus($event): void {
    this._selectedOption = null;
    this._hoveredOption = null;

    if (this._suffixClicked) {
      this._suffixClicked = false;
      return;
    }

    this._filter(null, false);
    this.openPanel();

    this.focusEmitter.emit($event);
  }

  _onSuffixAction($event): void {
    this._suffixClicked = true;
    this.closePanel();
    this.suffixAction.emit($event);
  }

  _onAction(action, $event) {
    this.closePanel();
    this.action.emit(action);
  }

  _onFilterChange($event): void {
    this.filter.emit($event);
    this.valueChange.emit($event);
    if (!$event && $event.length === 0) {
      this._select(-1);
    }
    this.openPanel();
    this._filter($event, $event && $event.length > 0);
  }

  _filter(value, selectFirst: boolean) {
    const self = this;
    if (self._filterFunction) {

      if (self._throttledFilterFunctionTimeout) {
        clearTimeout(self._throttledFilterFunctionTimeout);

      }

      if (self.filterThrottle > 0) {

        self._throttledFilterFunctionTimeout = setTimeout(this.__doFilter.bind(this), self.filterThrottle, value, selectFirst);
      }
      else {
        this.__doFilter(value, selectFirst);
      }
    }
    else {
      this._log.warning('ToDo, no filter function set');
    }
  }

  __doFilter(value, selectFirst: boolean) {
    const self = this;

    this._zone.run(() => {
      let promise;
      try {
        promise = self._filterFunction(value);
      } catch (err) {
        this._log.error('ERROR ON CALLING _filterFunction: ', err);
        self._setFilteredOptions(null, false);
        return;
      }

      if (Utils.isPromise(promise)) {
        if (self._noFilterResultTimeout) clearTimeout(self._noFilterResultTimeout);
        self._noFilterResultTimeout = setTimeout(() => { if (self._noFilterResultTimeout) this._log.error('TIMEOUT ON FILTER PROMISE'); }, 5000);

        promise.then((options) => {

          self._setFilteredOptions(options, selectFirst);

        }).catch(err => {
          this._log.error('error on _filter', err);
          self._setFilteredOptions(null, false);
        });
      }
      else if (promise) {
        self._setFilteredOptions(promise || [], selectFirst);
      }
      else {
        this._log.error('ERROR ON CALLING _filterFunction: it did not return a promise or any result', promise, self._filterFunction);
        self._setFilteredOptions(null, false);
      }
    });
  }

  _setFilteredOptions(options: any, selectFirst: boolean) {


    if (this._noFilterResultTimeout) clearTimeout(this._noFilterResultTimeout);
    this._noFilterResultTimeout = null;

    if (this._throttledFilterFunctionTimeout) clearTimeout(this._throttledFilterFunctionTimeout);
    this._throttledFilterFunctionTimeout = null;

    this._selectedOption = selectFirst && options && options.length > 0 ? 0 : null;
    this._hoveredOption = null;
    this.options = options;
    this._cdr.markForCheck();
  }

  _click(index) {
    this._select(index);
  }

  _select(index) {

    if (index === undefined) {
      return;
    }
    else if (index === null) { // reset to last selection
      this.value = this._lastSelectedValue && this._lastSelectedValue.length > 0 ? this._lastSelectedValue : '';
      this.forceInputValue(this.value); // why is this necessary?
      this._selectedOption = null;
      this._hoveredOption = null;
      this._cdr.markForCheck();
      this.closePanel();
    }
    else if (index === -1) { // deselect to nothing
      this.value = '';
      this._lastSelectedValue = '';
      this._selectedOption = null;
      this._hoveredOption = null;
      this.select.emit({ _id: null });
      this._cdr.markForCheck();
      this.closePanel();
    }
    else if (this.options && index >= 0 && index < this.options.length) { // select entry
      this.value = this.options[index].displayValue;
      this._lastSelectedValue = this.value;
      this._selectedOption = null;
      this._hoveredOption = null;
      this.select.emit(this.options[index]);
      this._cdr.markForCheck();
      this.closePanel();
    }
  }

  _onItemOver(event: any, index: number) {
    const templateList = this._getTemplateList();
    if (this._lastMousePosition) {
      if (event.clientX === this._lastMousePosition.clientX && event.clientY === this._lastMousePosition.clientY && this._arrowScrolled) {
        return;
      }
    }
    this._lastMousePosition = event;
    this._arrowScrolled = false;
    this._hoveredOption = index;
  }

  /** Force a value to be set as inputvalue  */
  forceInputValue(value: string) {
    this.value = value;
    this.bbitTextInput.value = value;
    this.bbitTextInput.domInput.nativeElement.value = value;
  }

  togglePanel(): void {
    this._panelOpen ? this.closePanel() : this.openPanel();
  }

  openPanel(): void {

    if (this.disabled || this._panelOpen) {
      return;
    }

    this._hoveredOption = null;
    this._selectedOption = null;
    this._currentPanelWith = this._calcPanelWidth();

    this._panelOpen = true;


  }

  afterOverlayAttached() {
    this._connectedOverlay.overlayRef.overlayElement.addEventListener('mouseover', (event) => {
      this._mouseOverOverlay = true;
    });

    this._connectedOverlay.overlayRef.overlayElement.addEventListener('mouseleave', () => {
      this._mouseOverOverlay = false;
    });
  }


  closePanel() {
    if (!this._panelOpen) {
      return;
    }
    this._panelOpen = false;

    this._hoveredOption = null;
    this._selectedOption = null;
    this._mouseOverOverlay = false;

  }


  _calcPanelWidth() {
    let elementWidth = this._element.nativeElement.offsetWidth;
    let selectWidth = 0;
    let width = selectWidth > elementWidth ? selectWidth : elementWidth;
    return width + 'px';
  }


}