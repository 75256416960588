
export class NotificationTimer {

  private timer: any;
  private start: number;
  private remaining: number;
  private callback: any;

  setDelay(delay: number) {
    this.remaining = delay;
  }

  setCallback(callback: any) {
    this.callback = callback;
  }

  pause() {
    clearTimeout(this.timer);
    this.remaining -= new Date().getTime() - this.start;
  }

  resume() {
    this.start = new Date().getTime();
    clearTimeout(this.timer);
    this.timer = setTimeout(this.callback, this.remaining);
  }


}