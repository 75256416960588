import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitPrintAreaComponent } from './print-area.component';
import { BbitPrintComponent } from './print.component';
import { BbitPrintService } from './print.service';


@NgModule({
  providers: [
    BbitPrintService
  ],
  exports: [
    BbitPrintAreaComponent,
    BbitPrintComponent
  ],
  declarations: [
    BbitPrintAreaComponent,
    BbitPrintComponent
  ],
  bootstrap: [
    BbitPrintAreaComponent,
    BbitPrintComponent
  ],
  imports: [
    BrowserModule,
  ],
})
export class BbitPrintModule { }