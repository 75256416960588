import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BbitAction, BbitAuthService, BbitMessagingService, BbitSessionState, BbitSettingsService, IBbitEvent, IBbitSession, Utils, BbitSelectService } from '@bbit/core';
import { Subscription } from 'rxjs';
import { BbitTabService } from '../tabs/tab.service';
import { BbitNotificationService } from './../notification/notification.service';
import { BbitI18n } from '@bbit/i18n';

@Component({
  selector: 'bbit-top-navigation',
  // imported in app.component.scss to be used before angular is ready
  // styleUrls: ["top-navigation.component.scss"],
  templateUrl: 'top-navigation.component.pug',
})
export class BbitTopNavigation implements OnInit {

  @ViewChild('navigation') navigation: ElementRef;
  @ViewChild('organization') organizaton: ElementRef;
  @ViewChild('user') user: ElementRef;

  _subscriptions: Subscription[] = [];
  currentSession: IBbitSession;
  organisationName: string;
  utils = Utils;
  isSyncWorking = true;
  syncCheckInterval: any;


  constructor(
    private _authService: BbitAuthService,
    private _settingsService: BbitSettingsService,
    private _notify: BbitNotificationService,
    public tabService: BbitTabService,
    private _messagingService: BbitMessagingService,
    private _cdr: ChangeDetectorRef,
    private _selectService: BbitSelectService,
  ) {

  }

  ngOnInit(): void {
    const self = this;

    this._subscriptions.push(this._authService.onSessionChanged((change: { old: IBbitSession, new: IBbitSession }) => {
      self.onCurrentSessionChanged(change.new);
    }));

    this._subscriptions.push(this._messagingService.observeTopic(null, BbitAction.ON_SESSION_STATE_CHANGES).subscribe(
      (event: IBbitEvent<{ session: IBbitSession, old: BbitSessionState, new: BbitSessionState }>) => {
        if (event.payload.session === self.currentSession) {
          self.onCurrentSessionStateChanged(event.payload.session, event.payload.new);
        }
      }
    ));

    this.onCurrentSessionChanged(this._authService.getCurrentSession());

  }

  checkSync() {
    if (!this.isSyncWorking) {
      return false;
    }
    this._selectService.request('SYNC', 'branch', {}, this._authService.getCurrentSession()).then(() => {
      this.isSyncWorking = true;
      this._cdr.markForCheck();
    }).catch((err) => {
      this.isSyncWorking = false;
      this._notify.showNotification({
        type: 'error',
        text: BbitI18n.t({ key: 'auto-sync-error' }),
        detail: BbitI18n.t({ key: 'please-reload-app' })
      });
      this._cdr.markForCheck();
    });
  }


  openAppMenu() {
    if (Utils.isElectronApp()) {
      window.require('electron').ipcRenderer.send('app:menu:open');
    }
  }

  openSyncTab() {
    this.tabService.create({
      uniqueEntityName: 'component',
      viewName: 'CacheComponent'
    });
  }

  minimizeApp() {
    if (Utils.isElectronApp()) {
      window.require('electron').ipcRenderer.send('app:minimize');
    }
  }

  maximizeApp() {
    if (Utils.isElectronApp()) {
      window.require('electron').ipcRenderer.send('app:maximize');
    }
  }

  closeApp() {
    if (Utils.isElectronApp()) {
      window.require('electron').ipcRenderer.send('app:close');
    }
  }

  onCurrentSessionChanged(session: IBbitSession) {
    this.currentSession = session;

    this.onCurrentSessionStateChanged(session, session ? session.getState() : null);
  }

  onCurrentSessionStateChanged(session: IBbitSession, newState: BbitSessionState) {
    const self = this;
    this.organisationName = '';

    if (newState && newState === BbitSessionState.LOGGED_IN) {

      this.organisationName = this._settingsService.getSettingValue('mandator/name');

      this.checkSync();
      this.syncCheckInterval = setInterval(() => {
        this.checkSync();
      }, 1 * 60 * 1000);

      this._subscriptions.push(
        this._settingsService.getSettingChangedObservable().subscribe((res) => {
          if (self.currentSession === session) {
            this.organisationName = this._settingsService.getSettingValue('mandator/name');
          }
        }));
    } else {
      if (this.syncCheckInterval) {
        clearInterval(this.syncCheckInterval);
      }
    }
  }

  getTabsStyle() {
    let maxWidth = this.navigation.nativeElement.offsetWidth - this.organizaton.nativeElement.offsetWidth - this.user.nativeElement.offsetWidth - 10;
    if (Utils.isMacOS()) {
      maxWidth = maxWidth - 75;
    }
    return { 'max-width': `${maxWidth}px` };
  }

  public openUserSettings() {
    if (this.isLoggedIn()) {
      this.tabService.create({
        uniqueEntityName: 'component',
        viewName: 'BbitUserComponent'
      });
    }
  }

  public isLoggedIn() {
    return this._authService.getCurrentSession() && this._authService.getCurrentSession().getState() === BbitSessionState.LOGGED_IN;
  }

  public openDashboard() {
    // const self = this;
    // switch (this._authService.getCurrentSession().getState()) {
    //   case BbitSessionState.LOGGED_IN:
    //     self.showNavigationAlert("error")
    //       .then(() => self.showNavigationAlert("error"))
    //       .then(() => self.showNavigationAlert("error"));
    //     break;

    //   default:
    // }
  }

  public showNavigationAlert(type: string): Promise<any> {
    let color = '#006600';
    let duration = 700;

    switch (type) {
      case 'error':
        color = 'darkred';
        duration = 150;
        break;
    }

    const transitionDuraton = `${duration / 1000}s`;

    return new Promise(function (resolve) {
      const sideColor = document.documentElement.style.getPropertyValue('--side-navigation-bg');
      const topColor = document.documentElement.style.getPropertyValue('--top-navigation-color');
      document.documentElement.style.setProperty('--side-navigation-color', color);
      document.documentElement.style.setProperty('--top-navigation-color', color);
      document.documentElement.style.setProperty('--navigation-alert-transition-duration', transitionDuraton);
      setTimeout(() => {
        document.documentElement.style.setProperty('--side-navigation-color', sideColor);
        document.documentElement.style.setProperty('--top-navigation-color', topColor);
        // resolve();
        setTimeout(() => {
          resolve();
        }, duration);
      }, duration);
    });

  }


}