import { Injectable } from '@angular/core';
import { BbitAction, BbitMessagingService, Utils } from '@bbit/core';
import { BbitNotificationService } from '../notification/notification.service';



@Injectable()
export class BbitDownloadService {

  constructor(
    private _notify: BbitNotificationService,
    private _eventBus: BbitMessagingService) {
      this._eventBus.observeTopic(null, BbitAction.DOWNLOAD_FILE, null, null, null, null).subscribe((event) => {
        this.download(event.payload);
      });
  }

  download(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (Utils.isElectronApp()) {
        const events = window.require('electron').ipcRenderer;
        events.on('download:error', (error) => {
          this._notify.showNotification({
            // TODO translate
            text: 'Error while downloading file',
            type: 'error',
            detail: error
          });
          return reject(error);
        });
        events.on('download:success', () => {
          return resolve();
        });
        events.send('download:start', { url: url, open: true });
      } else {
        window.open(url, '_blank');
        return resolve();
      }
    });
  }

}