import { Utils } from '@bbit/core';
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

@Injectable()
export class BbitIconService {

  private _initialized = false;
  constructor(
    private _mdIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) {
  }

  init() {
    if (this._initialized) return;

    this._mdIconRegistry.registerFontClassAlias('materialdesignicons-webfont', 'mdi');

    // register svgs
    const keys = (<any>require).context('../../../assets/icons/', true, /\.svg$/).keys();
    _.map(keys, (key) => {
      this.register((Utils.isElectronApp() ? '.' : '') + '/icons/' + key.substr(2));
    });

    this._initialized = true;


  }

  getIconName(url: string) {
    let index = url.lastIndexOf('/') + 1;
    let filenameWithExtension = url.substr(index);
    let filename = filenameWithExtension.split('.')[0];
    return filename;
  }

  register(iconUrl) {
    let name = this.getIconName(iconUrl);

    this._mdIconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(iconUrl));
  }


}