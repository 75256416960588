import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BbitAuthService, BbitSelectService } from '@bbit/core';
import * as _ from 'lodash';


@Component({
  selector: 'temp-westwind-person-work-type',
  templateUrl: 'temp-westwind-person-work-type.component.pug',
  styleUrls: [
    'temp-westwind-person-work-type.component.scss'
  ]
})
export class TempWestwindPersonWorkType implements OnInit {

  @Input('ui') ui;

  data: any;

  constructor(
    private _selectService: BbitSelectService,
    private _authService: BbitAuthService,
    private _cdr: ChangeDetectorRef
  ) {

  }


  ngOnInit() {
    const self = this;
    self._selectService.ensureCache(self._authService.getCurrentSession(), { 'person': ['ALL'], 'apartment': ['ALL'] }).then(() => {
      let personController = self._selectService.getCacheController(self._authService.getCurrentSession(), 'person');
      const person = personController.getData(self.ui.jsonPath);

      if (person.offers && person.offers.work && person.offers.work.used) {
        const contracts = _.orderBy(person.work, c => new Date(c.validFrom), ['desc']);
        if (contracts.length > 0) {
          const contract = contracts[0];
          if (contract) {
            self.data = personController.getDisplayValue(self.ui.jsonPath + '.work.' + contract._id + '.fieldsOfApplication', {}).data;
          }
        }
      }
      self._cdr.markForCheck();
    });
  }


}
