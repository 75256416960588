import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'bbit-menu-button',
  templateUrl: 'menu-button.component.pug',
  styleUrls: [
    'menu-button.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitMenuButtonComponent {

  // @ViewChild(MdMenuTrigger) trigger: MdMenuTrigger;
  @Input() icon: string;
  @Input() menu: any;
  @Output('action') actionEventEmitter = new EventEmitter();

  constructor() {
  }

  action(item) {
    this.actionEventEmitter.emit(item);
  }

}