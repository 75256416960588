import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { AppView, BbitAppStateService, Utils } from '@bbit/core';
import { BbitLog } from '@bbit/log';


@Injectable()
export class BbitUpdateService {

  private _log = BbitLog.scope({
    package: 'BbitUpdateService'
  });

  constructor(
    private _appState: BbitAppStateService,
    private _appRef: ApplicationRef,
    private _zone: NgZone
  ) {

  }

  public installUpdate(): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      if (Utils.isElectronApp()) {
        const events = window.require('electron').ipcRenderer;

        events.on('update:checking', () => {
          this._log.scope({ function: 'update:checking' }).info('Checking for updates');
          this._zone.run(() => {
            this._appState.addLoadingText('Checking for updates...');
          });
        });

        events.on('update:error', (event, error) => {
          this._log.scope({ function: 'update:error' }).error('Error while downloading updates', error);
          this._zone.run(() => {
            this._appState.addLoadingText(`Error while downloading updates: ${error}`);
            resolve();
          });
        });

        events.on('update:log', (event, log) => {
          this._log.scope({ function: 'update:log' }).info(log);
        });

        events.on('update:available', (event) => {
          this._log.scope({ function: 'update:available' }).info('A new update is available');
          this._zone.run(() => {
            this._appState.setView(AppView.UPDATE, 'Updates are available!');
          });
        });

        events.on('update:unavailable', (event) => {
          this._log.scope({ function: 'update:unavailable' }).info('No update is available');
          this._zone.run(() => {
            this._appState.addLoadingText('Your software is up to date!');
            resolve();
          });
        });

        events.on('update:downloaded', (event) => {
          this._log.scope({ function: 'update:downloaded' }).info('Update successfully downloaded');
          this._zone.run(() => {
            this._appState.setView(AppView.UPDATE, 'Your app will restart in a few seconds!');
            setTimeout(() => {
              // reject("Restarting....");
              events.send('update:restart');
            }, 2000);
          });
        });

        events.on('update:progress', (event, progress) => {
          this._log.scope({ function: 'update:progress' }).info(progress);
          this._zone.run(() => {
            this._appState.setView(AppView.UPDATE, 'Downloading updates...');
            this._appState.setUpdateProgress(progress.percent);
          });
        });

        events.send('update:start');
      }
      else {
        resolve();
      }
    });
  }

}