import { IBbitI18n } from '@bbit/i18n';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material';
import { IBbitInputSuffix } from './../input-text/interfaces.d';

@Component({
  selector: 'bbit-input-checkbox',
  templateUrl: 'input-checkbox.component.pug',
  styleUrls: ['input-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitInputCheckboxComponent {

  @Input() value: boolean;
  @Input() tooltip: string | IBbitI18n;
  @Input() label: string | IBbitI18n;
  @Input() error: string | IBbitI18n;
  @Input() warning: string | IBbitI18n;
  @Input() info: string | IBbitI18n;
  @Input() disabled: boolean;

  @Input() suffixes: IBbitInputSuffix[];

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();
  @Output() suffixAction: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatCheckbox) mdCheckbox: MatCheckbox;

  constructor() {}

  _onValueChange($event): void {
    this.valueChange.emit($event.checked);
  }

  _onBlur($event): void {
    this.blur.emit($event);
  }

  _onFocus($event): void {
    // this.selectText();
    this.focus.emit($event);
  }

  _onSuffixAction($event): void {
    this.suffixAction.emit($event);
  }

  toggle() {
    this.mdCheckbox.toggle();
    this._onValueChange({ checked: !this.value });
  }

}