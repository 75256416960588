import { Component, ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { BbitAuthService, BbitController, BbitSchemaService, BbitSessionState, IBbitRequestResult, IBbitSession, Utils } from '@bbit/core';
import * as _ from 'lodash';
import { IBbitTabInterface, IBbitTabSerialized } from './interfaces';
import { BbitLog } from '@bbit/log';

@Injectable()
export class BbitTabService {

  tabGroup: any;
  tabContentElementRef: ViewContainerRef;
  tabs: Array<any>;
  currentTabIndex: number = 0;
  currentTab: IBbitTabInterface;

  private _log = BbitLog.scope({
    package: 'BbitTabService'
  });
  private registry: { [key: string]: Component } = {};

  constructor(private cfr: ComponentFactoryResolver, private _authService: BbitAuthService, private _schemaService: BbitSchemaService) {
    this.tabs = [];
    this._authService.onNewSession((session: IBbitSession) => {
      session.registerSessionStateChangeHandler(900, 'tabService', this._handleSessionStateChange.bind(this));
    });

  }

  public getTabs() {
    return this.tabs;
  }

  public hasTabs() {
    return this.tabs.length > 0;
  }

  private _handleSessionStateChange(session: IBbitSession, oldState: BbitSessionState, newState: BbitSessionState): Promise<IBbitRequestResult> {
    if (!session) return Promise.resolve({ statusCode: 200 });
    const self = this;

    switch (newState) {
      case BbitSessionState.LOGGED_IN:
        // return this.loadTabs().then(result => {
        //   this._log.log('tabs loaded', JSON.stringify(result));
        //   return Promise.resolve(result);
        // }, error => {
        //   this._log.error('error loading tabs', error);
        //   return Promise.resolve({ statusCode: 300 });
        // });
        break;

      case BbitSessionState.LOGGING_OUT:

        const closeNextTab = (): Promise<IBbitRequestResult> => {
          if (self.tabs.length > 0) {
            return self.close(self.tabs[self.tabs.length - 1]).then(result => {
              return closeNextTab();
            });
          }
          else {
            return Promise.resolve({ statusCode: 200 });
          }
        };

        return this.saveTabs().then(result => closeNextTab());

    }

    return Promise.resolve({ statusCode: 200 });
  }

  registerComponent(name: string, component: any): Error {
    if (!component) return new Error('param component is empty');

    if (!name || name.length === 0) {
      return new Error('could not get name of component');
    }

    if (this.registry.hasOwnProperty(name)) {
      return new Error('component is already registered');
    }

    this.registry[name] = component;
    return null;
  }

  getComponent(componentName: string): Component {
    return this.registry[componentName];
  }

  getComponentList(): string[] {
    let returnList = [];

    for (let key in this.registry) {
      returnList.push(key);
    }

    return returnList;
  }

  getTabCount() {
    return this.tabs.length;
  }


  private _getComponentFactory(componentName: string): any {
    let component: any = this.getComponent(componentName);
    if (!component) {
      throw new Error('Component ' + componentName + ' not found');
    }

    try {
      return this.cfr.resolveComponentFactory(component);
    } catch (err) {
      this._log.error(err);
      throw err;
    }
  }

  activate(index) {
    if (this.tabs[index] !== this.currentTab) {
      if (this.currentTab &&
        this.currentTab.componentRef &&
        this.currentTab.componentRef.instance &&
        this.currentTab.componentRef.instance.deactivate) {
        this.currentTab.componentRef.instance.deactivate();
      }
      this.currentTab = this.tabs[index];
      this.currentTabIndex = index;

      if (this.tabGroup) {
        this.tabGroup.focusIndex = index;
        this.tabGroup.selectedIndex = index;
      }
    }
    if (this.currentTab &&
      this.currentTab.componentRef &&
      this.currentTab.componentRef.instance &&
      this.currentTab.componentRef.instance.activate) {
      this.currentTab.componentRef.instance.activate();
    }
    this.saveTabs();
  }

  create(param: IBbitTabSerialized, controller: BbitController = null): Error {
    if (!param) return new Error('param message is empty');
    if (!param.uniqueEntityName && param.uniqueEntityName.length === 0) return new Error('param message.uniqueEntityName is empty');
    if (!param.viewName && param.viewName.length === 0) return new Error('param message.viewName is empty');
    if (!param.params) { param.params = {}; }


    // is tab already open
    if (!param.forceNew) {
      const found = _.findIndex(this.tabs, <any>{
        uniqueEntityName: param.uniqueEntityName,
        viewName: param.viewName,
        params: param.params
      });
      if (found >= 0) {
        this.activate(found);
        return null;
      }
    }

    let componentName = null;
    if (param.uniqueEntityName === 'component') {
      componentName = param.viewName;
    }
    else {
      // get Schema for this View
      try {
        const schema = this._schemaService.getSchema(this._authService.getCurrentSession(), param.uniqueEntityName);
        if (!schema) {
          return new Error('Schema for uniqueEntityName ' + param.uniqueEntityName + ' not found');
        }

        const uiSchema = schema.getUiSchema('desktop', param.viewName);
        if (!uiSchema) {
          return new Error('Unknown ui schema ' + param.viewName + ' for uniqueEntityName ' + param.uniqueEntityName);
        }

        if (uiSchema.componentName && uiSchema.componentName.length > 0) {
          componentName = uiSchema.componentName;
        }
        else {
          switch (uiSchema.type) {
            case 'table':
              componentName = 'BaseListControllerComponent';
              break;
            case 'flex':
              componentName = 'BaseDocControllerComponent';
              break;
            default:
            return new Error('No component for ui schema ' + param.viewName + ' for uniqueEntityName ' + param.uniqueEntityName);
          }
        }
      }
      catch (err) {
        return new Error('Error loading ui schema: ' + err ? err.toString() : 'unknown error');
      }
    }

    let componentFactory;
    try {
      componentFactory = this._getComponentFactory(componentName);
    }
    catch (err) {
      return err;
    }

    let componentRef;
    try {
      componentRef = this.tabContentElementRef.createComponent(componentFactory);
    } catch (err) {
      this._log.error(err);
      return err;
    }

    const tab: IBbitTabInterface = {
      id: 'tab_' + Utils.makeId(7, false),
      uniqueEntityName: param.uniqueEntityName,
      viewName: param.viewName,
      params: param.params || {},
      icon: param.params ? param.params.icon : '',
      text: param.params ? param.params.text : null || '',
      componentName: componentName,
      componentRef: componentRef,
      controller: controller
    };

    try {
      if (tab.componentRef.instance.injectParams) {
        tab.componentRef.instance.injectParams(tab);
      }
    } catch (err) {
      this._log.error(err);
      return err;
    }

    try {
      if (tab.componentRef.instance.activate) {
        tab.componentRef.instance.activate();
      }
    } catch (err) {
      this._log.error(err);
      return err;
    }

    this.tabs.push(tab);

    if (!param.preventActivate) {
      this.activate(this.tabs.length - 1);
    }

    return null;
  }

  close(tab): Promise<IBbitRequestResult> {
    return tab.componentRef.instance.isClosingAllowed().then((close) => {
      if (close) {
        // const activateOtherTab = (tab === this.currentTab);
        const index = this.tabs.findIndex((i) => { return i === tab; });
        if (index >= 0) {
          this.tabs[index].componentRef.destroy();
          this.tabs.splice(index, 1);
          this.activate(this.tabs.length - 1);
        }
        return this.saveTabs();
      }
      else {
        return Promise.reject({ statusCode: 406, errorMessage: 'aborted by User' });
      }
    });
  }

  isTabActive(tab) {
    return _.indexOf(this.tabs, tab) === this.currentTabIndex;
  }

  selectTabUi(tab) {
    this.activate(_.indexOf(this.tabs, tab));
  }

  saveTabs(): Promise<IBbitRequestResult> {
    if (!this.tabs) return Promise.resolve({ statusCode: 300 });

    const currentSession = this._authService.getCurrentSession();
    if (!currentSession) return Promise.resolve({ statusCode: 301 });

    const serializedTabs = _.map(this.tabs, tab => {
      let tabState = null;
      if (tab.componentRef &&
        tab.componentRef.instance &&
        tab.componentRef.instance.serialise) {
        tabState = tab.componentRef.instance.serialise();
      }

      const serializedTab: IBbitTabSerialized = {
        uniqueEntityName: tab.uniqueEntityName,
        viewName: tab.viewName,
        params: tab.params,
        state: tabState
      };

      return serializedTab;
    });

    const serialized = { current: this.currentTabIndex, tabs: serializedTabs };


    return Promise.resolve({ statusCode: 200 }); // currentSession.set("tabs", serialized).then(result => self._authService.saveSession(null, false));
  }

  // loadTabs(): Promise<IBbitRequestResult> {
  //   const self = this;
  //   const currentSession = this._authService.getCurrentSession();
  //   if (!currentSession) return Promise.resolve({ statusCode: 301 });

  //   const data = null; // currentSession.get("tabs");
  //   if (!data) return Promise.resolve({ statusCode: 302, data: data });

  //   if (data.tabs && data.tabs.length > 0 && data.current !== null && data.current >= 0) {
  //     /* _.forEach(data.tabs, item => {
  //         self.createTab(item);
  //     });

  //     this.activateTab(data.current); */
  //     return Promise.resolve({ statusCode: 200, data: data });
  //   }
  //   else {
  //     return Promise.resolve({ statusCode: 300, data: data });
  //   }
  // }

  setTabGroup(tabGroup: any) {
    this.tabGroup = tabGroup;
  }

  sendActionToCurrentTab(action: any) {
    this._log.debug('ACTION ' + action, this.currentTab.componentRef.instance.handleAction);
    if (action.action === 'CLOSE-CURRENT-TAB') {
      if (this.currentTab) {
        this.close(this.currentTab);
      }
    } else {
      if (this.currentTab &&
        this.currentTab.componentRef &&
        this.currentTab.componentRef.instance &&
        this.currentTab.componentRef.instance.handleAction
      ) {
        this.currentTab.componentRef.instance.handleAction(action);
      }
    }
  }
}