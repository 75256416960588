import { BbitTooltipModule } from './../tooltip/tooltip.module';
import { BbitScheduleModule } from './../schedule/schedule.module';

import { BbitContentComponent } from './content.component';
import { BbitTabsModule } from '../tabs/tab.module';
import { BbitNotificationModule } from './../notification/notification.module';
import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitIconModule } from '../icon/icon.module';
import { BbitCoreModule } from '@bbit/core';
import { MatTabsModule } from '@angular/material';

@NgModule({
  imports: [
    BbitCoreModule,
    MatTabsModule,
    BbitIconModule,
    BrowserModule,
    FlexLayoutModule,
    BbitHotkeyModule,
    BbitIconModule,
    BbitNotificationModule,
    BbitTabsModule,
    BbitScheduleModule,
    BbitTooltipModule
  ],
  exports: [
    BbitContentComponent,
  ],
  declarations: [
    BbitContentComponent
  ]
})
export class BbitContentModule { }
