import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BbitI18nModule } from '@bbit/i18n';
import { BbitInputTextModule } from './../input-text/input-text.module';
import { BbitInputPasswordComponent } from './input-password.component';



@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    BbitI18nModule,
    BbitInputTextModule
  ],
  exports: [
    BbitInputPasswordComponent
  ],
  declarations: [
    BbitInputPasswordComponent
  ]
})
export class BbitInputPasswordModule { }
