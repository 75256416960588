import { FlexLayoutModule } from '@angular/flex-layout';

import { BbitCardComponent } from './card.component';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material';
import { BbitToolbarModule } from '../toolbar/toolbar.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    MatCardModule,
    BbitToolbarModule,
    BrowserModule,
    FlexLayoutModule,
  ],
  exports: [
    BbitCardComponent,
  ]
  ,
  declarations: [
    BbitCardComponent,
  ]
})
export class BbitCardModule { }