import { BbitStyleService } from './modules/style/style.service';
import { AfterViewInit, ChangeDetectorRef, Component, NgZone, ViewContainerRef } from '@angular/core';
import { AppView, BbitAppStateService, BbitAuthService, BbitSettingsService, CognitoSession, BbitUiController } from '@bbit/core';
import { LicenseManager } from 'ag-grid-enterprise';
import * as interact from 'interactjs';
import { BbitHotkeyService } from './modules/hotkey/hotkey.service';
import { BbitNotificationService } from './modules/notification/notification.service';
import { BbitPrintService } from './modules/print/print.service';
import { BbitShortcutService } from './modules/shortcut/shortcut.service';
import { BbitTabService } from './modules/tabs/tab.service';
import { registerSystemViews } from './modules/ui/ui.module';
import { BbitUpdateService } from './modules/update/update.service';
// import * as Particles from "particlesjs";
import { BbitI18n, BbitLanguage } from '@bbit/i18n';
import { BbitLog } from '@bbit/log';
import { BbitUtils } from '@bbit/utils';

@Component({
  selector: 'bbit-app',
  templateUrl: 'app.component.pug'
})
export class App implements AfterViewInit {

  private _log = BbitLog.scope({
    package: 'App'
  });

  sideNavWidth = 44;
  isCordova = false;

  appView = AppView;

  constructor(
    private _zone: NgZone,
    public _appState: BbitAppStateService,
    private _cdr: ChangeDetectorRef,
    private _settingsService: BbitSettingsService,
    private _tabService: BbitTabService,
    private _authService: BbitAuthService,
    private _notify: BbitNotificationService,
    private _vcr: ViewContainerRef,
    private _updateService: BbitUpdateService,
    private _shortcutService: BbitShortcutService,
    private _hotkeyService: BbitHotkeyService,
    private _printService: BbitPrintService,
    private _style: BbitStyleService) {
    const self = this;

    BbitI18n.setDefaultLanguage(BbitLanguage.DE_CH);
    BbitI18n.setLanguage(BbitLanguage.DE_CH);

    this._authService.registerSessionType('cognito', CognitoSession);

    this._hotkeyService.registerAdjacentHotkey('sudo', () => {
      this._tabService.create({
        uniqueEntityName: 'component',
        viewName: 'BbitSessionComponent'
      });
    });

    this._notify.setAppViewContainerRef(<any>_vcr);

    // TODO where to do this?
    BbitI18n.loadTranslations(['app']);
    LicenseManager.setLicenseKey('bbit_gmbh_bbit_2Devs1_SaaS_29_August_2019__MTU2NzAzMzIwMDAwMA==751f2e02e08eb52f551f07d8f5757d06');

    this._zone.run(() => {
      return Promise.resolve().then(
        () => { return _updateService.installUpdate(); }).then(
          () => { return _appState.initApp(); }).then(
            () => { return _appState.addLoadingText('loading views'); }).then(
              () => { return registerSystemViews(_tabService); }).then(
                () => { return _appState.setView(AppView.LOGIN, ''); })
        .catch((err) => {
          this._log.critical(new Error('#rjch9mshqygttubhe21q'), err);
          return this._appState.setView(AppView.LOADING, 'Fatal error: ' + (err && err.errorMessage ? err.errorMessage : err));
        });
    });
  }

  getSideNavStyle() {
    return {
      flex: `0 0 ${this.sideNavWidth}px`
    };
  }

  ngAfterViewInit() {
    this._style.applyStyles();
    interact('.bbit-side-navigation-resize')
      .resizable({
        // resize from all edges and corners
        edges: { left: false, right: true, bottom: false, top: false },

        onmove: (event: any) => {

          let width = event.rect.width;
          if (width < 44) {
            width = 44;
          }
          if (width > 300) {
            width = 300;
          }
          this.sideNavWidth = width;
          // event.target.style.flex = `0 0 ${width}px`;

        },

        onend: (event) => {
          // TODO implement save
          // self.saveEvent(event.target.getAttribute("data-id"));
        }

      }
      );

  }

  ravenTest() {
  }

}
