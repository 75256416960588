
import { Component } from '@angular/core';
import { BbitAppStateService } from '@bbit/core';

@Component({
  selector: 'bbit-update',
  templateUrl: 'update.component.pug',
  styleUrls: [
    'update.component.scss'
  ]
})
export class BbitUpdateComponent {

  constructor(
    public _appState: BbitAppStateService,
  ) {


  }
}