import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AddressDataType, IBbitSelect } from '@bbit/core';
import * as _ from 'lodash';
import { BbitGeocodeService, IBbitAddress } from './../geocode/geocode.service';
import { BbitInputSelectComponent } from './../input-select/input-select.component';
import { IBbitI18n } from '@bbit/i18n';
import { BbitLog } from '@bbit/log';




@Component({
  selector: 'bbit-input-address',
  templateUrl: 'input-address.component.pug',
  styleUrls: ['input-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitInputAddressComponent implements OnChanges {

  private _log = BbitLog.scope({
    package: 'BbitInputAddressComponent'
  });

  private _countrySelector: IBbitSelect;
  private _defaultRequired: string[];
  private _defaultVisible: string[];
  private _defaultLabels: any;
  private _areaCodeMap: any;
  private _internalAddress: IBbitAddress;
  private _postalBoxInfo: string = '';
  private _postalBoxError: IBbitI18n = null;

  @Input() address: IBbitAddress;
  @Input() required: boolean;
  @Input() requiredFields: string[] = [];
  @Input() visibleFields: string[] = [];
  @Input() hiddenFields: string[] = [];
  @Input() defaultCountry: string;
  @Input() disabled: boolean;
  @Input() coordinatesOnly = false;

  @Output() addressChange: EventEmitter<IBbitAddress> = new EventEmitter();

  @ViewChild('filterInput') filterInput: BbitInputSelectComponent;

  constructor(
    private _geocoder: BbitGeocodeService
  ) {
    // TODO create setting for API keys and create service for Geocoder


    this._internalAddress = {};

    // TODO fix this, should only be here on in ngOnChanges
    // if (!this.address.country) {
    // this._setValue("country", this.defaultCountry, true, true);
    // }

    this._countrySelector = {
      displayField: 'designation',
      from: 'country',
      select: [],
      where: {}
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['address']) {
      this._internalAddress = _.cloneDeep(changes['address'].currentValue || {});

      if (!this._internalAddress.country) {
        if (!this.defaultCountry) {
          this.defaultCountry = '#CH';
        }
        this._setValue('country', this.defaultCountry, false, true);
      } else {
        this._setCountryDefaults(this._internalAddress.country);
      }
    }
  }

  private _setValue(key: string, value: string | number, preventEmit: boolean = false, preventGeocode: boolean = false) {
    switch (key) {
      case 'lat':
      case 'lon':
        value = Number(value).toFixed(6);
        break;
      case 'postalBox':
        this._postalBoxInfo = '';
        break;
      case 'country':
        this._setCountryDefaults(value);
        break;
    }

    this._internalAddress[key] = value;

    if (!preventGeocode) {
      this._debouncedInputGeocode();
    }

    if (!preventEmit) {
      this.address = _.cloneDeep(this._internalAddress);
      this.addressChange.emit(this.address);
    }
  }

  private _inputGeocode() {
    const self = this;
    let data = '';

    if (this.coordinatesOnly) {
      self._geocoder.reverse(self._internalAddress.lat, self._internalAddress.lon).then(result => {
        self._setInputResult(result);
      }).catch((err) => {
        this._log.error(err);
      });
    } else {
      self._geocoder.geocode(self._getGeocodeAddress()).then(results => {
        if (results && results.length === 1) {
          self._setInputResult(results[0]);
        }
      }).catch((err) => {
        this._log.error(err);
      });
    }

  }

  private _getGeocodeAddress() {
    let address = '';

    // TODO country switch
    if (this._internalAddress.street) {
      address += this._internalAddress.street;

      if (this._internalAddress.streetNo) {
        address += ` ${this._internalAddress.streetNo}`;
      }

      address += ',';
    }

    if (this._internalAddress.postalCode || this._internalAddress.locality) {

      if (this._internalAddress.postalCode) {
        address += ` ${this._internalAddress.postalCode}`;
      }

      if (this._internalAddress.locality) {
        address += ` ${this._internalAddress.locality}`;
      }

      address += ',';
    }

    if (this._internalAddress.country) {
      address + ` ${this._internalAddress.country}`;
    }

    return address;
  }

  private _debouncedInputGeocode = _.debounce(() => {
    this._inputGeocode();
  }, 2000);

  private _selectGeocode(event): Promise<any> {
    const self = this;
    if (!event) {
      return Promise.resolve([]);
    }
    return self._geocoder.geocode(event).then(addresses => {
      if (addresses) {
        let a = [];
        for (let address of addresses) {
          a.push({
            displayValue: address.formatted,
            value: address
          });
        }
        return Promise.resolve(a);
      }
      else {
        return Promise.resolve([]);
      }
    }).catch((err) => {
      this._log.error(err);
    });

  }

  private _setInputResult(address: IBbitAddress): {} {

    if (!address) {
      return;
    }

    if (this.coordinatesOnly) {
      this._setSelectResult(address);
    } else {
      this._setValue('lat', address.lat, true, true);
      this._setValue('lon', address.lon, true, true);

      this.address = _.cloneDeep(this._internalAddress);
      this.addressChange.emit(this.address);
    }

  }

  private _setSelectResult(address: IBbitAddress): {} {
    if (!address) {
      return;
    }

    this._setValue('formatted', address.formatted, true, true);
    this._setValue('country', address.country, true, true);
    this._setValue('street', address.street, true, true);
    this._setValue('streetNo', address.streetNo, true, true);
    this._setValue('postalCode', address.postalCode, true, true);
    this._setValue('locality', address.locality, true, true);
    this._setValue('areaCode1Short', address.areaCode1Short, true, true);
    this._setValue('areaCode1Long', address.areaCode1Long, true, true);
    this._setValue('areaCode2Short', address.areaCode2Short, true, true);
    this._setValue('areaCode2Long', address.areaCode2Long, true, true);
    this._setValue('areaCode3Short', address.areaCode3Short, true, true);
    this._setValue('areaCode3Long', address.areaCode3Long, true, true);
    this._setValue('areaCode4Short', address.areaCode4Short, true, true);
    this._setValue('areaCode4Long', address.areaCode4Long, true, true);

    if (!this.coordinatesOnly) {
      this._setValue('lat', address.lat, true, true);
      this._setValue('lon', address.lon, true, true);
    }

    for (let map of this._areaCodeMap) {
      this._setValue(map.dest, map.reference ? `#${this._internalAddress[map.source]}` : this._internalAddress[map.source], true, true);
    }

    this.address = _.cloneDeep(this._internalAddress);
    this.addressChange.emit(this.address);

    if (this.filterInput) {
      this.filterInput.forceInputValue(this._internalAddress.street);
    }

  }

  private _isFieldVisible(field: string): boolean {
    if (this._defaultRequired && this._defaultRequired.indexOf(field) !== -1) {
      return true;
    }
    if (this.requiredFields && this.requiredFields.indexOf(field) !== -1) {
      return true;
    }
    if (this.hiddenFields && this.hiddenFields.indexOf(field) !== -1) {
      return false;
    }
    if (this._defaultVisible && this._defaultVisible.indexOf(field) > -1) {
      return true;
    }
    if (this.visibleFields && this.visibleFields.indexOf(field) > -1) {
      return true;
    }
    return false;
  }

  private _isFieldValid(field: string) {
    if (!this.required) {
      return null;
    }
    if (this._defaultRequired) {
      if (this._defaultRequired.indexOf(field) !== -1) {
        if (!this._internalAddress[field]) {
          return { key: 'field-required-message' };
        }
      }
    }
    else {
      switch (field) {
        case 'street':
        case 'postalCode':
        case 'locality':
        case 'country':
          if (!this._internalAddress[field]) {
            return { key: 'field-required-message' };
          }
          return null;
      }
    }
    if (this.requiredFields && this.requiredFields.indexOf(field) !== -1) {
      if (!this._internalAddress[field]) {
        return { key: 'field-required-message' };
      }
    }
    return null;
  }

  private _getLabel(field: string) {
    const label = this._defaultLabels[field];
    if (label) {
      return label;
    }
    return `${field} (label not set on country)`;
  }

  private _setCountryDefaults(country: any) {
    let defaults = AddressDataType.countries[country];
    if (defaults) {
      this._defaultRequired = defaults.required;
      this._defaultVisible = defaults.visible;
      this._defaultLabels = defaults.labels;
      this._areaCodeMap = defaults.areaCodeMap;
    }
    else {
      this._defaultRequired = [];
      this._defaultVisible = [];
      this._defaultLabels = {};
      this._areaCodeMap = [];
      this._log.warning(`no defaults for country ${country} found`);
    }
  }

  private _getReferenceSelector(field) {
    if (this._areaCodeMap) {
      const selector = <any>_.find(this._areaCodeMap, { dest: field });
      return selector ? selector.reference : null;
    }
  }

  private _onBlur(field: string) {
    switch (field) {
      case 'postalBox':
        if (this._postalBoxError) {
          this._postalBoxError = null;
          this._setValue('postalBox', '');
        } else if (this._postalBoxInfo) {
          this._setValue('postalBox', this._postalBoxInfo);
        }
        break;
    }
  }

  private _evaluateInfo(field: string, value: string) {
    switch (field) {
      case 'postalBox':
        this._postalBoxInfo = '';
        this._postalBoxError = null;
        if (!value) {
          return;
        }
        const evalValue = value.split(' ');
        // TODO implement translations
        if (evalValue.length === 1) {
          if (this._isNumeric(evalValue[0])) {
            this._setPostalBoxInfo(value);
            return;
          } else if ('postfach'.startsWith(evalValue[0].toLowerCase())) {
            this._setPostalBoxInfo('');
            return;
          }
        } else if (evalValue.length >= 2) {
          if ('postfach'.startsWith(evalValue[0].toLowerCase()) && this._isNumeric(evalValue[evalValue.length - 1])) {
            this._setPostalBoxInfo(evalValue[evalValue.length - 1]);
            return;
          } else if ('postfach'.startsWith(evalValue[0].toLowerCase()) && !evalValue[evalValue.length - 1]) {
            this._setPostalBoxInfo('');
            return;
          }
        }
        this._postalBoxError = {
          key: 'input-invalid-message',
          n: 1
        };
        break;
    }

  }

  private _setPostalBoxInfo(value: string) {
    // TODO implement translations
    this._postalBoxInfo = 'Postfach ' + value;
  }

  private _isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
}