import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitAuthModule, BbitFileModule } from '@bbit/core';
import { BbitDisplayImageComponent } from './display-image.component';
import { BbitI18nModule } from '@bbit/i18n';


@NgModule({
    imports: [
        BrowserModule,
        BbitI18nModule,
        BbitAuthModule,
        BbitFileModule
    ],
    exports: [
      BbitDisplayImageComponent,
    ],
    declarations: [
      BbitDisplayImageComponent
    ]
})
export class BbitDisplayImageModule { }
