import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { BbitAppStateService, BbitAuthService, BbitFileService, BbitSettingsService } from '@bbit/core';
import { BbitTabService } from '../tabs/tab.service';
import { BbitPrintService } from './../print/print.service';
import { BbitNotificationService } from '../notification/notification.service';


@Component({
  selector: 'bbit-content',
  styleUrls: ['content.component.scss'],
  templateUrl: 'content.component.pug',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitContentComponent implements AfterViewInit {

  @ViewChild('tabGroup') tabGroup;

  constructor(
    public tabService: BbitTabService,
    private _notify: BbitNotificationService
  ) {}

  ngAfterViewInit() {
    this.tabService.setTabGroup(this.tabGroup);
  }

  test() {
    this._notify.showNotification({
      type: 'error',
      text: 'test text',
      detail: 'detail text'
    });
  }

}