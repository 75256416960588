import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitInputSelectModule } from './../input-select/input-select.module';
import { BbitInputReferenceComponent } from './input-reference.component';
import { BbitI18nModule } from '@bbit/i18n';



@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        BbitCoreModule,
        BbitI18nModule,
        BbitInputSelectModule
    ],
    exports: [
        BbitInputReferenceComponent
    ],
    declarations: [
        BbitInputReferenceComponent
    ]
})
export class BbitInputReferenceModule {}
