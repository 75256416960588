import { BbitLog } from '@bbit/log';
import { HttpClient } from '@angular/common/http';
import { Component, NgZone, ViewChild } from '@angular/core';
import { BbitAuthService, BbitMgmtApiService, BbitTokenType, IBbitRequestResult, Utils } from '@bbit/core';
import { GridOptions } from 'ag-grid-community';
import * as ace from 'brace';
import 'brace/ext/searchbox';
import 'brace/mode/json';
import 'brace/theme/chrome';
import * as _ from 'lodash';
import PouchDB from 'pouchdb-browser';
import * as unidiff from 'unidiff';
import { BbitNotificationService } from '../notification/notification.service';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { Database, DatabaseDocument } from './interfaces';


@Component({
  templateUrl: 'document-detail.component.pug',
  styleUrls: ['document-detail.component.scss']
})
export class DocumentDetailComponent implements IBbitTabController {

  @ViewChild('editorDom') editorDom: any;
  tab: IBbitTabInterface = null; // warning: still externaly accessed, ToDo: refactor such shit
  isActive: boolean = false;
  toolbar: any;

  database: Database;
  databaseDoc: DatabaseDocument;
  editor: any;
  doc: any;
  gridOptions: GridOptions;
  columnDefs: any[];

  private _log = BbitLog.scope({
    package: 'DocumentDetailComponent'
  });
  private _pouchDB: any;

  constructor(
    protected _notify: BbitNotificationService,
    protected _tabService: BbitTabService,
    protected _http: HttpClient,
    protected _auth: BbitAuthService,
    protected _mgmtApi: BbitMgmtApiService
  ) {

    const self = this;

    self.gridOptions = <GridOptions>{
      onGridReady: () => {
        self.gridOptions.api.sizeColumnsToFit();
      },
      floatingFilter: true,
      icons: {
        menu: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">more_vert</i>',
        filter: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">search</i>',
      },
    };


    self.columnDefs = [
      { headerName: 'ID', field: 'id', filter: 'agTextColumnFilter' },
      { headerName: 'Revision', field: 'value.rev', filter: 'agTextColumnFilter' }
    ];

  }

  /**
   * Loads all databases from all endpoints
   */
  public loadDocument(doc: DatabaseDocument): Promise<any> {
    const self = this;

    const loadingPromise = doc && doc.id ? self._pouchDB.get(doc.id, { rev: doc.rev }) : Promise.resolve(null);
    return loadingPromise.then(result => {

      self.doc = result;

      if (self.editorDom) {
        if (!self.editor) {
          self.editor = ace.edit(this.editorDom.nativeElement);
          self.editor.$blockScrolling = Infinity;
          self.editor.setTheme('ace/theme/chrome');
          self.editor.session.setMode('ace/mode/json');
          self.editor.setShowPrintMargin(false);
          self.editor.session.setOptions({
            tabSize: 2
          });
        }

        if (self.doc) {
          self.editor.setValue(JSON.stringify(unidiff.default.canonicalize(self.doc), null, 2), -1);
        }
        else {
          self.editor.setValue('', -1);
        }
      }

      self.updateTitle();

      return Promise.resolve(result);
    }).catch((err) => {
      self._log.error(err);
    });
  }

  updateTitle() {
    if (this.doc && this.doc._id) {
      this.tab.text = { value: this.doc._id + ' \ ' + this.tab.params.database.database };
    }
    else if (this.tab.params.document && this.tab.params.document.id) {
      this.tab.text = { value: this.tab.params.document.id + ' \ ' + this.tab.params.database.database };
    }
    else {
      this.tab.text = { value: 'New \ ' + this.tab.params.database.database };
    }
  }

  injectParams(tab) {
    this.tab = tab;
    this.updateTitle();
    this.tab.icon = 'mdi:file-multiple';
    this.toolbar = {
      icon: 'mdi:file-multiple',
      title: this.tab.text,
      buttons: _.compact([{
        icon: 'more_vert',
        tooltip: { key: 'more', n: 1 },
        menu: _.compact([
          this.tab.uniqueEntityName === 'social-report-living' ? {
            icon: 'print',
            action: 'PRINT',
            text: { key: 'print', n: 1 }
          } : null,
          {
            icon: 'delete',
            action: 'DELETE',
            text: { key: 'delete', n: 1 }
          }
        ])
      },
      this._auth.getCurrentSession().hasWritePermission('superuser') ? {
        icon: 'save',
        action: 'SAVE'
      } : null])
    };

    const self = this;
    self.database = this.tab.params.database;
    self.databaseDoc = this.tab.params.document;
    self._auth.getCurrentSession().retrieveToken('databases', BbitTokenType.ACCESS).then((result: IBbitRequestResult) => {
      self._pouchDB = new PouchDB(`${self.database.endpointUrl}/${self.database.database}`, {
        fetch: function (url, opts) {
          if (result.data) {
            opts.headers.set('Authorization', 'Bearer ' + result.data);
          }
          return PouchDB.fetch(url, opts);
        }
      });

      self.loadDocument(self.databaseDoc).then(doc => {
        self._log.info(`Retrieved document`, doc);
      });

    });

  }

  save(addDeleteProperty: boolean = false): Promise<any> {
    const self = this;
    let docToSave = null;
    try {
      docToSave = JSON.parse(self.editor.getValue());
    }
    catch (err) {
      return Promise.reject(err);
    }

    if (!docToSave._id) return Promise.reject('Missing _id property');

    if (addDeleteProperty) docToSave._deleted = true;
    // ToDo: inject bbit common doc properties like $changedAt, etc...

    return self._pouchDB.put(docToSave).then((result) => {
      if (result.ok) {
        self.databaseDoc = {
          id: result.id,
          rev: result.rev
        };
        return self.loadDocument(self.databaseDoc);
      }
      else {
        return Promise.reject('unknown error');
      }
    });
  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  askForDelete() {

    this._notify.showDefaultDialog({
      title: { key: 'caution', n: 1 },
      message: { key: 'delete-message', n: 1 },
      buttons: [{
        color: 'warn',
        text: { key: 'delete' },
        returnValue: 'DELETE'
      }, {
        text: { key: 'cancel' },
        returnValue: 'CANCEL'
      }]
    }).then((result) => {
      if (result.data === 'DELETE') {
        this.save(true).then(
          res => {
            this._notify.showNotification({
              type: 'success',
              text: 'Erfolgreich gespeichert',
              detail: res._rev
            });
            this._tabService.close(this.tab);
          },
          err => {
            this._notify.showNotification({
              type: 'error',
              text: 'Fehler beim Speichern',
              detail: Utils.stringifyIBbitRequestResult(err)
            });
          }
        );
      }
    });
  }

  handleAction(event: any) {
    switch (event.action) {
      case 'SAVE':
        this.save().then(
          res => {
            this._notify.showNotification({
              type: 'success',
              text: 'Erfolgreich gespeichert',
              detail: res._rev
            });
          },
          err => {
            this._notify.showNotification({
              type: 'error',
              text: 'Fehler beim Speichern',
              detail: Utils.stringifyIBbitRequestResult(err)
            });
          }
        );
        break;
      case 'DELETE':
        this.askForDelete();
        break;
    }
  }


}