import { BbitHotkeyService } from './hotkey.service';
import { BbitHotkeyDirective } from './hotkey.directive';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    BrowserModule
  ],
  exports: [
    BbitHotkeyDirective
  ],
  declarations: [
    BbitHotkeyDirective
  ],
  providers: [
    BbitHotkeyService
  ]
})
export class BbitHotkeyModule { }
