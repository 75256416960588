import { Compiler, Component, ComponentRef, Injectable, NgModule, ViewContainerRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitController, Utils } from '@bbit/core';
import { BbitNotificationService } from './../notification/notification.service';
import { BbitLog } from '@bbit/log';


declare function escape(s: string): string;


@Injectable()
export class BbitPrintService {

  private _log = BbitLog.scope({
    package: 'BbitPrintService'
  });

  public printArea: ViewContainerRef;
  public quillHelper: any;
  private _printPage: string;
  private _defaultStyles: string;
  private _tempDir: string;
  private _fs: any;
  private _os: any;
  private _shell: any;

  constructor(
    private _compiler: Compiler,
    private _notify: BbitNotificationService
  ) {
    this._defaultStyles = require('./page.scss');
    this._printPage = require('./page.pug');
    if (Utils.isElectronApp()) {
      this._os = window.require('os');
      this._fs = window.require('fs');
      this._shell = window.require('electron').shell;
      this._tempDir = this._os.tmpdir() + '/bbit';
      if (!this._fs.existsSync(this._tempDir)) {
        this._fs.mkdirSync(this._tempDir);
      }
    }
  }

  public print(uniqueEntityName: string, controller: BbitController, docId: string) {
    switch (uniqueEntityName) {
      case 'social-report-living':
        let title = controller.getDisplayValue(`${docId}.person`, {}).data;
        title += ', ' + controller.getDisplayValue(`${docId}.date`, {}).data;
        this._print(`<h3>Sozialbericht</h3><h4>${title}</h4>${this._renderQuill(controller.getData(`${docId}.report`))}`, `Sozialbericht ${title}`, { preventAngularRendering: true });
    }
  }

  protected _print(template: string, filename: string, properties: any = {}, additionalStyles: string = '') {

    const self = this;

    if (Utils.isElectronApp()) {

      const styles = this._defaultStyles + ' ' + additionalStyles;
      let toPrint = this._printPage.replace(/<__REPLACE_STYLES__><\/__REPLACE_STYLES__>/g, `<style>${styles}</style>`);

      let body = '';
      let component;
      if (properties && properties.preventAngularRendering) {
        body = template;
      } else {
        component = this._renderTemplate(template, properties);
        let body = component.location.nativeElement.innerHTML;
      }
      toPrint = toPrint.replace(/<__REPLACE_BODY__><\/__REPLACE_BODY__>/g, body);

      const remote = window.require('electron').remote;

      let data = `data:text/html,${toPrint}`;

      const render = new remote.BrowserWindow({
        width: 0,
        height: 0,
        titleBarStyle: 'hidden-inset',
      });
      render.loadURL(data);
      render.show();
      render.webContents.on('did-finish-load', function () {
        render.webContents.printToPDF({}, (err, data) => {
          render.close();
          if (err) {
            self._log.error(err);
            self._notify.showNotification({
              type: 'error',
              text: 'Fehler beim Erstellen des PDFs'
            });
            self._destoryComponent(component);
            return;
          }
          // TODO windows path resolve?
          const file = self._tempDir + '/' + filename + '.pdf';
          self._fs.writeFile(file, data, (err) => {
            if (err) {
              self._log.error(err);
              self._notify.showNotification({
                type: 'error',
                text: 'Fehler beim Erstellen des PDFs'
              });
              self._destoryComponent(component);
              return;
            }
            self._shell.openItem(file);
            self._destoryComponent(component);
          });
        });
      });
    } else {
      // TODO translate
      self._notify.showNotification({
        type: 'error',
        text: 'Drucken im Browser wird zur Zeit nicht unterstützt'
      });
    }
  }

  private _destoryComponent(component: ComponentRef<any>) {
    if (component) {
      component.destroy();
    }
  }

  private _arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private _renderTemplate(template: string, properties: any = {}): ComponentRef<any> {

    @Component({ template })
    class TemplateComponent { }

    @NgModule({
      declarations: [TemplateComponent],
      imports: [
        BrowserModule
      ]
    })
    class TemplateModule { }

    const mod = this._compiler.compileModuleAndAllComponentsSync(TemplateModule);
    const factory = mod.componentFactories.find((comp) =>
      comp.componentType === TemplateComponent
    );
    const component = this.printArea.createComponent(factory);
    Object.assign(component.instance, properties);
    component.changeDetectorRef.detectChanges();

    return component;
  }


  private _renderQuill(q) {
    this.quillHelper.setContents(q);
    let html = this.quillHelper.root.innerHTML;
    return html.replace(/[\u200B-\u200D\uFEFF\u200E\u200F]/g, '');
    // return "<h1>todo</h1>";
  }

}