import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { BbitAuthService, BbitSettingsService, CognitoSession, ISecurityObject } from '@bbit/core';
import { BbitNotificationService } from '../notification/notification.service';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { BbitLog } from '@bbit/log';

@Component({
  templateUrl: 'session.component.pug'
})
export class BbitSessionComponent implements IBbitTabController {

  private _log = BbitLog.scope({
    package: 'BbitSessionComponent'
  });

  tab: IBbitTabInterface = null; // warning: still externaly accessed, ToDo: refactor such shit
  isActive: boolean = false;
  toolbar: any;
  attributes: any = {};
  session: CognitoSession;
  phoneConfirmationCode: string;
  emailConfirmationCode: string;
  loaded: boolean = false;
  mfa: boolean = false;
  mfaChanged: boolean = false;
  adminEdit: boolean = false;
  username: string;
  isNew: boolean = false;
  validation: any = [];
  permissions: ISecurityObject[];
  settings: any;
  sudoPassword: string;

  constructor(
    private _zone: NgZone,
    protected _tabService: BbitTabService,
    protected _settingsService: BbitSettingsService,
    protected _cdr: ChangeDetectorRef,
    protected _authService: BbitAuthService,
    protected _notify: BbitNotificationService) {
    this.session = <CognitoSession>this._authService.getCurrentSession();
  }

  refresh() {
    const self = this;
    self._zone.run(() => {

      self.permissions = self.session.getPermissions().toArray();
      self.settings = self._settingsService.listSettings().toArray().map(element => element.toJS()).map(element => { element.value = JSON.stringify(element.value, null, 3); return element; });


      self.loaded = true;
      return Promise.resolve();
    });
  }

  isSuperUser() {
    return this._authService.getCurrentSession().hasWritePermission('superuser');
  }

  _checkSudo(event) {

    switch (event.key) {
      case 'Escape':
        this.sudoPassword = '';
        event.preventDefault();
        break;
      case 'Enter':
        this._authService.getCurrentSession().sudo(this.sudoPassword);
        this.sudoPassword = '';
        event.preventDefault();

        if (this.isSuperUser()) {
          this._notify.showNotification({
            type: 'success',
            text: 'SuDo activated',
            detail: 'Reign with care, dude!'
          });
        }
        else {
          this._notify.showNotification({
            type: 'error',
            text: 'Wrong password',
            detail: 'SuDo is disabled'
          });
        }
        break;
    }
  }

  _disableSudo() {
    this._authService.getCurrentSession().sudo(null);
    this._notify.showNotification({
      type: 'success',
      text: 'SuDo disabled',
      detail: 'You\'re normal again!'
    });
  }

  injectParams(tab) {

    let buttons: Array<any> = [];
    this.tab = tab;
    this.tab.text = { value: { key: 'session', n: 1 } };
    this.tab.icon = 'vpn_key';

    buttons.push({
      icon: 'mdi:logout',
      action: 'LOGOUT'
    });

    this.toolbar = {
      icon: 'vpn_key',
      title: this.tab.text,
      buttons: buttons
    };

    this.refresh();

  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(event) {
    // if (_.isPlainObject(event.action)) {
    //   this._tabService.handleMessage(null, event.action);
    // }
    // else {
    switch (event.action) {
      case 'LOGOUT':
        this.session.logout();
        break;
      default:
        this._log.error('unknown action ' + event.action);
        return;
    }
  }
  // }

  updateTitle() {

  }
}