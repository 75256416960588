
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BbitCoreModule } from '@bbit/core';
import { AgGridModule } from 'ag-grid-angular';
import { BbitButtonModule } from '../button/button.module';
import { BbitCardModule } from '../card/card.module';
import { BbitIconModule } from '../icon/icon.module';
import { BbitInputCheckboxModule } from '../input-checkbox/input-checkbox.module';
import { BbitInputTextModule } from '../input-text/input-text.module';
import { BbitNotificationModule } from '../notification/notification.module';
import { BbitTabsModule } from '../tabs/tab.module';
import { BbitUserListComponent } from './user-list.component';
import { BbitUserComponent } from './user.component';


@NgModule({
    declarations: [
        BbitUserComponent,
        BbitUserListComponent
        ],
    exports: [
        BbitUserComponent,
        BbitUserListComponent
        ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        BbitCoreModule,
        BbitTabsModule,
        BbitNotificationModule,
        BbitButtonModule,
        BbitInputTextModule,
        BbitCardModule,
        FlexLayoutModule,
        BbitIconModule,
        BbitInputCheckboxModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
    ],
    bootstrap: [
        BbitUserComponent,
        BbitUserListComponent
        ]
})
export class BbitUserModule {}