import { BbitStyleService, BbitColor } from './../style/style.service';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BbitAppStateService, BbitAuthService, BbitMgmtApiService, BbitSettingsService } from '@bbit/core';
import * as _ from 'lodash';
import { BbitTabService } from '../tabs/tab.service';
import { BbitNotificationService } from './../notification/notification.service';
import { BbitI18n } from '@bbit/i18n';
import { BbitLog } from '@bbit/log';


@Component({
  selector: 'bbit-side-navigation',
  styleUrls: ['side-navigation.component.scss'],
  templateUrl: 'side-navigation.component.pug',
})
export class BbitSideNavigation implements OnInit, OnDestroy {

  @Input() width: number;

  public _modules: Array<any>;
  private _subscription: any;
  private _log = BbitLog.scope({
    package: 'BbitSideNavigation'
  });

  constructor(
    private _authService: BbitAuthService,
    private _mgmtApiService: BbitMgmtApiService,
    private _settingsService: BbitSettingsService,
    private _notify: BbitNotificationService,
    public tabService: BbitTabService,
    public _appState: BbitAppStateService,
    private _cdr: ChangeDetectorRef,
    private _style: BbitStyleService
  ) {
    const self = this;

    this._subscription = this._settingsService.getSettingChangedObservable().subscribe(() => {
      self.ngOnInit();
    });
  }

  getVersion() {
    return this._mgmtApiService.getAppVersion();
  }

  ngOnInit(): void {
    const self = this;
    const defaultColors = this._style.getDefaults();
    self.ensureSettingsModule();
    console.log('loading modules...', self._modules);
    this._settingsService.getModules(this._authService.getCurrentSession(), 'desktop').then(
      (result) => {
        _.map(result.data, (o) => {
          let color = defaultColors.secondaryColor;
          if (o.desktopColor) {
            color = new BbitColor(o.desktopColor);
          }
          o.hover = false;
          o.color = color.toString();
        });
        self._modules = result.data;
        console.log('modules loaded', self._modules);

        self.ensureSettingsModule();
        self._cdr.markForCheck();
      },
      (error) => {
        this._log.error('Error while loading modules', error);
        this.ensureSettingsModule();
        self._cdr.markForCheck();
      });
  }

  ensureSettingsModule() {
    if (!this._modules) this._modules = [];

    const settingsModule = _.find(this._modules, modul => modul && modul.viewName === 'SettingsComponent');

    if (!settingsModule) {
      this._modules.push({
        name: { key: 'settings' },
        uniqueEntityName: 'component',
        viewName: 'SettingsComponent',
        icon: 'svg:settings',
        hover: false,
      });
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }

  getModuleTabs(module) {
    // uniqueEntityName
    return _.filter(this.tabService.getTabs(), { uniqueEntityName: module.uniqueEntityName });
  }

  getTooltip(module) {
    if (this.width === 44) {
      return BbitI18n.t(module.name);
    }
    return null;
  }


  private _openModule(module: any) {
    try {
      let error = this.tabService.create(module);
      if (error) this._log.error(error);
    }
    catch (err) {
      this._log.error('Uncaught error in openModule', err);
      this._notify.showNotification({
        type: 'error',
        text: 'Error while opening tab'
      });
    }
  }
}