import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule, MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { BbitInputCheckboxComponent } from './input-checkbox.component';
import { BbitI18nModule } from '@bbit/i18n';

@NgModule({
    imports: [
        FormsModule,
        MatCheckboxModule,
        BrowserModule,
        BbitCoreModule,
        BbitI18nModule,
        BbitHotkeyModule,
        MatTooltipModule
    ],
    exports: [
        BbitInputCheckboxComponent
    ],
    declarations: [
        BbitInputCheckboxComponent
    ]
})
export class BbitInputCheckboxModule {}
