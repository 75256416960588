
import { BbitHotkeyService } from './hotkey.service';
import { Directive, ElementRef, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[hotkey]',
  host: {
    '[style.position]': '\'relative\'',
  }
})
export class BbitHotkeyDirective implements OnDestroy {

  @Input() hotkeyTopOffset: string;
  @Input() hotkeyLeftOffset: string;
  // @Input() hotkeyPrefix: string;
  @Input() hotkeyCallback: any;

  private _child: any;
  private _enabledSubscription: any;

  constructor(
    private _element: ElementRef,
    private _service: BbitHotkeyService,
    private _vc: ViewContainerRef) { }

  ngAfterViewInit() {

    this._child = document.createElement('div');
    this._child.className = 'bbit-hotkey-trigger';
    this._child.textContent = '-';
    this._child.style.display = 'none';
    if (this.hotkeyLeftOffset) {
      this._child.style.left = this.hotkeyLeftOffset;
    }
    if (this.hotkeyTopOffset) {
      this._child.style.top = this.hotkeyTopOffset;
    }
    this._element.nativeElement.append(this._child);

    this._enabledSubscription = this._service.enabledEmitter.subscribe((enabled) => {
      if (enabled) {
        this._child.style.display = 'block';
      }
      else {
        this._child.style.display = 'none';
      }
    });

    this._service.registerElement({
      element: this._element,
      prefix: '-', // this.hotkeyPrefix,
      callback: this.hotkeyCallback,
      topOffset: this.hotkeyTopOffset,
      leftOffset: this.hotkeyLeftOffset,
      keyCallback: this.setKey.bind(this)
    });

  }

  ngOnDestroy() {
    this._service.unregisterElement(this._element);
    this._enabledSubscription.unsubscribe();
  }

  setKey(key: string) {
    this._child.textContent = key;
  }
}