
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitToolbarModule } from './../../modules/toolbar/toolbar.module';
import { BbitTabContentAnchor } from './tab-content-anchor.component';
import { BbitTabContent } from './tab-content.component';
import { BbitTabService } from './tab.service';

@NgModule({
    imports: [
        BrowserModule,
        BbitCoreModule,
        BbitToolbarModule
    ],
    exports: [
        BbitTabContent,
        BbitTabContentAnchor
    ],
    declarations: [
        BbitTabContent,
        BbitTabContentAnchor
    ],
    providers: [BbitTabService]
})
export class BbitTabsModule {}
