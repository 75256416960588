import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule, MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BbitCoreModule } from '@bbit/core';
import { BbitButtonModule } from '../button/button.module';
import { BbitIconModule } from '../icon/icon.module';
import { BbitInputCheckboxModule } from '../input-checkbox/input-checkbox.module';
import { BbitNotificationModule } from '../notification/notification.module';
import { BbitTabsModule } from '../tabs/tab.module';
import { BbitPermissionGridComponent } from './permission-grid.component';


@NgModule({
  declarations: [
    BbitPermissionGridComponent,
  ],
  exports: [
    BbitPermissionGridComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    BbitCoreModule,
    BbitTabsModule,
    BbitNotificationModule,
    BbitButtonModule,
    FlexLayoutModule,
    BbitIconModule,
    BbitInputCheckboxModule,
    MatTooltipModule
  ],
  bootstrap: [
    BbitPermissionGridComponent
  ]
})
export class BbitPermissionModule { }