import { BbitTabsModule } from './../tabs/tab.module';
import { CacheComponent } from './cache.component';
import { CacheService } from './cache.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  imports: [
    BrowserModule,
    BbitTabsModule
  ],
  exports: [
    CacheComponent
  ],
  declarations: [
    CacheComponent
  ],
  bootstrap: [
    CacheComponent
  ],
  providers: [
    CacheService
  ],
  schemas: [
  ]
})
export class CacheModule { }