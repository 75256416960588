import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitMessagingModule } from '@bbit/core';
import { BbitNotificationModule } from './../notification/notification.module';
import { BbitDownloadService } from './download.service';


@NgModule({
  imports: [
    BrowserModule,
    BbitNotificationModule,
    BbitMessagingModule
  ],
  exports: [
  ],
  declarations: [
  ],
  bootstrap: [
  ],
  providers: [
    BbitDownloadService
  ],
  schemas: [
  ]
})
export class BbitDownloadModule { }