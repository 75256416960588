
import { BbitNotificationService } from './notification.service';
import { IBbitNotificationInterface } from './interfaces';
import { Component, Input, ViewChild } from '@angular/core';
import 'jquery';

@Component({
  selector: 'bbit-notification',
  templateUrl: 'notification.component.pug',
  styleUrls: ['notification.component.scss']
})
export class BbitNotificationComponent {

  @Input() notification: IBbitNotificationInterface;
  @ViewChild('notificationAnchor') notificationAnchor;

  constructor(public _notify: BbitNotificationService) {

  }

  ngOnInit() {
    $(this.notificationAnchor.nativeElement).hover(
      this._notify.pauseNotificationTimer.bind(this._notify),
      this._notify.resumeNotificationTimer.bind(this._notify)
    );
  }


}