import { BbitNotificationService } from './notification.service';
import { BbitDefaultDialogComponent } from './default-dialog.component';
import { BbitNotificationAreaComponent } from './notification-area.component';
import { BbitNotificationComponent } from './notification.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitIconModule } from '../icon/icon.module';
import { BbitButtonModule } from '../button/button.module';
import { BbitCoreModule } from '@bbit/core';
import { MatDialogModule } from '@angular/material';
import { BbitI18nModule } from '@bbit/i18n';


@NgModule({
  imports: [
    BrowserModule,
    BbitIconModule,
    BbitCoreModule,
    BbitButtonModule,
    MatDialogModule,
    BbitI18nModule
  ],
  declarations: [
    BbitNotificationAreaComponent,
    BbitNotificationComponent,
    BbitDefaultDialogComponent
  ],
  exports: [
    BbitNotificationAreaComponent,
    BbitNotificationComponent,
    BbitDefaultDialogComponent
  ],
  bootstrap: [
    BbitNotificationAreaComponent,
    BbitNotificationComponent,
    BbitDefaultDialogComponent
  ],
  providers: [
    BbitNotificationService
  ]
})
export class BbitNotificationModule { }
