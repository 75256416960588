import { CacheService, CacheDocument } from './cache.service';
import { BbitTabService } from './../tabs/tab.service';
import { IBbitTabController, IBbitTabInterface } from './../tabs/interfaces';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'bbit-cache',
  templateUrl: 'cache.component.pug',
  styleUrls: ['cache.component.scss']
})
export class CacheComponent implements IBbitTabController {

  tab: IBbitTabInterface = null;
  toolbar: any;
  isActive: boolean;
  isLoading = true;
  isErrored = false;
  data: CacheDocument[];

  constructor(
    private _tabService: BbitTabService,
    private _cache: CacheService,
  ) {
  }

  updateTitle() {
    this.tab.text = { value: 'Sync' };
  }

  injectParams(tab) {
    this.tab = tab;
    this.updateTitle();
    this.tab.icon = 'sync';
    this.toolbar = {
      icon: 'sync',
      title: 'Sync',
      buttons: [{
        icon: 'close',
        action: 'CLOSE'
      }]
    };

    this.isLoading = true;
    this.isErrored = false;

    this._cache.checkSyncedData().then((data) => {
      this.isLoading = false;
      this.data = data;
    }).catch((err) => {
      this.isLoading = false;
      this.isErrored = true;
    });

  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(event: any) {
    switch (event.action) {
      case 'CLOSE':
        this.closeTab();
        break;
    }
  }
}