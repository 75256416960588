import { Injectable } from '@angular/core';
import * as Mousetrap from 'mousetrap';
import { BbitTabService } from '../tabs/tab.service';
import { BbitHotkeyService } from './../hotkey/hotkey.service';



@Injectable()
export class BbitShortcutService {

  keyMapping = [
    {
      keys: 'mod+e',
      action: { action: 'EDIT' }
    },
    {
      keys: 'shift+mod+e',
      action: { action: 'PREVIEW' }
    },
    {
      keys: 'mod+s',
      action: { action: 'SAVE' }
    },
    {
      keys: 'mod+p',
      action: { action: 'PRINT' }
    },
    {
      keys: 'shift+mod+d',
      action: { action: 'DELETE' }
    },
    {
      keys: 'mod+w',
      action: { action: 'CLOSE-CURRENT-TAB' }
    },
    {
      keys: 'mod+n',
      action: { action: 'NEW' }
    }
  ];

  constructor(
    private _hotkeyService: BbitHotkeyService,
    private _tabService: BbitTabService
  ) {

    Mousetrap.prototype.stopCallback = function () {
      return false;
    };

    Mousetrap.bind('mod+g', (e) => {
      this._hotkeyService.enableHotkeys(false);
      e.preventDefault();
    });

    Mousetrap.bind('mod+shift+g', (e) => {
      this._hotkeyService.enableHotkeys(true);
      e.preventDefault();
    });

    for (let key of this.keyMapping) {
      Mousetrap.bind(key.keys, (e) => {
        this._tabService.sendActionToCurrentTab(key.action);
        e.preventDefault();
      });
    }

  }

  getShortcutText(action: string) {

    for (let key of this.keyMapping) {

      if (key.action && key.action.action && key.action.action === action) {
        // TODO implement windows check
        let text = key.keys.toUpperCase();
        text = text.replace(/ /g, '');
        text = text.replace(/COMMAND\+?|MOD\+?|META\+?|CTRL\+?/g, '⌘');
        text = text.replace(/OPTION\+?|ALT\+?/g, '⌥');
        text = text.replace(/SHIFT\+?/g, '⇧');
        text = text.replace(/ESC\+?/g, '⎋');
        text = text.replace(/CAPSLOCK\+?/g, '⇪');
        text = text.replace(/ENTER\+?|RETURN\+?/g, '⏎');
        text = text.replace(/BACKSPACE\+?/g, '⌫');
        return text;
      }
    }

    return '';

  }

}