import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitAuthModule, BbitFileModule } from '@bbit/core';
import { BbitNotificationModule } from './../notification/notification.module';
import { BbitFilePreviewComponent } from './file-preview.component';



@NgModule({
  imports: [
    BrowserModule,
    BbitAuthModule,
    BbitNotificationModule,
    BbitFileModule
  ],
  exports: [
    BbitFilePreviewComponent
  ],
  declarations: [
    BbitFilePreviewComponent
  ],
  bootstrap: [
    BbitFilePreviewComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class BbitFilePreviewModule { }