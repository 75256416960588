import { CacheService } from './../cache/cache.service';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, ElementRef } from '@angular/core';
import { BbitAuthService, BbitController, BbitSelectService, Utils, BbitMessagingService, BbitAction, IBbitEvent } from '@bbit/core';
import * as interact from 'interactjs';
import * as _ from 'lodash';
import { BbitNotificationService } from '../notification/notification.service';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { Database, DatabaseDocument } from './../pouchdb-ui/interfaces';
import { BbitPrintService } from './../print/print.service';
import { BbitStyleService, BbitColor } from '../style/style.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { BbitI18n } from '@bbit/i18n';
import { BbitLog } from '@bbit/log';

@Component({
  templateUrl: 'doc-controller.component.pug',
  styleUrls: [
    'doc-controller.component.scss'
  ]
})
export class BbitUiDocControllerComponent implements IBbitTabController, OnDestroy {

  private _log = BbitLog.scope({
    package: 'BbitUiDocControllerComponent'
  });

  deleteDocument = false;

  idWatcher: any;
  currentDocId: string;
  controller: BbitController = null;
  tab: IBbitTabInterface;
  isActive: boolean = false;
  mqttDocNotifySubscription: Subscription;

  previewWidth: number = 400;
  previews: {
    title: string;
    docId: string;
    controller: BbitController;
  }[] = [];
  activePreview: {
    title: string;
    docId: string;
    controller: BbitController;
  };

  protected uiSchema: any;
  public toolbar: any = {};



  constructor(
    private _selectService: BbitSelectService,
    protected _tabService: BbitTabService,
    protected _cdr: ChangeDetectorRef,
    private _notify: BbitNotificationService,
    private _printService: BbitPrintService,
    private _authService: BbitAuthService,
    private _elementRef: ElementRef,
    private _style: BbitStyleService,
    private _eventStream: BbitMessagingService,
    private _cache: CacheService
  ) { }

  hasWritePermissions() {
    const schema = this.controller ? this.controller.getSchema() : null;

    if (schema) return schema.hasWritePermission(this.controller.getSession());

    return false;
  }

  ngOnDestroy() {

    this._eventStream.unsubscribeMqttTopic(
      BbitAction.DOC_NOTIFY,
      this.controller.getSession().getOrganisation().key,
      this.controller.getSchema().getUniqueEntityName(),
      'main',
      this.currentDocId);

    if (this.mqttDocNotifySubscription && this.mqttDocNotifySubscription.unsubscribe) {
      this.mqttDocNotifySubscription.unsubscribe();
    }

    if (this.idWatcher && this.idWatcher.unsubscribe) {
      this.idWatcher.unsubscribe();
    }

    const self = this;
    let docId = self.currentDocId;

    setTimeout(() => {
      if (self.controller && docId) {
        if (self.controller.isNew(docId)) {
          self.currentDocId = null;
          // self._cdr.detectChanges();
          self.controller.discardRows(docId);
        }
      }
    }, 100);
  }

  /////////////////////////////  Begin implementation of IBbitTabController ////////////////////////////////////

  injectParams(tab) {

    // store Reference
    this.tab = tab;

    // Loading icon
    this.tab.text = { value: { key: 'loading' } };
    this.tab.icon = '';

    this.controller = this.tab.controller || new BbitController();


    let error = this.controller.setup({
      uniqueEntityName: this.tab.uniqueEntityName,
      schemaCompilation: this._selectService.getSchemaService().getSchemaCompilation(this._selectService.getAuthService().getCurrentSession()),
      selectService: this._selectService,
      sessionRef: this._selectService.getAuthService().getCurrentSession(),
      onExternalRowUpdate: 'merge',
      onExternalRowInsert: 'append',
      onExternalRowDelete: 'remove'
    });

    this.toolbar = {
      title: { key: 'loading' },
      icon: '',
      buttons: _.compact([this.hasWritePermissions() ?
        {
          icon: 'more_vert',
          tooltip: { key: 'more', n: 1 },
          menu: _.compact([
            this.tab.uniqueEntityName === 'social-report-living' ? {
              icon: 'print',
              action: 'PRINT',
              text: { key: 'print', n: 1 }
            } : null,
            {
              icon: 'sync',
              action: 'SYNC',
              text: { key: 'sync-document', n: 1 }
            },
            {
              icon: 'delete',
              action: 'DELETE',
              text: { key: 'delete', n: 1 }
            }
          ])
        } : null, this.hasWritePermissions() ? {
          icon: 'save',
          action: 'SAVE',
          tooltip: { key: 'save', n: 1 }
        } : null,
      {
        icon: 'close',
        tooltip: { key: 'close', n: 1 },
        action: 'CLOSE-TAB'
      }])
    };

    if (this.controller.getSchema().getDesktopColor()) {
      this._style.applyStyles({
        secondaryColor: new BbitColor(this.controller.getSchema().getDesktopColor())
      }, this._elementRef.nativeElement);
    }

    const newUiSchema = this.controller.getSchema().getUiSchema('desktop', this.tab.viewName);
    if (!newUiSchema) return this._log.error('error getting uiSchema for view ' + this.tab.viewName);


    this.uiSchema = newUiSchema;

    this.tab.text = this.uiSchema.title;
    this.tab.icon = this.uiSchema.icon;

    if (this.uiSchema.toolbar) {
      this.toolbar.icon = this.uiSchema.toolbar.icon;
      this.toolbar.title = this.uiSchema.toolbar.title;
      // ToDo: merge buttons
    }

    this.idWatcher = this.controller.getObservableStream('idChanged').subscribe((result) => {
      this._log.info('rest', result, result.data.oldId === this.currentDocId);
      if (result.data.oldId === this.currentDocId) {
        this.currentDocId = result.data.newId;
      }
    });

    switch (this.tab.params.action) {
      case 'OPEN':
        this.controller.retrieveOneById(this.tab.params.entityId).then(() => {

          this.currentDocId = this.controller.getDocumentId('[0]');
          this.controller.validateDoc(this.currentDocId);

          this.updateTitle();
          this.subscribeMqtt();
          this._cdr.markForCheck();
        });
        break;
      case 'NEW':
        this.controller.createNew(this.tab.params.entityId).then(() => {
          this.currentDocId = this.controller.getDocumentId('[0]');
          this.controller.validateDoc(this.currentDocId);
          this.updateTitle();
          this.subscribeMqtt();
          this._cdr.markForCheck();
        });
        break;
      default:
        this._log.error('unknown action on tab.params');
    }


    this._cdr.markForCheck();
  }

  subscribeMqtt() {
    // subscribe for mqtt notifications
    this._eventStream.subscribeMqttTopic(
      BbitAction.DOC_NOTIFY,
      this.controller.getSession().getOrganisation().key,
      this.controller.getSchema().getUniqueEntityName(),
      'main',
      this.currentDocId);


    this.mqttDocNotifySubscription = this._eventStream.observeTopic(
      this.controller.getSession(),
      BbitAction.DOC_NOTIFY,
      this.controller.getSession().getOrganisation().key,
      this.controller.getSchema().getUniqueEntityName(),
      'main',
      this.currentDocId).subscribe((event: IBbitEvent<any>) => {
        this._notify.showNotification({
          type: event.payload.type ? event.payload.type : 'info',
          text: BbitI18n.t(event.payload.text),
          detail: BbitI18n.t(event.payload.detail)
        });
      });
  }


  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    const self = this;

    if (!this.deleteDocument && this.controller.isModified(this.currentDocId)) {
      return this._notify.showDefaultDialog({
        title: { key: 'caution', n: 1 },
        message: 'Datensatz speichern?', // { key: "person-modified-message", n: 1 },
        buttons: [{
          text: { key: 'yes' },
          returnValue: 'YES'
        }, {
          text: { key: 'no' },
          returnValue: 'NO'
        }, {
          text: { key: 'cancel' },
          returnValue: 'CANCEL'
        }]
      }).then((result) => {
        switch (result.data) {
          case 'YES':
            return this.save().then(() => {
              return Promise.resolve(true);
            }).catch(() => {
              return Promise.resolve(false);
            });
          case 'NO':
            return Promise.resolve(true);

          case 'CANCEL':
            return Promise.resolve(false);
        }
      });
    } else {
      return Promise.resolve(true);
    }

  }

  openPreview(uniqueEntityName: string, id: string, reset: boolean) {

    if (this.activePreview && this.activePreview.docId === id) {
      return;
    }

    const self = this;
    let controller = self._selectService.getCacheControllerWithLoadedId(this._authService.getCurrentSession(), uniqueEntityName, id);
    if (Utils.isPromise(controller)) {
      controller.then(() => {
        controller = self._selectService.getCacheControllerWithLoadedId(this._authService.getCurrentSession(), uniqueEntityName, id);
        this._doOpenPreview(<BbitController>controller, id, reset);
      });

    } else {
      this._doOpenPreview(controller, id, reset);
    }

  }

  _doOpenPreview(controller: BbitController, id: string, reset: boolean) {
    const self = this;
    if (controller.getSchema().hasReadPermission(this._authService.getCurrentSession()) && controller.getSchema().hasPreviewView('desktop')) {

      if (reset) {
        self.previews = [];
      }

      if (this.activePreview && this.activePreview.docId === id) {
        return;
      } else {
        const preview = {
          title: BbitI18n.t(controller.evaluate(controller.getSchema().getUiSchema('desktop', 'preview').title, {}, id).data),
          controller: controller,
          docId: id,
        };
        self.previews.push(preview);
        self.activePreview = preview;
      }
    }
  }

  previewAttached(element) {
    interact(element)
      .resizable({
        // resize from all edges and corners
        edges: { left: true, right: false, bottom: false, top: false },

        onmove: (event: any) => {

          let width = event.rect.width;
          // element.offsetWidth = width;
          this.previewWidth = width;
          // this.sideNavWidth = width;
          // event.target.style.flex = `0 0 ${width}px`;

        },

        onend: (event) => {
          // TODO implement save
          // self.saveEvent(event.target.getAttribute("data-id"));
        }

      }
      );
  }

  closePreview() {
    this.previews = [];
    this.activePreview = null;
  }

  selectPreview(index: number) {
    this.activePreview = this.previews[index];
    this.previews.length = index + 1;
  }

  openDetail(rowId: string = null, uniqueEntityName: string = null) {

    let params: any = {};

    if (rowId) {
      params.action = 'OPEN';
      params.entityId = rowId;
    } else {
      params.action = 'NEW';
    }

    this._tabService.create({
      uniqueEntityName: uniqueEntityName || this.tab.uniqueEntityName,
      viewName: this.uiSchema.detailViewName || 'detail',
      params: params
    });
  }

  handleAction(event) {

    try {
      if (!event.jsonPath) event.jsonPath = this.currentDocId;

      switch (event.action) {
        case 'EDIT':
          if (this.activePreview) {
            this.openDetail(this.activePreview.docId, this.activePreview.controller.getSchema().getUniqueEntityName());
          }
          break;
        case 'CLOSE-TAB':
          this.closeTab();
          break;
        case 'SAVE':
          this.save();
          break;
        case 'DELETE':
          this.askForDelete();
          break;
        case 'PRINT':
          this._printService.print(this.tab.uniqueEntityName, this.controller, this.currentDocId);
          break;
        case 'OPEN-PREVIEW':
          this.openPreview(event.params.uniqueEntityName, event.params.id, false);
          break;
        case 'CLOSE-PREVIEW':
          this.closePreview();
          break;
        case 'SYNC':
          if (!this.controller.isModified(this.currentDocId)) {
            this.controller.getSelectService().request('SYNC', this.controller.getSchema().getUniqueEntityName(), {}, this.controller.getSession()).then(() => {
              // TODO translate
              this._notify.showNotification({
                type: 'success',
                text: 'Dokument erfolgreich synchronisiert',
              });
            }).catch((err) => {
              // TODO translate
              this._notify.showNotification({
                type: 'error',
                text: 'Fehler beim Synchronisieren',
                detail: Utils.stringifyIBbitRequestResult(err)
              });
            });
          } else {
            // TODO translate
            this._notify.showNotification({
              type: 'error',
              text: 'Fehler beim Synchronisieren',
              detail: 'Das Dokument muss zuerst gespeichert werden'
            });
          }
          break;
        default:
          this.controller.handleAction(event.jsonPath, event.action, event.params || { jsonPath: this.currentDocId }, false).then(result => {
            // this.action.emit(result.data);
          }, error => {
            this._log.error('handleAction Error', error, JSON.stringify(event), event);
          });
          break;
      }
    } catch (err) {
      this._log.error('Error on handleAction of Component', err);
    }
  }

  /////////////////////////////  End implementation of IBbitTabController ////////////////////////////////////

  updateTitle() {
    if (this.currentDocId) {
      if (this.controller.isNew(this.currentDocId)) {
        this.tab.text = { value: { key: 'new-record', n: 1 } };
        this.toolbar.title = { key: 'new-record', n: 1 };
      } else {
        // let name = this.controller.get(this.currentDocId + ".fullname()");
        let name = Utils.stringifyIBbitRequestResult(this.controller.evaluate(this.uiSchema.title, {}, this.currentDocId));
        this.tab.text = { value: name };
        this.toolbar.title = name;
      }
    }
  }

  save(): Promise<boolean> {
    if (!this.hasWritePermissions()) {
      return Promise.resolve(false);
    }
    return this.controller.save('[0]').then(() => {
      return this._cache.save(this.controller.getSchema().getUniqueEntityName(), this.controller.getData('[0]')).then(() => {
        this.updateTitle();
        // TODO translate
        this._notify.showNotification({
          type: 'success',
          text: 'Speichern erfolgreich',
          detail: 'Revision: ' + this.controller.getDocumentRevision('[0]')
        });
        return Promise.resolve(true);
      });
      // save to local cache
    }).catch((err) => {
      this._log.error('Error on save', err);
      this._notify.showNotification({
        type: 'error',
        text: 'Fehler beim Speichern',
        detail: Utils.stringifyIBbitRequestResult(err)
      });
      return Promise.resolve(false);
    });
  }

  askForDelete() {

    this._notify.showDefaultDialog({
      title: { key: 'caution', n: 1 },
      message: { key: 'delete-message', n: 1 },
      buttons: [{
        color: 'warn',
        text: { key: 'delete' },
        returnValue: 'DELETE'
      }, {
        text: { key: 'cancel' },
        returnValue: 'CANCEL'
      }]
    }).then((result) => {
      if (result.data === 'DELETE') {
        this.controller.delete('[0]').then(() => {
          this.deleteDocument = true;
          this.closeTab();
        });
      }
    });

  }

  private _getPouchDBUiParams(): { database: Database, document: DatabaseDocument } {
    // TODO centralize this code
    const bucket = 'main';
    const endpoint = 'bbit-cloud';
    const uniqueEntityName = this.controller.getSchema().getUniqueEntityName();
    const organization = this.controller.getSession().getOrganisation().key;
    const dbName = `${organization}+${uniqueEntityName}+${bucket}`;
    const endpointUrl = this.controller.getSession().getMgmtApiService().getDatabaseEndpoints()[endpoint];
    const database: Database = {
      database: dbName,
      endpoint: endpoint,
      endpointUrl: endpointUrl,
      uniqueEntityName: uniqueEntityName,
      organization: organization,
      bucket: bucket
    };
    const doc: DatabaseDocument = {
      id: this.controller.getDocumentId(this.currentDocId),
      rev: this.controller.getDocumentRevision(this.currentDocId)
    };

    return {
      database: database,
      document: doc
    };
  }

  showChanges() {
    this._tabService.create({
      uniqueEntityName: 'component',
      viewName: 'DocumentDiffComponent',
      params: this._getPouchDBUiParams()
    });
  }

  showJson() {
    this._tabService.create({
      uniqueEntityName: 'component',
      viewName: 'DocumentDetailComponent',
      params: this._getPouchDBUiParams()
    });
  }
}
