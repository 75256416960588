import { IBbitI18n } from '@bbit/i18n';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bbit-input-password',
  templateUrl: 'input-password.component.pug',
  styleUrls: ['input-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitInputPasswordComponent {

  passwordSet: boolean = false;
  newPassword: string;
  newPasswordValid: boolean;
  newPasswordError: IBbitI18n;
  confirmPassword: string;
  confirmPasswordError: IBbitI18n;
  passwordValidationMessages: any[] = [];

  @Input() disabled: boolean = false;

  @Output() passwordChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  reset() {
    this.newPassword = '';
    this.newPasswordValid = false;
    this.newPasswordError = null;
    this.confirmPassword = '';
    this.confirmPasswordError = null;
    this.passwordSet = false;
  }

  private _onNewPasswordChanged($event) {

    let valid: boolean = true;
    this.passwordValidationMessages = [];

    this.newPassword = $event;

    if (!this.newPassword) {
      this.newPasswordValid = false;
      this.newPasswordError = null;
      return;
    }

    if (this.newPassword.length < 8) {
      this.passwordValidationMessages.push({ key: 'password-character-count', values: ['8'], n: 8 });
      valid = false;
    }

    if (!this.newPassword.match(/[a-z]+/)) {
      this.passwordValidationMessages.push({ key: 'password-lowercase-count', values: ['1'], n: 1 });
      valid = false;
    }

    if (!this.newPassword.match(/[A-Z]+/)) {
      this.passwordValidationMessages.push({ key: 'password-uppercase-count', values: ['1'], n: 1 });
      valid = false;
    }

    if (!this.newPassword.match(/[0-9]+/)) {
      this.passwordValidationMessages.push({ key: 'password-number-count', values: ['1'], n: 1 });
      valid = false;
    }

    if (!this.newPassword.match(/[!@#$%^&*()_+\-=\[\]{};":"\\|,.<>\/?]+/)) {
      this.passwordValidationMessages.push({ key: 'password-special-char-count', values: ['1'], n: 1 });
      valid = false;
    }

    this.newPasswordValid = valid;
    if (!valid) {
      this.confirmPasswordError = { key: 'invalid-input' };
      this.newPasswordError = { key: 'invalid-input' };
    }
    else {
      this.confirmPassword = '';
      this.confirmPasswordError = null;
      this.newPasswordError = null;
    }

  }

  private _onConfirmPasswordChanged($event) {
    this.confirmPassword = $event;
    this.passwordValidationMessages = [];
    if (!this.confirmPassword) {
      this.confirmPasswordError = null;
      return;
    }
    if (this.confirmPassword !== this.newPassword) {
      this.confirmPasswordError = { key: 'invalid-input' };
      this.passwordValidationMessages.push({ key: 'password-match-message' });
    } else {
      this.passwordSet = true;
      this.passwordChange.emit(this.newPassword);
    }
  }


}