
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BbitAuthService, BbitFileService, IBbitFile, Utils } from '@bbit/core';
import { BbitNotificationService } from './../notification/notification.service';
import { BbitLog } from '@bbit/log';


@Component({
  selector: 'bbit-file-preview',
  templateUrl: 'file-preview.component.pug',
  styleUrls: ['file-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitFilePreviewComponent implements OnChanges {

  @Input() file: IBbitFile;

  private _log = BbitLog.scope({
    package: 'BbitFilePreviewComponent'
  });

  signedTestSrc: SafeResourceUrl;
  signedSrc: SafeResourceUrl;
  src: string;
  utils = Utils;

  constructor(
    private _auth: BbitAuthService,
    private _notify: BbitNotificationService,
    private _fileService: BbitFileService,
    private _cdr: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private _zone: NgZone
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    const self = this;
    if (changes.file.currentValue) {
      self.signedSrc = null;
      const file = changes.file.currentValue;
      self._zone.run(() => {
        self._fileService.getSignedDownloadUrl(file, self._auth.getCurrentSession()).then((result) => {
          self.src = result;
          self.signedSrc = self._sanitizer.bypassSecurityTrustResourceUrl(result);
          self._cdr.markForCheck();
        }, (err) => {
          this._log.error(err);
          self._notify.showNotification({
            type: 'error',
            text: 'Fehler beim Download',
            detail: Utils.stringifyIBbitRequestResult(err)
          });
          self.src = null;
          self.signedSrc = null;
          self._cdr.markForCheck();
        });
      });
    } else {
      self.src = null;
      self.signedSrc = null;
      self._cdr.markForCheck();
    }
  }

}