import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitGeocodeModule } from './../geocode/geocode.module';
import { BbitInputReferenceModule } from './../input-reference/input-reference.module';
import { BbitInputSelectModule } from './../input-select/input-select.module';
import { BbitInputTextModule } from './../input-text/input-text.module';
import { BbitInputAddressComponent } from './input-address.component';
import { BbitI18nModule } from '@bbit/i18n';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BbitCoreModule,
    BbitInputTextModule,
    BbitI18nModule,
    BbitInputSelectModule,
    BbitInputReferenceModule,
    BbitGeocodeModule
  ],
  exports: [
    BbitInputAddressComponent
  ],
  declarations: [
    BbitInputAddressComponent
  ]
})
export class BbitInputAddressModule { }
