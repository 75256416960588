import { BbitMessagingModule } from '@bbit/core';
import { BbitStyleService } from './style.service';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
      BbitMessagingModule
    ],
    exports: [
    ],
    providers: [
      BbitStyleService
    ],
    declarations: [

    ]
})
export class BbitStyleModule {}