import { Component, ViewContainerRef } from '@angular/core';
import { BbitPrintService } from './print.service';

/*
 *   Anchor dummy element for dynamic component loading
 *
 */
@Component({
  selector: 'bbit-print-area',
  template: '<div></div>'
})
export class BbitPrintAreaComponent {
  constructor(private _viewContainerRef: ViewContainerRef, private _printService: BbitPrintService) {
    this._printService.printArea = this._viewContainerRef;
  }
}