import { IBbitI18n } from '@bbit/i18n';


import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Input, NgZone, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BbitAuthService, BbitFileService, IBbitFile, IBbitFileState, Utils } from '@bbit/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { BbitDownloadService } from '../download/download.service';
import { BbitNotificationService } from '../notification/notification.service';
import { IBbitInputSuffix } from './../input-text/interfaces.d';
import { BbitLog } from '@bbit/log';

@Component({
  selector: 'bbit-input-file',
  templateUrl: 'input-file.component.pug',
  styleUrls: ['input-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitInputFileComponent implements OnChanges {

  @Input() file: IBbitFile;
  @Input() tooltip: string | IBbitI18n;
  @Input() label: string | IBbitI18n;
  @Input() error: string | IBbitI18n;
  @Input() warning: string | IBbitI18n;
  @Input() info: string | IBbitI18n;
  @Input() type: string | IBbitI18n;
  @Input() disabled: boolean;
  @Input() suffixes: IBbitInputSuffix[];
  @Input() bucketName: string;
  @Input() keyPrefix: string;
  @Input() bucketRegion: string;

  @Input() allowMimeTypes: string;
  @Input() maxFilesize: number;

  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();
  @Output() suffixAction: EventEmitter<any> = new EventEmitter();

  private _log = BbitLog.scope({
    package: 'BbitInputFileComponent'
  });

  _internalError: string;
  _suffixClicked: boolean = false;
  _currentFileState: IBbitFileState;
  _subscribtions: Subscription[] = [];

  @HostBinding('class.drag-active') dragActiveAccept: boolean = false;
  @HostBinding('class.drag-active-reject') dragActiveReject: boolean = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _auth: BbitAuthService,
    private _zone: NgZone,
    private _fileService: BbitFileService,
    private _notify: BbitNotificationService,
    private _dlService: BbitDownloadService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['file']) {
      if (this._currentFileState) {
        // ToDo
        return;
      }
      this._currentFileState = this._fileService.getFileState(changes['file'].currentValue, this._auth.getCurrentSession());
    }
  }

  ngOnDestroy(): void {
    this._dispose();
  }

  _dispose() {
    if (this._currentFileState) {
      if (this._subscribtions && this._subscribtions.length > 0) {
        this._subscribtions.forEach((sub) => {
          if (sub && sub.unsubscribe) sub.unsubscribe();
        });
        this._subscribtions = [];
      }

      if (this._currentFileState.cancel) {
        this._currentFileState.cancel();
      }

      this._currentFileState = null;
    }

    this.dragActiveAccept = false;
    this.dragActiveReject = false;
    this._internalError = null;
  }

  emitChanges() {
    this.file = this._currentFileState ? _.cloneDeep(this._currentFileState.file) : null;
    this._internalError = null;
    if (this._currentFileState) {
      switch (this._currentFileState.state) {
        case 'failed':
          this._internalError = this._currentFileState.internalError;
          break;
      }
    }

    this._cdr.markForCheck();
    this.fileChange.emit(this.file);
  }

  @HostListener('dragover', [
    '$event',
  ])
  public onDragOver(event: any): void {
    const self = this;
    const transfer: DataTransfer = event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;

    if (this.disabled) {
      transfer.dropEffect = 'none';
      return;
    }

    /* https://github.com/danialfarid/ng-file-upload/blob/cbbec3a6c6b0b74d1502f3a32d5c6e575360886c/demo/src/main/webapp/js/ng-file-upload.js
    https://github.com/blueimp/jQuery-File-Upload/blob/aaeb8c38e9dc87b02e672f57658c4111943ac44b/js/jquery.fileupload-image.js
    if (!this._hasFiles(transfer.types)) {
        return;
    } */

    /* ToDo: do post validation of mismatched files
    const patternMatch = FileUtils.doesPatternMatch(transfer, this.allowMimeTypes);
    if (!patternMatch) {
        transfer.dropEffect = "none";
        this._internalError = "Only " + this.allowMimeTypes + " mimetypes are allowed";
        this.dragActiveAccept = false;
        this.dragActiveReject = true;
        return;
    } */


    // handling dragover events from the Chrome download bar
    if (navigator.userAgent.indexOf('Chrome') > -1) {
      transfer.dropEffect = (transfer.effectAllowed === 'move' || transfer.effectAllowed === 'linkMove') ? 'move' : 'copy';
    }
    else {
      transfer.dropEffect = 'copy';
    }

    // prevent Default to allow drop
    event.preventDefault();
    event.stopPropagation();

    this.dragActiveAccept = true;
    this.dragActiveReject = false;
  }



  @HostListener('dragleave', [
    '$event',
  ])
  public onDragLeave(event: any): void {
    /* if (event.currentTarget === this._element[0]) {
        return;
    } */

    event.preventDefault();
    event.stopPropagation();

    this.dragActiveAccept = false;
    this.dragActiveReject = false;
    this._internalError = null;
  }

  @HostListener('drop', [
    '$event',
  ])
  public onDrop(event: any): void {
    this._newFilesAdded(this._fileService.addNewFiles(event, this._auth.getCurrentSession(), {
      bucketName: this.bucketName,
      bucketRegion: this.bucketRegion,
      keyPrefix: this.keyPrefix,
      allowDirectory: false,
      maxFiles: 1,
      maxFileSize: this.maxFilesize,
      includeDirectory: false,
      allowMultiple: false,
      replacesFile: this._currentFileState
    }));
  }

  private _newFilesAdded(addPromise) {
    const self = this;

    self._zone.run(() => {
      return addPromise.then((results) => {

        self._dispose();

        if (!results || results.length === 0 || !results[0]) {
          self._internalError = 'file-source not supported';
          return;
        }

        self._currentFileState = results[0];
        self.emitChanges();

        self._subscribtions.push(self._currentFileState.onProgress.pipe(throttleTime(100)).subscribe(() => {
          self._cdr.detectChanges();
        }));

        self._subscribtions.push(self._currentFileState.onStateChange.subscribe(() => {
          self._zone.run(() => {
            self.emitChanges();
          });
        }));

        self._subscribtions.push(self._currentFileState.onError.subscribe((err) => {
          self._zone.run(() => {
            self._dispose();
            self._notify.showNotification({
              type: 'error',
              text: 'Fehler beim Hochladen',
              detail: Utils.stringifyIBbitRequestResult(err)
            });

            self.emitChanges();
          });
        }));

      }, (err) => {
        this._log.error(err);
        self._dispose();
        self._notify.showNotification({
          type: 'error',
          text: 'Fehler beim Einlesen',
          detail: Utils.stringifyIBbitRequestResult(err)
        });

        self.emitChanges();
      });
    });
  }

  openFileDialog() {
    this._newFilesAdded(this._fileService.addNewFilesByDialog(this._auth.getCurrentSession(), {
      bucketName: this.bucketName,
      bucketRegion: this.bucketRegion,
      keyPrefix: this.keyPrefix,
      allowDirectory: false,
      maxFiles: 1,
      maxFileSize: this.maxFilesize,
      includeDirectory: false,
      allowMultiple: false,
      replacesFile: this._currentFileState
    }));
  }

  private _onBlur($event): void {
    this.blur.emit($event);
  }

  private _onFocus($event): void {
    this._suffixClicked = false;
    this.focus.emit($event);
  }

  private _onSuffixAction($event): void {
    if ($event && $event.action) {
      this._suffixClicked = true;
      this.suffixAction.emit($event);
    }
    else {
      this._suffixClicked = false;
      this._onFocus({});
    }
  }

  private _onInputClick() {
    if (this._suffixClicked) {
      this._suffixClicked = false;
      this._onFocus({});
    }
  }

  handleActionWithId($event, file) {
    const self = this;
    if ($event) {
      switch ($event.action) {
        case 'FILE-DELETE':
          this._dispose();
          self.emitChanges();
          return;
        case 'FILE-ABORT':
          this._dispose();
          self.emitChanges();
      }
    }
  }

  downloadFile(file: IBbitFile) {
    const self = this;
    // debugger;

    this._zone.run(() => {
      self._fileService.getSignedDownloadUrl(file, self._auth.getCurrentSession()).then((url) => {
        self._dlService.download(url);
      }, (err) => {
        this._log.error(err);
        self._notify.showNotification({
          type: 'error',
          text: 'Fehler beim Download',
          detail: Utils.stringifyIBbitRequestResult(err)
        });
        self._cdr.markForCheck();
      });
    });
  }


}