import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BbitCoreModule } from '@bbit/core';
import { BbitButtonModule } from '../button/button.module';
import { BbitCardModule } from '../card/card.module';
import { BbitIconModule } from '../icon/icon.module';
import { BbitInputCheckboxModule } from '../input-checkbox/input-checkbox.module';
import { BbitInputTextModule } from '../input-text/input-text.module';
import { BbitNotificationModule } from '../notification/notification.module';
import { BbitTabsModule } from '../tabs/tab.module';
import { BbitSessionComponent } from './session.component';


@NgModule({
  declarations: [
    BbitSessionComponent,
  ],
  exports: [
    BbitSessionComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    BbitCoreModule,
    BbitTabsModule,
    BbitNotificationModule,
    BbitButtonModule,
    BbitInputTextModule,
    BbitCardModule,
    FlexLayoutModule,
    BbitIconModule,
    BbitInputCheckboxModule,
    ReactiveFormsModule,
  ],
  bootstrap: [
    BbitSessionComponent,
  ]
})
export class BbitSessionModule { }