import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitIconModule } from '../icon/icon.module';
import { BbitTabsModule } from '../tabs/tab.module';
import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { BbitNotificationModule } from './../notification/notification.module';
import { BbitTopNavigation } from './top-navigation.component';
import { BbitHelperModule } from '../helper/helper.module';

@NgModule({
  imports: [
    BbitCoreModule,
    BbitIconModule,
    BrowserModule,
    FlexLayoutModule,
    BbitHotkeyModule,
    BbitIconModule,
    BbitNotificationModule,
    BbitTabsModule,
    MatTooltipModule,
    BbitHotkeyModule,
    BbitHelperModule
  ],
  exports: [
    BbitTopNavigation,
  ],
  declarations: [
    BbitTopNavigation
  ]
})
export class BbitTopNavigationModule { }
