import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BbitController, BbitUiController, IBbitUiField } from '@bbit/core';
import 'jquery';

@Component({
  selector: 'bbit-ui-section',
  templateUrl: 'section.component.pug',
  styleUrls: [
    'section.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BbitUiSectionComponent {

  @Input() controller: BbitController;
  @Input() uiController: BbitUiController;
  @Input() ui: any;

  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() sizeChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('sectionDom') sectionDom;

  isVisible: boolean = true;
  schemaFields: string[];

  _subscription: any;
  _invalidFields: string[];
  _notPlausibleFields: string[];
  _inProgressFields: string[];

  closedWidth = '200px';
  initialSize: string;
  open: boolean = true;

  constructor(
    protected _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

    let size = this.ui.styleWidth;
    if (size !== '100%') {
      this.initialSize = size;
    }

    this._invalidFields = this.ui.invalidFields || [];
    this._notPlausibleFields = this.ui.notPlausibleFields || [];
    this._inProgressFields = this.ui.inProgressFields || [];

    this._subscription = this.uiController.getUiChangedObservable().subscribe(() => {
      this._invalidFields = this.ui.invalidFields || [];
      this._notPlausibleFields = this.ui.notPlausibleFields || [];
      this._inProgressFields = this.ui.inProgressFields || [];
      this._cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._dispose();
  }

  private _dispose(): void {
    if (this._subscription) this._subscription.unsubscribe();
    this._subscription = null;
  }

  toggleOpen() {
    // debugger;
    this.open = !this.open;
    this.sizeChange.emit(this.getWidth());
  }

  getJsonPath(docId: string, basePath: string, field: IBbitUiField) {
    return docId + '.' + (basePath && basePath.length > 0 ? basePath + '.' : '') + (field ? field.jsonPath : '');
  }

  ngAfterViewInit() {

    // temp
    $(this.sectionDom.nativeElement).focusin(() => {
      if (!this.open) {
        this.open = true;
      }
    });
  }

  getWidth(): string {
    if (!this.open) {
      return this.closedWidth;
    } else {
      return this.getContentWidth();
    }
  }

  getContentWidth(): string {
    if (this.initialSize) {
      return this.initialSize;
    } else {
      return '450px';
    }
  }
}