import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BbitAuthModule, BbitDataModule } from '@bbit/core';
import { AgGridModule } from 'ag-grid-angular';
import { BbitTabsModule } from '../tabs/tab.module';
import { BbitCardModule } from './../card/card.module';
import { BbitHelperModule } from './../helper/helper.module';
import { BbitToolbarModule } from './../toolbar/toolbar.module';
import { DatabaseComponent } from './database.component';
import { DocumentDetailComponent } from './document-detail.component';
import { DocumentDiffComponent } from './document-diff.component';
import { DocumentListComponent } from './document-list.component';
import { BbitI18nModule } from '@bbit/i18n';


@NgModule({
  declarations: [
    DatabaseComponent,
    DocumentListComponent,
    DocumentDetailComponent,
    DocumentDiffComponent
  ],
  exports: [
    DatabaseComponent,
    DocumentListComponent,
    DocumentDetailComponent,
    DocumentDiffComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BbitTabsModule,
    BbitI18nModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    BbitAuthModule,
    BbitDataModule,
    BbitCardModule,
    BbitHelperModule,
    BbitToolbarModule
  ],
  bootstrap: [
    DatabaseComponent,
    DocumentListComponent,
    DocumentDetailComponent,
    DocumentDiffComponent
  ]
})
export class PouchDBUiModule { }