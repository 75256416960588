import { BbitTooltipComponent } from './tooltip.component';
import { Injectable, ElementRef } from '@angular/core';
import { Overlay, OverlayConfig, OriginConnectionPosition, OverlayConnectionPosition } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';



@Injectable()
export class BbitTooltipService {

  _tooltipInstance: BbitTooltipComponent;

  // Inject overlay service
  constructor(private _overlay: Overlay) { }

  open(el: ElementRef): BbitTooltipComponent {
    // Returns an OverlayRef (which is a PortalHost)
    const overlayRef = this._overlay.create(this._getOverlayConfig(el));

    // Create ComponentPortal that can be attached to a PortalHost
    const tooltipPortal = new ComponentPortal(BbitTooltipComponent);

    // Attach ComponentPortal to PortalHost
    this._tooltipInstance = overlayRef.attach(tooltipPortal).instance;

    this._tooltipInstance.show();

    return this._tooltipInstance;
  }

  private _getOverlayConfig(el: ElementRef): OverlayConfig {
    const originPos: OriginConnectionPosition = { originX: 'center', originY: 'bottom' };
    const overlayPos: OverlayConnectionPosition = { overlayX: 'center', overlayY: 'top' };
    const positionStrategy = this._overlay.position().connectedTo(el, originPos, overlayPos);

    return new OverlayConfig({
      // hasBackdrop: config.hasBackdrop,
      // backdropClass: config.backdropClass,
      // panelClass: config.panelClass,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy
    });

  }
}