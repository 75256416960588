import { AfterViewInit, Component, ViewChild } from '@angular/core';
import * as Quill from 'quill';
import { BbitPrintService } from './print.service';

@Component({
  selector: 'bbit-print',
  template: '<div style = "display: none;"><bbit-print-area></bbit-print-area><div #quillHelper></div></div>'
})
export class BbitPrintComponent implements AfterViewInit {

  @ViewChild('quillHelper') quillHelper;

  constructor(
    private _printService: BbitPrintService
  ) {

  }

  ngAfterViewInit() {
    // TODO implementt own renderer
    this._printService.quillHelper = new (<any>Quill)(this.quillHelper.nativeElement);
  }
}