import { Component } from '@angular/core';

@Component({
  selector: 'bbit-raised-button',
  templateUrl: 'raised-button.component.pug',
})
export class BbitRaisedButtonComponent {
  constructor() {
  }
}
