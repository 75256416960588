import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BbitAuthService, BbitMgmtApiService, BbitSelectService, BbitTokenType, IBbitRequestResult } from '@bbit/core';
import AceDiff from 'ace-diff';
// optionally, include CSS, or use your own
import 'ace-diff/dist/ace-diff.min.css';
import { GridOptions } from 'ag-grid-community';
import 'brace/ext/searchbox';
import 'brace/mode/json';
import 'brace/theme/chrome';
import { Diff2Html } from 'diff2html';
import 'diff2html/dist/diff2html.min.css';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import PouchDB from 'pouchdb-browser';
import * as unidiff from 'unidiff';
import { IBbitTabController, IBbitTabInterface } from '../tabs/interfaces';
import { BbitTabService } from '../tabs/tab.service';
import { Database, DatabaseDiff, DatabaseDocument } from './interfaces';
import { BbitLog } from '@bbit/log';



@Component({
  templateUrl: 'document-diff.component.pug',
  styleUrls: ['document-diff.component.scss'],
  // necessary for innterHTML styling
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentDiffComponent implements IBbitTabController {

  @ViewChild('editor') editorDom: any;
  editor: any;
  tab: IBbitTabInterface = null; // warning: still externaly accessed, ToDo: refactor such shit
  isActive: boolean = false;
  toolbar: any;

  users: any[];
  diffs: any[];
  revisions: any[];
  gridOptions: GridOptions;
  columnDefs: any[];
  currentEditorDiff: DatabaseDiff;

  private _log = BbitLog.scope({
    package: 'DocumentDiffComponent'
  });

  constructor(
    private _zone: NgZone,
    protected _tabService: BbitTabService,
    protected _http: HttpClient,
    protected _auth: BbitAuthService,
    protected _mgmtApi: BbitMgmtApiService,
    protected _selectService: BbitSelectService,
    protected _sanitize: DomSanitizer,
    protected _cdr: ChangeDetectorRef,
  ) {

    const self = this;

    self.gridOptions = <GridOptions>{
      onGridReady: () => {
        self.gridOptions.api.sizeColumnsToFit();
      },
      floatingFilter: true,
      icons: {
        menu: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">more_vert</i>',
        filter: '<i class="material-icons" style="font-size: 18px; height: 18px; width: 18px;">search</i>',
      },
    };

  }


  /**
   * Creates and object with all diffs
   */
  public createDiffArray(revisions: any): DatabaseDiff[] {
    const diffs = [];
    for (let i = 0; i < revisions.length; i++) {

      // prepare revisions
      let firstRev = _.cloneDeep(revisions[i]);
      if (!firstRev) { continue; }
      let secondRev: any = {};
      if (revisions[i + 1]) {
        secondRev = _.cloneDeep(revisions[i + 1]);
      }

      // clean up docs
      const changesInfos = this.getChangesInfo(firstRev);
      const firstRevisionId = firstRev._rev;
      delete firstRev._rev;
      delete firstRev.$changedBy;
      delete firstRev.$changedOn;
      delete firstRev.$changedAt;
      delete firstRev.$appVersion;

      const secondRevisionId = secondRev._rev;
      delete secondRev._rev;
      delete secondRev.$changedBy;
      delete secondRev.$changedOn;
      delete secondRev.$changedAt;
      delete secondRev.$appVersion;

      // prepare diff and html
      let diff = unidiff.default.diffJson(secondRev, firstRev);
      diff = unidiff.formatLines(diff);
      diff = diff.replace(/--- a/, `--- ${secondRevisionId ? secondRevisionId : 'New document'}`);
      diff = diff.replace(/\+\+\+ b/, `+++ ${firstRevisionId}`);
      let html = Diff2Html.getPrettyHtml(diff, {
        inputFormat: 'diff',
        showFiles: false,
        matching: 'lines',
        // outputFormat: 'side-by-side'
      });

      // html = html.replace(/<span class="d2h-tag d2h-moved d2h-moved-tag">RENAMED<\/span>/, `<span>&nbsp;| ${changedDetails}</span>`);
      const sanitizedHtml = this._sanitize.bypassSecurityTrustHtml(html);

      const d = {
        newRevisionId: firstRevisionId,
        previousRevisionId: secondRevisionId,
        diff: diff,
        html: sanitizedHtml,
        previous: secondRev,
        new: firstRev,
        changesInfos: changesInfos
      };
      diffs.push(d);

    }

    return diffs;
  }

  /**
   * Load all revision of a document
   */
  public loadAllRevisions(database: Database, docToFind: DatabaseDocument): Promise<any> {

    const self = this;
    return self._auth.getCurrentSession().retrieveToken('databases', BbitTokenType.ACCESS).then((result: IBbitRequestResult) => {
      if (result.statusCode !== 200) {
        return Promise.reject('Error while retrieving token');
      }
      const _pouchDB = new PouchDB(`${database.endpointUrl}/${database.database}`, {
        fetch: function (url, opts) {
          if (result.data) {
            opts.headers.set('Authorization', 'Bearer ' + result.data);
          }
          return PouchDB.fetch(url, opts);
        }
      });
      // return Promise.resolve();
      return _pouchDB.get(docToFind.id, {
        revs: true,
        open_revs: 'all'
      }).then(d => {

        const foundDoc = d[0].ok;

        let lastRevision = foundDoc._revisions.start;

        const revisions = _.map(foundDoc._revisions.ids, r => {
          const revision = {
            id: docToFind.id,
            rev: lastRevision + '-' + r
          };
          lastRevision--;
          return revision;
        });
        delete foundDoc._revisions;

        const promises = [];
        for (const revision of revisions) {
          promises.push(_pouchDB.get(docToFind.id, {
            revs: revision,
          }));
        }

        return _pouchDB.bulkGet({
          docs: revisions,
          include_docs: true
        }).then((result) => {

          let docs = [];
          for (let res of result.results) {
            // filter missing elements
            if (res.docs[0].ok) {
              docs.push(res.docs[0].ok);
            }
          }
          docs = docs.reverse();
          return Promise.resolve(docs);
        });
      }).catch((err) => {
        self._log.error(err);
      });
    });

  }


  /**
   * Load all users
   */
  loadUsers(database: Database): Promise<any[]> {
    const self = this;
    return self._auth.getCurrentSession().retrieveToken('databases', BbitTokenType.ACCESS).then((result: IBbitRequestResult) => {
      if (result.statusCode !== 200) {
        return Promise.reject('Error while retrieving token');
      }
      const _pouchDB = new PouchDB(`${database.endpointUrl}/${database.organization}+user+main`, {
        fetch: function (url, opts) {
          if (result.data) {
            opts.headers.set('Authorization', 'Bearer ' + result.data);
          }
          return PouchDB.fetch(url, opts);
        }
      });
      return _pouchDB.allDocs({ include_docs: true, open_revs: 'all' }).then(result => { return Promise.resolve(_.map(result.rows, row => { return row.doc; })); });
    });
  }

  getChangesInfo(doc: any) {
    const user: any = _.find(this.users, { _id: doc.$changedBy });
    if (!user) {
      return `"undefined user (user not found)"`;
    }
    return `${user.firstname} ${user.lastname}, ${moment(doc.$changedAt).format('dddd DD.MM.YYYY HH:mm:ss')}`; // .tz('Europe/Bern')
  }

  getDiffToolbar(diff: DatabaseDiff) {
    return {
      title: (diff.previousRevisionId ? diff.previousRevisionId : 'New') + ' → ' + diff.newRevisionId + ' | ' + diff.changesInfos,
      icon: 'mdi:vector-difference',
      buttons: [
        {
          icon: 'mdi:file-multiple',
          action: 'SHOW-IN-EDITOR',
          tooltipWithShortcut: 'Editor',
          params: {
            diff: diff
          }
        }
      ]
    };
  }


  injectParams(tab) {
    this.tab = tab;
    this.tab.text = { value: 'Changes ' + this.tab.params.database.database + ' ' + this.tab.params.document.id };
    this.tab.icon = 'mdi:vector-difference';
    this.toolbar = {
      icon: 'mdi:vector-difference',
      title: this.tab.text,
      // buttons: [{
      //   icon: 'add',
      //   action: 'ADD'
      // }]
    };

    const self = this;

    self.loadUsers(this.tab.params.database).then((users) => {
      self.users = users;
      self._log.info(`Retrieved users`, users);
      self.loadAllRevisions(this.tab.params.database, this.tab.params.document).then(revs => {
        self.revisions = revs;
        self._log.info(`Retrieved revisions`, revs);
        self.diffs = self.createDiffArray(revs);
        self._log.info(`Prepared diffs`, self.diffs);
        self._cdr.detectChanges();
      });
    });


  }

  showRevisionsInEditor(diff: DatabaseDiff) {

    this._zone.run(() => {
      this.currentEditorDiff = null;
      this.currentEditorDiff = diff;

      // timout neede because of rendering problems in ace-diff
      setTimeout(() => {
        this.renderEditors();
      }, 50);
    });

  }

  renderEditors() {
    this._zone.run(() => {
      if (this.editorDom) {
        if (this.editor) {
          this.editor = null;
          this.editorDom.nativeElement.innerHTML = '';
        }
        this.editor = new AceDiff({
          element: this.editorDom.nativeElement,
          mode: 'ace/mode/json',
          theme: 'ace/theme/chrome',
          left: {
            content: JSON.stringify(unidiff.canonicalize(this.currentEditorDiff.previous), null, 2),
            editable: false,
            copyLinkEnabled: false
          },
          right: {
            content: JSON.stringify(unidiff.canonicalize(this.currentEditorDiff.new), null, 2),
            editable: false,
            copyLinkEnabled: false
          }
        });
      }
    });
  }

  isTabHidden() {
    return !(this.isActive);
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  closeTab() {
    this._tabService.close(this.tab);
  }

  isClosingAllowed() {
    return Promise.resolve(true);
  }

  handleAction(event: any) {
    switch (event.action) {
      case 'SHOW-IN-EDITOR':
        this.showRevisionsInEditor(event.params.diff);
    }
  }


}