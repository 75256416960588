import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BbitAuthModule } from '@bbit/core';
import { BbitI18nModule } from '@bbit/i18n';
import { BbitInputDeltaModule } from './../input-delta/input-delta.module';
import { BbitDisplayTextComponent } from './display-text.component';

@NgModule({
    imports: [
        BrowserModule,
        BbitI18nModule,
        BbitAuthModule,
        BbitInputDeltaModule
    ],
    exports: [
      BbitDisplayTextComponent,
    ],
    declarations: [
      BbitDisplayTextComponent
    ]
})
export class BbitDisplayTextModule { }
