import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitButtonModule } from '../button/button.module';
import { BbitIconModule } from '../icon/icon.module';
import { BbitShortcutModule } from './../shortcut/shortcut.module';
import { BbitToolbarComponent } from './toolbar.component';

@NgModule({
  imports: [
    MatToolbarModule,
    BrowserModule,
    BbitIconModule,
    BbitCoreModule,
    BbitButtonModule,
    FlexLayoutModule,
    BbitShortcutModule,
    FormsModule
  ],
  exports: [
    BbitToolbarComponent
  ],
  declarations: [
    BbitToolbarComponent
  ]
})
export class BbitToolbarModule { }
