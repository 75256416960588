import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BbitLog } from '@bbit/log';

@Component({
  selector: 'bbit-tab-content',
  templateUrl: 'tab-content.component.pug',
  styleUrls: ['tab-content.component.scss']
})
export class BbitTabContent {

  private _log = BbitLog.scope({
    package: 'BbitTabContent'
  });

  @Input() component: any;
  @Input() toolbar: any;
  @Input() search: boolean = false;
  @Input() searchValue: string;

  @Output('action') actionEventEmitter = new EventEmitter();
  @Output('searchValueChange') searchValueEventEmitter = new EventEmitter();

  constructor() {
  }

  handleAction(event) {
    this.actionEventEmitter.emit(event);
  }

  onSearchValueChange(event) {
    this.searchValue = event;
    this.searchValueEventEmitter.emit(event);
  }

  ngOnInit() {
    if (!this.component) {
      this._log.error('Component needs to be passed to bbit-tab-content!');
    } else {
      /* if (!(this.component instanceof BbitTabController)) {
          this._log.error("Component needs to be an instance to BbitTabController!");
      } */
      this._log.info('ToDo: check if component correctly implements IBbitTabController interface');
    }
  }


}