import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BbitAuthService, BbitController, BbitSelectService, Utils } from '@bbit/core';
import * as _ from 'lodash';

@Component({
  selector: 'bbit-display-button',
  styleUrls: ['display-button.component.scss'],
  templateUrl: 'display-button.component.pug',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitDisplayButtonComponent {

  @Input('ui') ui;
  @Input('label') label;
  @Output() action: EventEmitter<any> = new EventEmitter();

  private _referencesDisplayField: string;

  constructor(
    private _selectService: BbitSelectService,
    private _authService: BbitAuthService
  ) {

  }

  loadController(uniqueEntityName: string, referenceField: string): Promise<BbitController> {
    const self = this;
    const schema = this._selectService.getSchemaService().getSchema(this._authService.getCurrentSession(), uniqueEntityName);
    const schemaElement = schema.getSchemaElement(`#0.${referenceField}`, this._selectService.getSchemaService().getSchemaCompilation(this._authService.getCurrentSession()));

    self._referencesDisplayField = schemaElement[0].element.referencesDisplayField;
    const newUniqueEntityName = schemaElement[0].element.referencesDocumentSchema;

    const controller = self._selectService.getCacheControllerWithLoadedId(self._authService.getCurrentSession(), newUniqueEntityName, self.ui.jsonPath);
    if (Utils.isPromise(controller)) {
      return  controller.then(() => {
        return Promise.resolve(<BbitController>self._selectService.getCacheControllerWithLoadedId(self._authService.getCurrentSession(), newUniqueEntityName, self.ui.jsonPath));
      });
    } else {
      return Promise.resolve(controller);
    }

  }

  handleClick() {

    const event = _.cloneDeep(this.ui.onClick);

    if (event) {
      switch (event.action) {
        case 'OPEN-LIST':
          this.loadController(event.params.uniqueEntityName, event.params.referenceField).then((controller) => {
            this.action.emit({
              action: 'OPEN-REFERENCED-LIST',
              params: {
                uniqueEntityName: event.params.uniqueEntityName,
                referenceField: event.params.referenceField,
                filterValue: [controller.getDisplayValue(`${this.ui.jsonPath}.${this._referencesDisplayField}`, null).data]
              }
            });
          });
          break;
        default:
          this.action.emit(event);
      }
    } else {
      this.action.emit(event);
    }


  }

}