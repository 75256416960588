import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BbitAuthService, BbitFileService } from '@bbit/core';


@Component({
  selector: 'bbit-display-image',
  styleUrls: ['display-image.component.scss'],
  templateUrl: 'display-image.component.pug',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitDisplayImageComponent implements OnChanges {

  @Input('ui') ui;
  @Input('file') file;
  @Output() action: EventEmitter<any> = new EventEmitter();

  signedUrl: string;

  constructor(
    private _auth: BbitAuthService,
    private _fileService: BbitFileService,
    private _cdr: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const self = this;
    if (changes['file'].currentValue) {
      self._fileService.getSignedDownloadUrl(changes['file'].currentValue, self._auth.getCurrentSession()).then((url) => {
        this.signedUrl = url;
        this._cdr.markForCheck();
      });
    } else {
      this.signedUrl = null;
    }
  }


}