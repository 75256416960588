import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { BbitCardModule } from './../card/card.module';
import { BbitUpdateService } from './../update/update.service';
import { BbitUpdateComponent } from './update.component';


@NgModule({
  imports: [
    BbitCoreModule,
    BrowserModule,
    BbitCardModule,
    MatProgressBarModule,
    FlexLayoutModule
  ],
  exports: [
    BbitUpdateComponent
  ],
  declarations: [
    BbitUpdateComponent
  ],
  providers: [
    BbitUpdateService
  ]
})
export class BbitUpdateModule { }
