import { BbitStyleModule } from './../style/style.module';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BbitCoreModule } from '@bbit/core';
import { AgGridModule } from 'ag-grid-angular';
import { BbitDisplayImageModule } from '../display-image/display-image.module';
import { BbitDisplayTextModule } from '../display-text/display-text.module';
import { SettingsModule } from '../settings/settings.module';
import { BbitTabsModule } from './../../modules/tabs/tab.module';
import { BbitButtonModule } from './../button/button.module';
import { BbitCardModule } from './../card/card.module';
import { BbitDisplayButtonModule } from './../display-button/display-button.module';
import { BbitFilePreviewModule } from './../file-preview/file-preview.module';
import { BbitHelperModule } from './../helper/helper.module';
import { BbitHotkeyModule } from './../hotkey/hotkey.module';
import { BbitIconModule } from './../icon/icon.module';
import { BbitInputAddressModule } from './../input-address/input-address.module';
import { BbitInputCheckboxModule } from './../input-checkbox/input-checkbox.module';
import { BbitInputDeltaModule } from './../input-delta/input-delta.module';
import { BbitInputFileModule } from './../input-file/input-file.module';
import { BbitInputMultiReferenceModule } from './../input-multireference/input-multireference.module';
import { BbitInputPasswordModule } from './../input-password/input-password.module';
import { BbitInputReferenceModule } from './../input-reference/input-reference.module';
import { BbitInputSelectModule } from './../input-select/input-select.module';
import { BbitInputTextModule } from './../input-text/input-text.module';
import { BbitPermissionGridComponent } from './../permissions/permission-grid.component';
import { BbitPermissionModule } from './../permissions/permissions.module';
import { DatabaseComponent } from './../pouchdb-ui/database.component';
import { DocumentDetailComponent } from './../pouchdb-ui/document-detail.component';
import { DocumentDiffComponent } from './../pouchdb-ui/document-diff.component';
import { DocumentListComponent } from './../pouchdb-ui/document-list.component';
import { PouchDBUiModule } from './../pouchdb-ui/pouchdb-ui.module';
import { BbitSessionComponent } from './../session/session.component';
import { BbitSessionModule } from './../session/session.module';
import { SettingsComponent } from './../settings/settings.component';
import { BbitTabService } from './../tabs/tab.service';
import { BbitToolbarModule } from './../toolbar/toolbar.module';
import { BbitUserListComponent } from './../user/user-list.component';
import { BbitUserComponent } from './../user/user.component';
import { BbitUserModule } from './../user/user.module';
import { BbitUiDocControllerComponent } from './doc-controller.component';
import { BbitUiDocViewComponent } from './doc-view.component';
import { BbitUiFieldComponent } from './field.component';
import { BbitUiFlexComponent } from './flex.component';
import { BbitUiListControllerComponent } from './list-controller.component';
import { BbitUiSectionComponent } from './section.component';
import { TempWestwindPersonAddress } from './temp-components/temp-westwind-person-address.component';
import { TempWestwindPersonLiving } from './temp-components/temp-westwind-person-living.component';
import { TempWestwindPersonWork } from './temp-components/temp-westwind-person-work.component';
import { BbitLog } from '@bbit/log';
import { TempWestwindPersonLivingType } from './temp-components/temp-westwind-person-living-type.component';
import { TempWestwindPersonWorkType } from './temp-components/temp-westwind-person-work-type.component';
import { CacheComponent } from '../cache/cache.component';

@NgModule({
  bootstrap: [
    BbitUiDocViewComponent,
    BbitUiSectionComponent,
    BbitUiFieldComponent,
    BbitUiFlexComponent,
    BbitUiListControllerComponent,
    BbitUiDocControllerComponent,

    // temporary
    TempWestwindPersonLiving,
    TempWestwindPersonWork,
    TempWestwindPersonAddress,
    TempWestwindPersonLivingType,
    TempWestwindPersonWorkType
  ],
  declarations: [
    BbitUiDocViewComponent,
    BbitUiSectionComponent,
    BbitUiFieldComponent,
    BbitUiFlexComponent,
    BbitUiListControllerComponent,
    BbitUiDocControllerComponent,

    // temporary
    TempWestwindPersonLiving,
    TempWestwindPersonWork,
    TempWestwindPersonAddress,
    TempWestwindPersonLivingType,
    TempWestwindPersonWorkType
  ],
  exports: [
    BbitUiDocViewComponent,
    BbitUiSectionComponent,
    BbitUiFieldComponent,
    BbitUiFlexComponent,
    BbitUiListControllerComponent,
    BbitUiDocControllerComponent,

    // temporary
    TempWestwindPersonLiving,
    TempWestwindPersonWork,
    TempWestwindPersonAddress,
    TempWestwindPersonLivingType,
    TempWestwindPersonWorkType
  ],
  imports: [
    BrowserModule,
    BbitCoreModule,
    BbitTabsModule,
    BbitInputTextModule,
    BbitInputSelectModule,
    BbitInputReferenceModule,
    BbitInputCheckboxModule,
    BbitInputDeltaModule,
    BbitInputPasswordModule,
    BbitInputMultiReferenceModule,
    BbitInputAddressModule,
    BbitInputFileModule,
    BbitCardModule,
    BbitButtonModule,
    BbitIconModule,
    BbitHotkeyModule,
    BbitDisplayTextModule,
    MatTooltipModule,
    BbitFilePreviewModule,
    BbitToolbarModule,
    SettingsModule,
    BbitUserModule,
    BbitPermissionModule,
    BbitSessionModule,
    BbitHelperModule,
    BbitDisplayButtonModule,
    PouchDBUiModule,
    AgGridModule.withComponents([]),
    FlexLayoutModule,
    BbitDisplayImageModule,
    BbitStyleModule
  ]
})
export class BbitUiModule { }


export function registerSystemViews(tabService: BbitTabService): Promise<Error> {
  let error = null;

  error = tabService.registerComponent('BaseDocControllerComponent', BbitUiDocControllerComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('BaseListControllerComponent', BbitUiListControllerComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('SettingsComponent', SettingsComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('BbitUserComponent', BbitUserComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('BbitUserListComponent', BbitUserListComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('BbitPermissionGridComponent', BbitPermissionGridComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('DatabaseComponent', DatabaseComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('DocumentListComponent', DocumentListComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('DocumentDetailComponent', DocumentDetailComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('DocumentDiffComponent', DocumentDiffComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('BbitSessionComponent', BbitSessionComponent);
  if (error) return Promise.reject(error);

  error = tabService.registerComponent('CacheComponent', CacheComponent);
  if (error) return Promise.reject(error);

  // // temp
  // error = tabService.registerComponent('TempWestwindPersonAddress', TempWestwindPersonAddress);
  // if (error) return Promise.reject(error);

  BbitLog.scope({ package: 'BbitUiModule', function: 'registerSystemViews' }).log('Registered SystemViews', tabService.getComponentList());
  return Promise.resolve(null);
}