import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'bbit-card',
  templateUrl: 'card.component.pug',
  styleUrls: ['card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BbitCardComponent {

  @Input() toolbar: any;
  @Input() ui: any;

  @Output('action') actionEventEmitter = new EventEmitter();

  constructor() {
  }

  hanldeAction(event) {
    this.actionEventEmitter.emit(event);
  }
}